export const sessionPoints = [
  "Counseling using Vedic Astrology and Numerology ",
  "Matchmaking ",
  "Love Life / Marriage Life analysis - things you can do to strengthen your romantic life, how your marriage life will be ",
  "Helping you choose your destined career using the chart ",
  "Life Remedies. Remedies that work all the time ",
  "Your entire year's prediction ",
  "Do's and Don'ts. Areas where you should be Courageous and put more effort and areas where you have to be cautious ",
  "Strengthening the relationship with your Parents or loved ones ",
  "Your Source to Create Money and Wealth ",
  "Business Approach- How you should proceed or be cautious, if needed. When you should accelerate your business. Analysis in Depth using Vedic Astrology, Nakshatras, Divisional Charts",
];
