import React from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import Background1 from "../../images/Service1Background.compressed.svg";
import GemsImageSlider from "../../components/GemsImageSlider";

const Ruby = () => {
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      {/* web view */}
      <VStack
        w={"full"}
        h={{ base: "full" }}
        bgRepeat="no-repeat"
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        display={{ base: "none", md: "flex" }}
        mb={"50px"}
      >
        <HStack>
          <Flex
            alignItems={"start"}
            justifyContent={"start"}
            gap={8}
            ml={"50px"}
            flexDir={"column"}
            maxW={"47%"}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              textTransform={"capitalize"}
              fontWeight={900}
              lineHeight={"40px"}
            >
              RUBY ( माणिक )
            </Text>
            <Flex gap={"5"} flexDir={"column"}>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                Who should wear Ruby stone ?
              </Text>
              <OrderedList
                spacing={3}
                pl={4}
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color="var(--astrology-nav)"
              >
                <ListItem>Ideal for Aries Ascendants.</ListItem>
                <ListItem>
                  Ruby is also known as the
                  <Text display={"inline"}> “Ratna Raj”</Text> ruled by SUN.
                </ListItem>
                <ListItem>
                  Worn in the <Text display="inline">Ring Finger</Text>.
                </ListItem>
                <ListItem>
                  Sun is the symbol of energy, strength, and overall health in
                  Vedic astrology. Hence, an original manik ratna (also sourced
                  by us) benefits the body by enhancing blood
                  circulation,improving eyesight.
                </ListItem>
                <ListItem>
                  Surya is believed to make one confident and determined and
                  heightens recognition in society and builds a sense of
                  responsibility in handling finances.
                </ListItem>
                <ListItem>
                  The ruby stone benefits the wearer with courage,
                  self-confidence, and good relationships, bringing name, fame,
                  health, and luck.
                </ListItem>
              </OrderedList>
            </Flex>

            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Buy Now!!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://wa.me/+916355230818", "_blank");
              }}
            >
              Buy Now!!
            </Button>
          </Flex>
          <Flex
            w={{ base: "full", md: "50vw", lg: "50vw" }}
            h={{ base: "300px", md: "500px", lg: "500px" }}
          >
            <GemsImageSlider type="ruby" />
          </Flex>
        </HStack>
      </VStack>

      {/* mobile view */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"100px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"400px"}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          aspectRatio={3 / 4}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <GemsImageSlider type="ruby" />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            RUBY ( माणिक )
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Who should wear Ruby stone ?
          </Text>
          <OrderedList
            spacing={3}
            pl={4}
            fontFamily={"Jost"}
            fontSize={"20px"}
            fontWeight={400}
            lineHeight={"30px"}
            color="var(--astrology-nav)"
          >
            <ListItem>Ideal for Aries Ascendants.</ListItem>
            <ListItem>
              Ruby is also known as the
              <Text display={"inline"}> “Ratna Raj”</Text> ruled by SUN.
            </ListItem>
            <ListItem>
              Worn in the <Text display="inline">Ring Finger</Text>.
            </ListItem>
            <ListItem>
              Sun is the symbol of energy, strength, and overall health in Vedic
              astrology. Hence, an original manik ratna (also sourced by us)
              benefits the body by enhancing blood circulation,improving
              eyesight.
            </ListItem>
            <ListItem>
              Surya is believed to make one confident and determined and
              heightens recognition in society and builds a sense of
              responsibility in handling finances.
            </ListItem>
            <ListItem>
              The ruby stone benefits the wearer with courage, self-confidence,
              and good relationships, bringing name, fame, health, and luck.
            </ListItem>
          </OrderedList>

          <Button
            variant={"trPrimary"}
            title={"Buy Now!!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/+916355230818", "_blank");
            }}
          >
            Buy Now!!
          </Button>
        </Flex>
      </VStack>
    </>
  );
};

export default Ruby;
