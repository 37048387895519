import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  fontWeight: "600",
  textTransform: "capitalize",
  borderRadius: "base",
  fontSize: "16px",
  borderRadius: "26px",
});

let commonStyle = {
  border: "2px solid",
  borderColor: "brand.10",
  color: "brandGray.100",
  height: "2.5rem",
  lineHeight: "1",
  padding: "5px 12px",
};

const sm = defineStyle({
  fontSize: "sm",
  px: 4, // <-- px is short for paddingLeft and paddingRight
  py: 3, // <-- py is short for paddingTop and paddingBottom
});

const md = defineStyle({
  fontSize: "md",
  px: 4, // <-- these values are tokens from the design system
  py: 4, // <-- these values are tokens from the design system
});

const brandPrimary = defineStyle({
  ...commonStyle,
  bg: "brand.100",
  _hover: {
    bg: `brand.50`,
  },
});

const discard = defineStyle({
  ...commonStyle,
  bg: "brandGray.10",
  color: "brandGray.200",
  border: 0,
  _hover: {
    bg: `brandGray.20`,
  },
});

const danger = defineStyle({
  ...commonStyle,
  border: "2px solid",
  borderColor: "#F05252",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "#F05252",
  color: "white",
});

const modal = defineStyle({
  ...commonStyle,
  fontSize: "14px",
  border: "2px solid",
  borderColor: "brand.100",
  height: "2rem",
  position: "absolute",
  right: "20px",
  top: "14px",
  color: "brand.100",
  _hover: {
    bg: `brandGray.20`,
  },
});

const imgDeleteBtn = defineStyle({
  bg: "brandGray.20",
  position: "relative",
  left: "240px",
  top: "20px",
  borderRadius: "20px",
  color: "white",
  height: "20px",
  width: "20px",
});

const specializationButton = defineStyle({
  ...commonStyle,
  border: "2px solid",
  borderColor: "brand.50",
  bg: "#FFFFFF",
  w: "74px",
  h: "30px",
  borderRadius: "40px",
  fontSize: "14px",
  fontWeight: "600",
  color: "brand.50",
  p: "0px",
});

const serviceButton = defineStyle({
  ...commonStyle,
  border: "2px solid",
  borderColor: "brand.50",
  w: "74px",
  h: "30px",
  borderRadius: "40px",
  fontSize: "14px",
  fontWeight: "600",
  p: "0px",
  bgColor: "brand.50",
  color: "white",
});

const newThemePrimary = defineStyle({
  ...commonStyle,
  border: "2px solid",
  borderColor: "brand.200",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "brand.200",
  color: "white",
});

const trPrimary = defineStyle({
  ...commonStyle,
  border: "2px solid",
  borderColor: "#68030D",
  borderRadius: "10px",
  fontSize: "24px",
  fontWeight: "400",
  bgColor: "#68030D",
  color: "#FFFFFF",
});

const trNoOutline = defineStyle({
  ...commonStyle,
  border: "none",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "var(--white-color)",
  color: "var(--astrology-nav)",
  _hover: { bgColor: "var(--white-color)" },
});

const trThemeOutline = defineStyle({
  ...commonStyle,
  border: "1px solid",
  borderColor: "var(--astrology-nav)",
  borderRadius: "10px",
  bgColor: "var(--white-color)",
  color: "var(--astrology-nav)",
  _hover: { bgColor: "var(--white-color)" },
  fontSize: "24px",
  fontWeight: "400",
});

const trDiscardThemeOutline = defineStyle({
  ...commonStyle,
  border: "1px solid",
  borderColor: "var(--atlantic-100)",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "#FFF",
  color: "var(--atlantic-300)",
});

const redThemeOutline = defineStyle({
  ...commonStyle,
  border: "none",
  // borderColor: "brand.200",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "var(--cherry-red-50)",
  color: "var(--cherry-red-600)",
  _hover: { bgColor: "#F8E0E0" },
});

const trNoBg = defineStyle({
  ...commonStyle,
  border: "none",
  // borderColor: "brand.200",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "#FFF",
  color: "brand.200",
});

const trNoBgOutline = defineStyle({
  ...commonStyle,
  border: "1px solid",
  borderColor: "var(--flemingo-300)",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "inherit",
  color: "var(--white-color)",
});

export const buttonTheme = defineStyleConfig({
  //   variants: { outline },
  baseStyle: baseStyle,
  // Two sizes: sm and md
  sizes: {
    sm: sm,
    md: md,
  },
  // variants list
  variants: {
    discard,
    brandPrimary,
    modal,
    danger,
    imgDeleteBtn,
    specializationButton,
    serviceButton,
    newThemePrimary,
    trPrimary,
    trNoOutline,
    trThemeOutline,
    trDiscardThemeOutline,
    redThemeOutline,
    trNoBg,
    trNoBgOutline,
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "trPrimary",
  },
});
