import React from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { MdBrightness1 } from "react-icons/md";
import Background1 from "../../images/Service1Background.compressed.svg";
import GemstoneFirstSection from "../../images/gemstoneFirstSection.png";
import GemstoneSecondSection from "../../images/gemstoneSecondSection.png";
import GemstoneThirdSection from "../../images/gemstoneThirdSection.png";
import Footer from "../Footer/Footer";
import Ruby from "./Ruby";
import Emerald from "./Emerald";
import Opal from "./Opal";
import Sapphire from "./Sapphire";
import CatEye from "./CatEye";
import Pearl from "./Pearl";
import YellowSapphire from "./YellowSapphire";
import Assurity from "./Assurity";
import FAQS from "../FAQS/FAQS";
import RedCoral from "./RedCoral";
import { Button } from "@astrology/shared-components";
import Hessonite from "./Hessonite";

const Gemstones = () => {
  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });

  return (
    <>
      {/* web view 1st section */}

      <VStack
        className={c1}
        bgImage={`url('${Background1}')`}
        w="full"
        h="auto"
        py="16"
        bgRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
        flexDir="column"
        backgroundPosition="top"
        display={{ base: "none", md: "flex" }}
      >
        <HStack>
          <Flex
            bgImage={`url('${GemstoneFirstSection}')`}
            w={{ base: "full", md: "45%", lg: "50%", xl: "full" }}
            h={{ base: "400px", md: "430px", lg: "420px", xl: "420px" }}
            backgroundPosition={"center top"}
            backgroundSize={"contain"}
            aspectRatio={1 / 1}
            bgRepeat="no-repeat"
            display={"flex"}
            ml={{ base: "10px", md: "20px" }}
          ></Flex>
          <Flex
            alignItems={"start"}
            justifyContent={"start"}
            gap={8}
            mr={"30px"}
            flexDir={"column"}
            maxW={"50%"}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"40px"}
            >
              The Chosen stOne
            </Text>
            <Flex gap={"5"} flexDir={"column"}>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                The chosen stone is a personal guide to help you attract
                abundance, and well-being through the power of Vedic astrology
                and Gems.
              </Text>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                With insights of your birth chart, this report answers the whys
                and hows of gemstones: why a specific gemstone(s) is ideal for
                you and how it can positively influence your life, whether it’s
                achieving success, emotional harmony, or healing your hidden
                imbalances. This report offers clarity and direction by Jay and
                his team.
              </Text>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                Let the right gemstone attune your energies to enhance your
                life. Discover the transformative power of gemstones uniquely
                aligned with your energy.
              </Text>
            </Flex>

            <Button
              variant="trPrimary"
              borderRadius="8px"
              padding="13px 20px"
              fontSize="16px"
              fontWeight={500}
              lineHeight="22px"
              title={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                >
                  <Text color="whiteAlpha.900" fontSize="16px" fontWeight={500}>
                    Get Your Report in
                  </Text>
                  <Text
                    as="span"
                    textDecoration="line-through"
                    textShadow="0 1px whiteAlpha.900, 0 -1px whiteAlpha.900"
                    color="whiteAlpha.900"
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="22px"
                    mr="8px"
                  >
                    ₹2,500/-
                  </Text>
                  <Text
                    as="span"
                    color="whiteAlpha.900"
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="22px"
                  >
                    ₹1,000/-
                  </Text>
                </Box>
              }
              boxShadow="0px 1px 2px 0px rgba(15, 23, 42, 0.06)"
              onClick={(e) => {
                e.preventDefault();
                window.open("https://rzp.io/rzp/q75ESpJE", "_blank");
              }}
            />
            <Text
              fontSize="16px"
              mt="-25px"
              textAlign="center"
              cursor="pointer"
              fontFamily={"Jost"}
              fontWeight={450}
              lineHeight={"30px"}
            >
              Feel free to reach out to our team at{" "}
              <Text
                _hover={{ textDecoration: "underline" }}
                onClick={() => {
                  window.location.href = "tel:+916355230818";
                }}
                display="inline"
                fontSize="16px"
                textAlign="center"
                cursor="pointer"
                fontFamily={"Jost"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                +91 6355230818
              </Text>
            </Text>
          </Flex>
        </HStack>
      </VStack>

      {/* mobile view 1st section */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"150px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"400px"}
          backgroundPosition={"center bottom"}
          backgroundSize={"cover"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <Box
            as="img"
            src={GemstoneFirstSection}
            alt="Gems"
            position="center top"
            width="100%"
            height="100%"
            objectFit="contain"
          />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            The Chosen stOne
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            The chosen stone is a personal guide to help you attract abundance,
            and well-being through the power of Vedic astrology and Gems.
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            With insights of your birth chart, this report answers the whys and
            hows of gemstones: why a specific gemstone(s) is ideal for you and
            how it can positively influence your life, whether it’s achieving
            success, emotional harmony, or healing your hidden imbalances. This
            report offers clarity and direction by Jay and his team.
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Let the right gemstone attune your energies to enhance your life.
            Discover the transformative power of gemstones uniquely aligned with
            your energy.
          </Text>

          <Button
            variant={"trPrimary"}
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Text color="whiteAlpha.900" fontSize="15px" fontWeight={500}>
                  Get Your Report in
                </Text>
                <Text
                  as="span"
                  textDecoration="line-through"
                  textShadow="0 1px whiteAlpha.900, 0 -1px whiteAlpha.900"
                  color="whiteAlpha.900"
                  fontSize="15px"
                  fontWeight={500}
                  lineHeight="22px"
                >
                  ₹2,500/-
                </Text>
                <Text
                  as="span"
                  color="whiteAlpha.900"
                  fontSize="15px"
                  fontWeight={500}
                  lineHeight="22px"
                >
                  ₹1,000/-
                </Text>
              </Box>
            }
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://rzp.io/rzp/q75ESpJE", "_blank");
            }}
          />
          <Text
            fontSize="14px"
            mt="-25px"
            textAlign="center"
            cursor="pointer"
            fontFamily={"Jost"}
            fontWeight={350}
            lineHeight={"30px"}
          >
            Feel free to reach out to our team at{" "}
            <Text
              _hover={{ textDecoration: "underline" }}
              onClick={() => {
                window.location.href = "tel:+916355230818";
              }}
              display="inline"
              fontSize="14px"
              textAlign="center"
              cursor="pointer"
              fontFamily={"Jost"}
              fontWeight={300}
              lineHeight={"30px"}
            >
              +91 6355230818
            </Text>
          </Text>
        </Flex>
      </VStack>

      {/* web view 2nd section */}
      <VStack
        w="full"
        h="auto"
        py="8"
        bgRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
        flexDir="column"
        backgroundPosition="top"
        display={{ base: "none", md: "flex" }}
      >
        <HStack>
          <Flex
            alignItems="start"
            justifyContent="start"
            gap={8}
            ml="50px"
            flexDir="column"
            maxW="47%"
          >
            <Text
              fontFamily="Fondamento, sans-serif"
              fontSize="36px"
              fontWeight={900}
              lineHeight="40px"
            >
              Why The Chosen stOne?
            </Text>
            <Flex gap="5" flexDir="column">
              <Text
                fontFamily="Jost"
                fontSize="20px"
                fontWeight={400}
                lineHeight="30px"
              >
                Many free gemstone recommendations are based on general factors
                like your Moon sign or Sun sign. However, this way the
                complexities of your birth chart remain ignored and can lead to
                unfavorable outcomes, such as activating 6th, 8th, or 12th
                houses of your chart. This can disturb your energy.
              </Text>
              <Text
                fontFamily="Jost"
                fontSize="20px"
                fontWeight={400}
                lineHeight="30px"
              >
                Our report is the result of a thorough analysis of your complete
                birth chart, ensuring the gemstones we recommend align perfectly
                with your life goals and personal energies. This process
                requires time, effort, and expertise, which is why there is an
                energy exchange for the report.
              </Text>
              <Text
                fontFamily="Jost"
                fontSize="20px"
                fontWeight={400}
                lineHeight="30px"
              >
                If you choose to purchase a gemstone through us, your report
                will be free of charge.
              </Text>
              <Text
                fontFamily="Jost"
                fontSize="24px"
                fontWeight={600}
                lineHeight="30px"
                mt={5}
              >
                A Word of Caution
              </Text>
              <Text
                fontFamily="Jost"
                fontSize="20px"
                fontWeight={400}
                lineHeight="30px"
              >
                Gemstones are powerful tools and should be worn only after
                consulting a qualified astrologer. Wearing a gemstone without
                guidance can give you ill effects. Our detailed analysis ensures
                you receive the right guidance, allowing you to wear your
                gemstone with confidence and purpose.
              </Text>
            </Flex>
            <Button
              variant="trPrimary"
              borderRadius="8px"
              padding="13px 20px"
              fontSize="16px"
              textTransform="none"
              fontWeight={500}
              lineHeight="22px"
              title="Get The Chosen stOne Now!!"
              boxShadow="0px 1px 2px 0px rgba(15, 23, 42, 0.06)"
              onClick={(e) => {
                e.preventDefault();
                window.open("https://rzp.io/rzp/q75ESpJE", "_blank");
              }}
            />
          </Flex>
          <Flex
            bgImage={`url('${GemstoneSecondSection}')`}
            w={{ base: "full", md: "50%", lg: "50%" }}
            h={{ base: "400px", md: "500px", lg: "600px" }}
            backgroundPosition="center"
            backgroundSize="contain"
            aspectRatio={3 / 4}
            bgRepeat="no-repeat"
            display="flex"
          ></Flex>
        </HStack>
      </VStack>

      {/* mobile view 2nd section */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"100px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"400px"}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          aspectRatio={3 / 4}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <Box
            as="img"
            src={GemstoneSecondSection}
            alt="Gems"
            position="center top"
            width="100%"
            height="100%"
            objectFit="contain"
          />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            Why The Chosen stOne?
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Many free gemstone recommendations are based on general factors like
            your Moon sign or Sun sign. However, this way the complexities of
            your birth chart remain ignored and can lead to unfavorable
            outcomes, such as activating 6th, 8th, or 12th houses of your chart.
            This can disturb your energy.
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Our report is the result of a thorough analysis of your complete
            birth chart, ensuring the gemstones we recommend align perfectly
            with your life goals and personal energies. This process requires
            time, effort, and expertise, which is why there is an energy
            exchange for the report.
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            If you choose to purchase a gemstone through us, your report will be
            free of charge.
          </Text>
          <Text
            fontFamily="Jost"
            fontSize="24px"
            fontWeight={600}
            lineHeight="30px"
            mt={5}
          >
            A Word of Caution
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Gemstones are powerful tools and should be worn only after
            consulting a qualified astrologer. Wearing a gemstone without
            guidance can give you ill effects. Our detailed analysis ensures you
            receive the right guidance, allowing you to wear your gemstone with
            confidence and purpose.
          </Text>

          <Button
            variant={"trPrimary"}
            title="Get The Chosen stOne Now!!"
            textTransform="none"
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://rzp.io/rzp/q75ESpJE", "_blank");
            }}
          />
        </Flex>
      </VStack>

      {/* web view 3rd section */}

      <VStack
        className={c2}
        bgImage={`url('${Background1}')`}
        w="full"
        h="auto"
        py="20"
        bgRepeat="no-repeat"
        backgroundSize="cover"
        alignItems="flex-end"
        justifyContent="center"
        flexDir="column"
        backgroundPosition="top"
        display={{ base: "none", md: "flex" }}
        mb="50px"
      >
        <HStack>
          <Flex
            bgImage={`url('${GemstoneThirdSection}')`}
            w={{ base: "full", md: "45%", lg: "50%", xl: "full" }}
            h={{ base: "400px", md: "700px", lg: "800px", xl: "800px" }}
            backgroundPosition={"center"}
            backgroundSize={"contain"}
            aspectRatio={3 / 4}
            bgRepeat="no-repeat"
            display={"flex"}
            ml={{ base: "10px", md: "10px" }}
          />
          <Flex
            alignItems={"start"}
            justifyContent={"start"}
            gap={8}
            mr={"30px"}
            flexDir={"column"}
            maxW={"50%"}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              textTransform={"capitalize"}
              fontWeight={900}
              lineHeight={"40px"}
            >
              What will you get ?
            </Text>
            <List
              spacing={3}
              fontFamily="Jost"
              fontSize="20px"
              fontWeight={400}
              lineHeight="30px"
              color="var(--astrology-nav)"
            >
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                Best-Suited precious and semi-precious stones and crystals,
                perfectly aligned with your birth chart
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                When and How to Wear Them: Clear instructions on the right day,
                time, and method for wearing your gemstone to maximize its
                benefits
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                Care and Maintenance: Guidance on how to cleanse, recharge, and
                care for your gemstone to retain its energy and effectiveness
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                Why to Wear Them: An in-depth explanation of how your
                recommended gemstone activates and improves certain areas of
                your life, specific areas of your life, be it health,
                relationships, or career
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                Energizing the Stone: Step-by-step rituals to energize and
                activate your gemstone for optimal results
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                About the Stones: Insights into the qualities, and significance
                of the recommended gemstones and crystals
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                Personalized Dos and Don’ts: Specific guidance tailored to your
                energy to ensure you get the most out of your gemstone
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                This report is your complete guide to harnessing the power of
                gemstones and crystals in a practical, meaningful way
              </ListItem>
              <ListItem display="flex" alignItems="flex-start">
                <ListIcon as={MdBrightness1} mt="6px" />
                You can expect to receive the report within 1 to 2 days
              </ListItem>
            </List>
          </Flex>
        </HStack>
      </VStack>

      {/* mobile view 3rd section */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"150px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"650px"}
          backgroundPosition={"center bottom"}
          backgroundSize={"cover"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <Box
            as="img"
            src={GemstoneThirdSection}
            alt="GemstoneThirdSection"
            position="center top"
            width="100%"
            height="100%"
            objectFit="contain"
          />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            What will you get ?
          </Text>
          <List
            marginX="8px"
            spacing={3}
            fontFamily="Jost"
            fontSize="20px"
            fontWeight={400}
            lineHeight="30px"
            color="var(--astrology-nav)"
          >
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              Best-Suited precious and semi-precious stones and crystals,
              perfectly aligned with your birth chart
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              When and How to Wear Them: Clear instructions on the right day,
              time, and method for wearing your gemstone to maximize its
              benefits
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              Care and Maintenance: Guidance on how to cleanse, recharge, and
              care for your gemstone to retain its energy and effectiveness
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              Why to Wear Them: An in-depth explanation of how your recommended
              gemstone activates and improves certain areas of your life,
              specific areas of your life, be it health, relationships, or
              career
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              Energizing the Stone: Step-by-step rituals to energize and
              activate your gemstone for optimal results
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              About the Stones: Insights into the qualities, and significance of
              the recommended gemstones and crystals
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              Personalized Dos and Don’ts: Specific guidance tailored to your
              energy to ensure you get the most out of your gemstone
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              This report is your complete guide to harnessing the power of
              gemstones and crystals in a practical, meaningful way
            </ListItem>
            <ListItem display="flex" alignItems="flex-start">
              <ListIcon as={MdBrightness1} mt="6px" />
              You can expect to receive the report within 1 to 2 days
            </ListItem>
          </List>
        </Flex>
      </VStack>
      {/* Gemstones */}
      <Sapphire />
      <YellowSapphire />
      <Hessonite />
      <Pearl />
      <Ruby />
      <Opal />
      <Emerald />
      <CatEye />
      <RedCoral />

      {/* Assurity icons */}
      <Assurity />
      <FAQS headText={"FAQS"} type="gemstones" hasBackground={false} />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Gemstones;
