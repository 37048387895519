import React from "react";
import Collaboration from "../features/Collaboration/Collaboration";
import ContactUs from "../features/ContactUs/ContactUs";
import FAQS from "../features/FAQS/FAQS";
import HomeComponent from "../features/Home/Home";
import WrittenNotes from "../features/Services/Notes/WrittenNotes";
import OneToOneConsultant from "../features/Services/OneToOneConsultant/OneToOneConsultant";
import Services from "../features/Services/Services";
import Numerology from "../features/Numerology/Numerology";
import Commingsoon from "../features/CommingSoon/Commingsoon";
import Donation from "../features/Donation/Donation";
import TermsAndConditions from "../features/TermsConditions/Terms";
import Gemstones from "../features/Gemstones/Gemstones";

const serviceSubComponents = [
  {
    path: "/services/consultation",
    name: "Consultation with Jay",
    menuItem: false,
    component: <OneToOneConsultant />,
  },
  {
    path: "/services/notes",
    name: "Written Notes",
    menuItem: false,
    component: <WrittenNotes />,
  },
  {
    path: "/services/numerology",
    name: "Numerology",
    menuItem: false,
    component: <Numerology />,
  },
  {
    path: "/services/reports",
    name: "Reports",
    menuItem: false,
    component: <Commingsoon />,
  },
];

const productsSubComponents = [
  {
    path: "/products/gemstones",
    name: "Gemstones",
    menuItem: false,
    component: <Gemstones />,
  },
];

//  if routes are change also change it to footer file
export const authRoutes = [
  {
    path: "/",
    name: "",
    menuItem: false,
    component: <HomeComponent />,
  },
  {
    path: "/home",
    name: "Home",
    menuItem: true,
    component: <HomeComponent />,
  },
  {
    path: "/services/consultation",
    name: "One on one consultation",
    menuItem: false,
    component: <OneToOneConsultant />,
  },
  {
    path: "/services/notes",
    name: "Written Notes",
    menuItem: false,
    component: <WrittenNotes />,
  },
  {
    path: "/services/numerology",
    name: "Numerology",
    menuItem: false,
    component: <Numerology />,
  },
  {
    path: "/services/reports",
    name: "Reports",
    menuItem: false,
    component: <Commingsoon />,
  },
  {
    path: "/services/consultation",
    name: "Services",
    menuItem: true,
    subComponent: serviceSubComponents,
    component: <Services />,
  },
  {
    path: "/products/gemstones",
    name: "Products",
    menuItem: true,
    subComponent: productsSubComponents,
    component: <Gemstones />,
  },
  {
    path: "/donation",
    name: "Donation",
    menuItem: false,
    component: <Donation />,
  },
  {
    path: "/faqs",
    name: "FAQS",
    menuItem: false,
    component: <FAQS />,
  },
  {
    path: "/collaboration",
    name: "Collaboration",
    menuItem: true,
    component: <Collaboration />,
  },
  {
    path: "/contact",
    name: "Contact Team",
    menuItem: true,
    component: <ContactUs />,
  },
  {
    path: "/tandc",
    name: "Terms and Conditions",
    menuItem: false,
    component: <TermsAndConditions />,
  },
];
