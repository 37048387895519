// navigateWithSection.js (utility file)

import { useNavigate } from "react-router-dom";

const useNavigateWithSection = () => {
  const navigate = useNavigate();

  const navigateToSection = (path, sectionId) => {
    // Navigate to the route without the hash
    navigate(path);

    // Wait until the navigation completes, then scroll to the section
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay to ensure the new page is loaded
  };

  return { navigateToSection };
};

export default useNavigateWithSection;
