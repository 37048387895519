import { Stack, Text, VStack } from "@chakra-ui/react";
import Background4 from "../../images/Home-Bg4.compressed.svg";
import { ResponsiveSlider } from "../../components/ResponsiveSlider";

const Testimonials = ({ screen }) => {
  return (
    <>
      <VStack
        w="100vw"
        h={{ base: "800px", md: "900px" }}
        alignItems={"center"}
        justifyContent={"center"}
        flexDir={"column"}
        display={{ base: "none", md: "flex" }}
        overflow={"hidden"}
        zIndex={10}
        border="32px solid transparent"
        style={{
          borderImage: `url('${Background4}') 32 fill`,
          borderImageSlice: "32 fill",
          borderImageWidth: "32px",
        }}
      >
        <Text
          mt={8}
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "24px", md: "36px" }} // Responsive font size
          textTransform={"capitalize"}
          fontWeight={1000}
          lineHeight={{ base: "40px", md: "70px" }}
        >
          Life Changing Stories
        </Text>
        <Stack style={{ width: "95vw", paddingX: "24px" }}>
          <ResponsiveSlider screen={screen} />
        </Stack>
      </VStack>

      <VStack
        w="100vw"
        h={{ base: "860px", md: "900px" }}
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        display={{ base: "flex", md: "none" }}
        gap={1}
        zIndex={10}
        p={2}
        border="32px solid transparent"
        sx={{
          borderImage: `url('${Background4}') 32 fill`,
          borderImageSlice: "32 fill",
          borderImageWidth: "32px",
        }}
      >
        <Text
          mt={8}
          fontFamily="Fondamento, sans-serif"
          fontSize={{ base: "24px", md: "36px" }} // Responsive font size
          textTransform="capitalize"
          fontWeight={1000}
          // lineHeight={{ base: "40px", md: "70px" }}
        >
          Life Changing Stories
        </Text>
        <Stack style={{ width: "99%", paddingX: "8px" }}>
          <ResponsiveSlider screen={screen} mob={true} />
        </Stack>
      </VStack>
    </>
  );
};

export default Testimonials;
