export const SampleReportText = `
<html>
    <head>
        <meta content="text/html; charset=utf-8" http-equiv="content-type" />
        <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=ZWjJg3Z-BmxuodZeCNuZK0x0JgEQxzhNGumvBIBeqnuCYqoUBKs_Zdq7Ku0clX38);

      .lst-kix_2pwmsj3vn4m0-7 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-7;
      }

      ol.lst-kix_1z6q89hfw1p-6.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-6 0;
      }

      ol.lst-kix_fnxtu971ku3a-3.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-3 0;
      }

      ol.lst-kix_isgnzfu6iina-3.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-3 0;
      }

      ol.lst-kix_xvj810p0nm2n-1.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-1 0;
      }

      .lst-kix_uwtrugqpen3i-2 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-2;
      }

      ol.lst-kix_2pwmsj3vn4m0-7.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-7 0;
      }

      ol.lst-kix_rfdhzkkds8w-1.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-1 0;
      }

      .lst-kix_isgnzfu6iina-0 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-0;
      }

      .lst-kix_1ovy383n3v89-8 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-8;
      }

      .lst-kix_2pwmsj3vn4m0-5 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-5;
      }

      .lst-kix_isgnzfu6iina-8 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-8, lower-roman) ". ";
      }

      ol.lst-kix_uwtrugqpen3i-5.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-5 0;
      }

      ol.lst-kix_2pwmsj3vn4m0-1.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-1 0;
      }

      .lst-kix_fnxtu971ku3a-0 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-0;
      }

      ol.lst-kix_xvj810p0nm2n-7.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-7 0;
      }

      .lst-kix_isgnzfu6iina-2 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-2;
      }

      .lst-kix_xvj810p0nm2n-5 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-5;
      }

      .lst-kix_uwtrugqpen3i-4 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-4;
      }

      ol.lst-kix_356d8n1okyab-3 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-2 {
        list-style-type: none;
      }

      .lst-kix_isgnzfu6iina-4 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-4;
      }

      ol.lst-kix_356d8n1okyab-1 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-0 {
        list-style-type: none;
      }

      ol.lst-kix_1z6q89hfw1p-1.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-1 0;
      }

      .lst-kix_1z6q89hfw1p-2 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-2;
      }

      .lst-kix_uwtrugqpen3i-6 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-6;
      }

      ol.lst-kix_uwtrugqpen3i-0.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-0 0;
      }

      .lst-kix_uwtrugqpen3i-0 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-0;
      }

      ol.lst-kix_fnxtu971ku3a-8.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-8 0;
      }

      .lst-kix_1z6q89hfw1p-7 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-7;
      }

      ol.lst-kix_fnxtu971ku3a-2.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-2 0;
      }

      .lst-kix_xvj810p0nm2n-7 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-7;
      }

      .lst-kix_1ovy383n3v89-6 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-6;
      }

      ol.lst-kix_isgnzfu6iina-8.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-8 0;
      }

      ol.lst-kix_356d8n1okyab-8 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-7 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-6 {
        list-style-type: none;
      }

      .lst-kix_356d8n1okyab-8 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-8;
      }

      ol.lst-kix_rfdhzkkds8w-6.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-6 0;
      }

      ol.lst-kix_356d8n1okyab-5 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-4 {
        list-style-type: none;
      }

      ol.lst-kix_xvj810p0nm2n-6.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-6 0;
      }

      .lst-kix_rfdhzkkds8w-7 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-7;
      }

      ol.lst-kix_isgnzfu6iina-2.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-2 0;
      }

      ol.lst-kix_1z6q89hfw1p-7.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-7 0;
      }

      .lst-kix_fnxtu971ku3a-2 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-2;
      }

      ol.lst-kix_rfdhzkkds8w-0.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-0 0;
      }

      .lst-kix_xvj810p0nm2n-1 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-1;
      }

      ol.lst-kix_rfdhzkkds8w-7.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-7 0;
      }

      ol.lst-kix_1z6q89hfw1p-0.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-0 0;
      }

      ol.lst-kix_2pwmsj3vn4m0-6.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-6 0;
      }

      .lst-kix_1ovy383n3v89-4 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-4;
      }

      .lst-kix_356d8n1okyab-4 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-4, lower-latin) ". ";
      }

      .lst-kix_356d8n1okyab-6 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-6, decimal) ". ";
      }

      .lst-kix_fnxtu971ku3a-7 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-7;
      }

      ol.lst-kix_1ovy383n3v89-6.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-6 0;
      }

      .lst-kix_isgnzfu6iina-7 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-7;
      }

      ol.lst-kix_xvj810p0nm2n-8.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-8 0;
      }

      ol.lst-kix_rfdhzkkds8w-8.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-8 0;
      }

      .lst-kix_1z6q89hfw1p-5 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-5;
      }

      .lst-kix_isgnzfu6iina-6 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-6;
      }

      ol.lst-kix_fnxtu971ku3a-7.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-7 0;
      }

      ol.lst-kix_fnxtu971ku3a-8 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-6.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-6 0;
      }

      ol.lst-kix_fnxtu971ku3a-7 {
        list-style-type: none;
      }

      ol.lst-kix_fnxtu971ku3a-6 {
        list-style-type: none;
      }

      ol.lst-kix_fnxtu971ku3a-5 {
        list-style-type: none;
      }

      ol.lst-kix_fnxtu971ku3a-4 {
        list-style-type: none;
      }

      ol.lst-kix_fnxtu971ku3a-3 {
        list-style-type: none;
      }

      .lst-kix_356d8n1okyab-8 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-8, lower-roman) ". ";
      }

      ol.lst-kix_fnxtu971ku3a-2 {
        list-style-type: none;
      }

      ol.lst-kix_fnxtu971ku3a-1 {
        list-style-type: none;
      }

      ol.lst-kix_fnxtu971ku3a-0 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-8.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-8 0;
      }

      ol.lst-kix_1z6q89hfw1p-2.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-2 0;
      }

      .lst-kix_rfdhzkkds8w-1 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-1, lower-latin) ". ";
      }

      ol.lst-kix_xvj810p0nm2n-8 {
        list-style-type: none;
      }

      ol.lst-kix_xvj810p0nm2n-7 {
        list-style-type: none;
      }

      .lst-kix_1ovy383n3v89-3 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-3;
      }

      .lst-kix_1ovy383n3v89-5 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-5;
      }

      ol.lst-kix_xvj810p0nm2n-6 {
        list-style-type: none;
      }

      ol.lst-kix_xvj810p0nm2n-5 {
        list-style-type: none;
      }

      .lst-kix_1z6q89hfw1p-3 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-3, decimal) ". ";
      }

      ol.lst-kix_fnxtu971ku3a-4.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-4 0;
      }

      ol.lst-kix_xvj810p0nm2n-4 {
        list-style-type: none;
      }

      ol.lst-kix_xvj810p0nm2n-3 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-7.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-7 0;
      }

      ol.lst-kix_xvj810p0nm2n-2 {
        list-style-type: none;
      }

      ol.lst-kix_xvj810p0nm2n-1 {
        list-style-type: none;
      }

      .lst-kix_1z6q89hfw1p-5 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-5, lower-roman) ". ";
      }

      ol.lst-kix_1z6q89hfw1p-5.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-5 0;
      }

      .lst-kix_2pwmsj3vn4m0-2 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-2;
      }

      .lst-kix_rfdhzkkds8w-0 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-0;
      }

      .lst-kix_isgnzfu6iina-5 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-5;
      }

      .lst-kix_xvj810p0nm2n-8 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-8;
      }

      .lst-kix_356d8n1okyab-6 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-6;
      }

      ol.lst-kix_isgnzfu6iina-4.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-4 0;
      }

      .lst-kix_356d8n1okyab-0 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-0, decimal) ". ";
      }

      .lst-kix_356d8n1okyab-2 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-2, lower-roman) ". ";
      }

      ol.lst-kix_1ovy383n3v89-3.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-3 0;
      }

      .lst-kix_1z6q89hfw1p-7 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-7, lower-latin) ". ";
      }

      .lst-kix_fnxtu971ku3a-6 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-6;
      }

      ol.lst-kix_fnxtu971ku3a-5.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-5 0;
      }

      ol.lst-kix_isgnzfu6iina-8 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-6 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-7 {
        list-style-type: none;
      }

      .lst-kix_xvj810p0nm2n-2 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-2;
      }

      ol.lst-kix_uwtrugqpen3i-4.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-4 0;
      }

      .lst-kix_isgnzfu6iina-0 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-0, decimal) ". ";
      }

      .lst-kix_rfdhzkkds8w-5 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-5;
      }

      .lst-kix_uwtrugqpen3i-1 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-1, lower-latin) ". ";
      }

      ol.lst-kix_2pwmsj3vn4m0-8.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-8 0;
      }

      .lst-kix_2pwmsj3vn4m0-8 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-8, lower-roman) ". ";
      }

      .lst-kix_isgnzfu6iina-2 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-2, lower-roman) ". ";
      }

      .lst-kix_isgnzfu6iina-4 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-4, lower-latin) ". ";
      }

      .lst-kix_356d8n1okyab-1 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-1;
      }

      .lst-kix_uwtrugqpen3i-3 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-3, decimal) ". ";
      }

      ol.lst-kix_isgnzfu6iina-4 {
        list-style-type: none;
      }

      .lst-kix_1z6q89hfw1p-1 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-1, lower-latin) ". ";
      }

      ol.lst-kix_1ovy383n3v89-4.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-4 0;
      }

      ol.lst-kix_isgnzfu6iina-5 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-2 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-3 {
        list-style-type: none;
      }

      .lst-kix_isgnzfu6iina-6 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-6, decimal) ". ";
      }

      ol.lst-kix_1z6q89hfw1p-4.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-4 0;
      }

      .lst-kix_xvj810p0nm2n-3 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-3;
      }

      ol.lst-kix_isgnzfu6iina-0 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-1 {
        list-style-type: none;
      }

      ol.lst-kix_isgnzfu6iina-5.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-5 0;
      }

      .lst-kix_uwtrugqpen3i-5 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-5, lower-roman) ". ";
      }

      ol.lst-kix_1ovy383n3v89-5.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-5 0;
      }

      ol.lst-kix_uwtrugqpen3i-3.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-3 0;
      }

      .lst-kix_uwtrugqpen3i-7 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-7, lower-latin) ". ";
      }

      .lst-kix_2pwmsj3vn4m0-2 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-2, lower-roman) ". ";
      }

      .lst-kix_uwtrugqpen3i-5 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-5;
      }

      .lst-kix_1z6q89hfw1p-0 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-0;
      }

      .lst-kix_2pwmsj3vn4m0-0 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-0, decimal) ". ";
      }

      .lst-kix_356d8n1okyab-7 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-7;
      }

      .lst-kix_2pwmsj3vn4m0-3 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-3;
      }

      .lst-kix_2pwmsj3vn4m0-6 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-6, decimal) ". ";
      }

      .lst-kix_1z6q89hfw1p-6 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-6;
      }

      ol.lst-kix_fnxtu971ku3a-6.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-6 0;
      }

      .lst-kix_2pwmsj3vn4m0-4 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-4, lower-latin) ". ";
      }

      ol.lst-kix_isgnzfu6iina-6.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-6 0;
      }

      .lst-kix_fnxtu971ku3a-1 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-1;
      }

      ol.lst-kix_uwtrugqpen3i-2.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-2 0;
      }

      .lst-kix_uwtrugqpen3i-3 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-3;
      }

      ol.lst-kix_fnxtu971ku3a-0.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-0 0;
      }

      ol.lst-kix_1z6q89hfw1p-3.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-3 0;
      }

      ol.lst-kix_356d8n1okyab-1.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-1 0;
      }

      .lst-kix_isgnzfu6iina-1 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-1;
      }

      .lst-kix_xvj810p0nm2n-4 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-4;
      }

      ol.lst-kix_1ovy383n3v89-2.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-2 0;
      }

      .lst-kix_rfdhzkkds8w-8 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-8;
      }

      ol.lst-kix_2pwmsj3vn4m0-4.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-4 0;
      }

      .lst-kix_xvj810p0nm2n-4 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-4, lower-latin) ". ";
      }

      .lst-kix_xvj810p0nm2n-6 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-6, decimal) ". ";
      }

      ol.lst-kix_1z6q89hfw1p-8 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-8.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-8 0;
      }

      .lst-kix_xvj810p0nm2n-1 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-1, lower-latin) ". ";
      }

      .lst-kix_xvj810p0nm2n-5 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-5, lower-roman) ". ";
      }

      ol.lst-kix_1z6q89hfw1p-4 {
        list-style-type: none;
      }

      .lst-kix_1ovy383n3v89-0 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-0;
      }

      ol.lst-kix_1z6q89hfw1p-5 {
        list-style-type: none;
      }

      .lst-kix_2pwmsj3vn4m0-8 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-8;
      }

      ol.lst-kix_1z6q89hfw1p-6 {
        list-style-type: none;
      }

      ol.lst-kix_1z6q89hfw1p-7 {
        list-style-type: none;
      }

      .lst-kix_xvj810p0nm2n-2 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-2, lower-roman) ". ";
      }

      ol.lst-kix_1z6q89hfw1p-0 {
        list-style-type: none;
      }

      ol.lst-kix_1z6q89hfw1p-1 {
        list-style-type: none;
      }

      ol.lst-kix_xvj810p0nm2n-4.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-4 0;
      }

      ol.lst-kix_1z6q89hfw1p-2 {
        list-style-type: none;
      }

      ol.lst-kix_1z6q89hfw1p-3 {
        list-style-type: none;
      }

      .lst-kix_xvj810p0nm2n-3 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-3, decimal) ". ";
      }

      .lst-kix_uwtrugqpen3i-1 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-1;
      }

      ol.lst-kix_isgnzfu6iina-1.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-1 0;
      }

      .lst-kix_356d8n1okyab-0 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-0;
      }

      .lst-kix_fnxtu971ku3a-3 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-3;
      }

      .lst-kix_xvj810p0nm2n-0 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-0, decimal) ". ";
      }

      .lst-kix_rfdhzkkds8w-6 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-6;
      }

      ol.lst-kix_1ovy383n3v89-2 {
        list-style-type: none;
      }

      ol.lst-kix_1z6q89hfw1p-8.start {
        counter-reset: lst-ctn-kix_1z6q89hfw1p-8 0;
      }

      ol.lst-kix_1ovy383n3v89-3 {
        list-style-type: none;
      }

      .lst-kix_2pwmsj3vn4m0-4 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-4;
      }

      .lst-kix_fnxtu971ku3a-1 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-1, lower-latin) ". ";
      }

      .lst-kix_fnxtu971ku3a-2 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-2, lower-roman) ". ";
      }

      ol.lst-kix_1ovy383n3v89-4 {
        list-style-type: none;
      }

      .lst-kix_356d8n1okyab-2 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-2;
      }

      ol.lst-kix_1ovy383n3v89-5 {
        list-style-type: none;
      }

      ol.lst-kix_1ovy383n3v89-6 {
        list-style-type: none;
      }

      ol.lst-kix_1ovy383n3v89-7 {
        list-style-type: none;
      }

      ol.lst-kix_1ovy383n3v89-8 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-5.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-5 0;
      }

      .lst-kix_fnxtu971ku3a-6 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-6, decimal) ". ";
      }

      .lst-kix_1ovy383n3v89-6 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-6, decimal) ". ";
      }

      .lst-kix_1ovy383n3v89-8 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-8, lower-roman) ". ";
      }

      ol.lst-kix_356d8n1okyab-6.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-6 0;
      }

      .lst-kix_1z6q89hfw1p-8 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-8;
      }

      .lst-kix_fnxtu971ku3a-0 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-0, decimal) ". ";
      }

      .lst-kix_fnxtu971ku3a-7 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-7, lower-latin) ". ";
      }

      .lst-kix_fnxtu971ku3a-8 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-8, lower-roman) ". ";
      }

      ol.lst-kix_1ovy383n3v89-1.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-1 0;
      }

      .lst-kix_1ovy383n3v89-7 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-7, lower-latin) ". ";
      }

      ol.lst-kix_xvj810p0nm2n-0 {
        list-style-type: none;
      }

      .lst-kix_1ovy383n3v89-2 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-2, lower-roman) ". ";
      }

      .lst-kix_1ovy383n3v89-4 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-4, lower-latin) ". ";
      }

      .lst-kix_1ovy383n3v89-2 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-2;
      }

      .lst-kix_1ovy383n3v89-1 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-1, lower-latin) ". ";
      }

      .lst-kix_1ovy383n3v89-5 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-5, lower-roman) ". ";
      }

      .lst-kix_rfdhzkkds8w-5 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-5, lower-roman) ". ";
      }

      ol.lst-kix_rfdhzkkds8w-1 {
        list-style-type: none;
      }

      ol.lst-kix_rfdhzkkds8w-2 {
        list-style-type: none;
      }

      .lst-kix_fnxtu971ku3a-5 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-5, lower-roman) ". ";
      }

      ol.lst-kix_1ovy383n3v89-7.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-7 0;
      }

      ol.lst-kix_rfdhzkkds8w-0 {
        list-style-type: none;
      }

      .lst-kix_rfdhzkkds8w-4 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-4, lower-latin) ". ";
      }

      .lst-kix_xvj810p0nm2n-8 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-8, lower-roman) ". ";
      }

      .lst-kix_1z6q89hfw1p-1 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-1;
      }

      .lst-kix_fnxtu971ku3a-3 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-3, decimal) ". ";
      }

      .lst-kix_fnxtu971ku3a-4 > li:before {
        content: "" counter(lst-ctn-kix_fnxtu971ku3a-4, lower-latin) ". ";
      }

      .lst-kix_1ovy383n3v89-3 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-3, decimal) ". ";
      }

      .lst-kix_rfdhzkkds8w-3 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-3, decimal) ". ";
      }

      .lst-kix_xvj810p0nm2n-7 > li:before {
        content: "" counter(lst-ctn-kix_xvj810p0nm2n-7, lower-latin) ". ";
      }

      .lst-kix_rfdhzkkds8w-1 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-1;
      }

      ol.lst-kix_rfdhzkkds8w-7 {
        list-style-type: none;
      }

      ol.lst-kix_rfdhzkkds8w-8 {
        list-style-type: none;
      }

      ol.lst-kix_rfdhzkkds8w-5 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-0.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-0 0;
      }

      ol.lst-kix_rfdhzkkds8w-6 {
        list-style-type: none;
      }

      ol.lst-kix_rfdhzkkds8w-3 {
        list-style-type: none;
      }

      ol.lst-kix_rfdhzkkds8w-4 {
        list-style-type: none;
      }

      .lst-kix_rfdhzkkds8w-4 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-4;
      }

      .lst-kix_fnxtu971ku3a-8 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-8;
      }

      ol.lst-kix_uwtrugqpen3i-1.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-1 0;
      }

      .lst-kix_1ovy383n3v89-0 > li:before {
        content: "" counter(lst-ctn-kix_1ovy383n3v89-0, decimal) ". ";
      }

      .lst-kix_rfdhzkkds8w-6 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-6, decimal) ". ";
      }

      .lst-kix_fnxtu971ku3a-5 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-5;
      }

      .lst-kix_rfdhzkkds8w-7 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-7, lower-latin) ". ";
      }

      ol.lst-kix_1ovy383n3v89-8.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-8 0;
      }

      .lst-kix_rfdhzkkds8w-8 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-8, lower-roman) ". ";
      }

      ol.lst-kix_356d8n1okyab-7.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-7 0;
      }

      ol.lst-kix_uwtrugqpen3i-7.start {
        counter-reset: lst-ctn-kix_uwtrugqpen3i-7 0;
      }

      ol.lst-kix_xvj810p0nm2n-5.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-5 0;
      }

      ol.lst-kix_1ovy383n3v89-0 {
        list-style-type: none;
      }

      ol.lst-kix_1ovy383n3v89-1 {
        list-style-type: none;
      }

      .lst-kix_2pwmsj3vn4m0-1 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-1;
      }

      .lst-kix_356d8n1okyab-5 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-5, lower-roman) ". ";
      }

      ol.lst-kix_uwtrugqpen3i-7 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-8 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-3.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-3 0;
      }

      .lst-kix_356d8n1okyab-3 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-3, decimal) ". ";
      }

      .lst-kix_356d8n1okyab-7 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-7, lower-latin) ". ";
      }

      .lst-kix_356d8n1okyab-5 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-5;
      }

      .lst-kix_rfdhzkkds8w-2 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-2;
      }

      ol.lst-kix_2pwmsj3vn4m0-8 {
        list-style-type: none;
      }

      .lst-kix_356d8n1okyab-4 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-4;
      }

      ol.lst-kix_2pwmsj3vn4m0-0.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-0 0;
      }

      ol.lst-kix_uwtrugqpen3i-1 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-1 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-2 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-0 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-3 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-0 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-2 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-5 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-5 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-6 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-4 {
        list-style-type: none;
      }

      ol.lst-kix_uwtrugqpen3i-3 {
        list-style-type: none;
      }

      .lst-kix_1z6q89hfw1p-4 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-4;
      }

      ol.lst-kix_2pwmsj3vn4m0-7 {
        list-style-type: none;
      }

      .lst-kix_xvj810p0nm2n-0 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-0;
      }

      ol.lst-kix_uwtrugqpen3i-4 {
        list-style-type: none;
      }

      ol.lst-kix_2pwmsj3vn4m0-6 {
        list-style-type: none;
      }

      ol.lst-kix_356d8n1okyab-5.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-5 0;
      }

      .lst-kix_rfdhzkkds8w-0 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-0, decimal) ". ";
      }

      .lst-kix_rfdhzkkds8w-2 > li:before {
        content: "" counter(lst-ctn-kix_rfdhzkkds8w-2, lower-roman) ". ";
      }

      ol.lst-kix_rfdhzkkds8w-2.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-2 0;
      }

      ol.lst-kix_1ovy383n3v89-0.start {
        counter-reset: lst-ctn-kix_1ovy383n3v89-0 0;
      }

      .lst-kix_1z6q89hfw1p-4 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-4, lower-latin) ". ";
      }

      ol.lst-kix_xvj810p0nm2n-0.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-0 0;
      }

      ol.lst-kix_356d8n1okyab-2.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-2 0;
      }

      ol.lst-kix_rfdhzkkds8w-5.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-5 0;
      }

      .lst-kix_1z6q89hfw1p-6 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-6, decimal) ". ";
      }

      .lst-kix_356d8n1okyab-1 > li:before {
        content: "" counter(lst-ctn-kix_356d8n1okyab-1, lower-latin) ". ";
      }

      .lst-kix_356d8n1okyab-3 > li {
        counter-increment: lst-ctn-kix_356d8n1okyab-3;
      }

      .lst-kix_isgnzfu6iina-8 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-8;
      }

      .lst-kix_uwtrugqpen3i-7 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-7;
      }

      .lst-kix_rfdhzkkds8w-3 > li {
        counter-increment: lst-ctn-kix_rfdhzkkds8w-3;
      }

      ol.lst-kix_fnxtu971ku3a-1.start {
        counter-reset: lst-ctn-kix_fnxtu971ku3a-1 0;
      }

      .lst-kix_1z6q89hfw1p-8 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-8, lower-roman) ". ";
      }

      .lst-kix_1ovy383n3v89-1 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-1;
      }

      .lst-kix_1ovy383n3v89-7 > li {
        counter-increment: lst-ctn-kix_1ovy383n3v89-7;
      }

      ol.lst-kix_rfdhzkkds8w-3.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-3 0;
      }

      ol.lst-kix_xvj810p0nm2n-3.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-3 0;
      }

      .lst-kix_fnxtu971ku3a-4 > li {
        counter-increment: lst-ctn-kix_fnxtu971ku3a-4;
      }

      .lst-kix_uwtrugqpen3i-0 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-0, decimal) ". ";
      }

      .lst-kix_isgnzfu6iina-1 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-1, lower-latin) ". ";
      }

      .lst-kix_uwtrugqpen3i-2 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-2, lower-roman) ". ";
      }

      .lst-kix_isgnzfu6iina-3 > li {
        counter-increment: lst-ctn-kix_isgnzfu6iina-3;
      }

      ol.lst-kix_356d8n1okyab-3.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-3 0;
      }

      .lst-kix_1z6q89hfw1p-2 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-2, lower-roman) ". ";
      }

      .lst-kix_isgnzfu6iina-3 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-3, decimal) ". ";
      }

      .lst-kix_isgnzfu6iina-7 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-7, lower-latin) ". ";
      }

      .lst-kix_uwtrugqpen3i-6 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-6, decimal) ". ";
      }

      ol.lst-kix_2pwmsj3vn4m0-2.start {
        counter-reset: lst-ctn-kix_2pwmsj3vn4m0-2 0;
      }

      .lst-kix_uwtrugqpen3i-4 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-4, lower-latin) ". ";
      }

      .lst-kix_isgnzfu6iina-5 > li:before {
        content: "" counter(lst-ctn-kix_isgnzfu6iina-5, lower-roman) ". ";
      }

      .lst-kix_1z6q89hfw1p-0 > li:before {
        content: "" counter(lst-ctn-kix_1z6q89hfw1p-0, decimal) ". ";
      }

      ol.lst-kix_isgnzfu6iina-0.start {
        counter-reset: lst-ctn-kix_isgnzfu6iina-0 0;
      }

      .lst-kix_2pwmsj3vn4m0-1 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-1, lower-latin) ". ";
      }

      .lst-kix_xvj810p0nm2n-6 > li {
        counter-increment: lst-ctn-kix_xvj810p0nm2n-6;
      }

      .lst-kix_1z6q89hfw1p-3 > li {
        counter-increment: lst-ctn-kix_1z6q89hfw1p-3;
      }

      ol.lst-kix_rfdhzkkds8w-4.start {
        counter-reset: lst-ctn-kix_rfdhzkkds8w-4 0;
      }

      .lst-kix_uwtrugqpen3i-8 > li:before {
        content: "" counter(lst-ctn-kix_uwtrugqpen3i-8, lower-roman) ". ";
      }

      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }

      .lst-kix_2pwmsj3vn4m0-0 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-0;
      }

      .lst-kix_uwtrugqpen3i-8 > li {
        counter-increment: lst-ctn-kix_uwtrugqpen3i-8;
      }

      .lst-kix_2pwmsj3vn4m0-7 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-7, lower-latin) ". ";
      }

      .lst-kix_2pwmsj3vn4m0-6 > li {
        counter-increment: lst-ctn-kix_2pwmsj3vn4m0-6;
      }

      ol.lst-kix_xvj810p0nm2n-2.start {
        counter-reset: lst-ctn-kix_xvj810p0nm2n-2 0;
      }

      ol.lst-kix_356d8n1okyab-4.start {
        counter-reset: lst-ctn-kix_356d8n1okyab-4 0;
      }

      .lst-kix_2pwmsj3vn4m0-3 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-3, decimal) ". ";
      }

      .lst-kix_2pwmsj3vn4m0-5 > li:before {
        content: "" counter(lst-ctn-kix_2pwmsj3vn4m0-5, lower-roman) ". ";
      }

      ol {
        margin: 0;
        padding: 0;
      }

      table td,
      table th {
        padding: 0;
      }

      .c2 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
      }

      .c16 {
        color: #073763;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 24pt;
        font-family: "Times New Roman";
        font-style: normal;
      }

      .c17 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: center;
      }

      .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      .c12 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }

      .c1 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal;
      }

      .c0 {
        font-size: 10pt;
        font-family: "Nunito";
        font-weight: 500;
      }

      .c22 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }

      .c7 {
        font-weight: 700;
        font-size: 10pt;
        font-family: "Arial";
      }

      .c6 {
        font-size: 10pt;
        font-family: "Nunito";
        font-weight: 400;
      }

      .c5 {
        font-size: 10pt;
        font-family: "Nunito";
        font-weight: 700;
      }

      .c8 {
        font-weight: 700;
        font-size: 11pt;
        font-family: "Times New Roman";
      }

      .c18 {
        font-weight: 400;
        font-size: 10pt;
        font-family: "Arial";
      }

      .c13 {
        font-weight: 400;
        font-size: 11pt;
        font-family: "Arial";
      }

      .c20 {
        margin-left: 36pt;
        height: 16pt;
      }

      .c9 {
        border: 1px solid black;
        margin: 5px;
      }

      .c11 {
        margin-left: 36pt;
        padding-left: 0pt;
      }

      .c24 {
        padding: 0;
        margin: 0;
      }

      .c4 {
        margin-left: 72pt;
        height: 16pt;
      }

      .c21 {
        font-weight: 700;
        font-family: "Times New Roman";
      }

      .c19 {
        font-size: 10pt;
      }

      .c27 {
        margin-left: 72pt;
      }

      .c14 {
        font-style: italic;
      }

      .c23 {
        font-size: 24pt;
      }

      .c25 {
        margin-left: 67.5pt;
      }

      .c10 {
        page-break-after: avoid;
      }

      .c26 {
        color: #073763;
      }

      .c15 {
        height: 11pt;
      }

      .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      .blur-content {
        filter: blur(4px);
      }

      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }

      h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }

      h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>
    </head>

    <body class="c22 doc-content" oncopy="return false" oncut="return false">
        <p class="c17"><span class="c16"> </span></p>
        <p class="c17">
            <span class="c21 c23 c26 blur-content"> Vishwa
                Shah</span><sup></sup>
        </p>
        <p class="c3 c15"><span class="c1 c21 c23"></span></p>
        <p class="c3 c15"><span class="c1 c8"></span></p>
        <p class="c3 c15"><span class="c1 c8"></span></p>
        <p class="c3"><span class="c1 c8">These are your details:</span></p>
        <p class="c3 c15"><span class="c1 c8"></span></p>
        <p class="c3 blur-content">
            <span class="c6">6/22/1998</span><span class="c19"> </span><span
                class="c6">11:32:00 AM</span><span class="c19"> </span><span
                class="c6">NAGPUR MAHARASHTRA</span><sup></sup>
        </p>
        <p class="c3 c15"><span class="c1 c6"></span></p>
        <p class="c3 c15"><span class="c1 c6"></span></p>
        <p class="c3 c15"><span class="c1 c18"></span></p>
        <p class="c3"><span class="c21">Your Questions:</span></p>
        <p class="c2"><span class="c1 c7"></span></p>
        <p class="c2"><span class="c1 c7"></span></p>
        <p class="c3">
            <span class="c1 c5">1. I am not doing well in my career. I have been
                struggling with my CFA
                exams for a long time but I am not able to clear them. Can you
                please
                help me with the remedies?
            </span>
        </p>
        <p class="c2"><span class="c1 c5"></span></p>
        <p class="c3">
            <span class="c5">Answer: </span><span class="c0">Your 10th house,
                which governs your career and profession, is ruled by
                Venus, and Venus itself is positioned in the 10th house along
                with Mars. </span><span class="c0 blur-content">Venus, being
                associated with creativity, beauty, and the arts, combined
                with Mars, the planet of energy, drive, and ambition, indicates
                that you
                may have a strong desire for success in your professional life.
            </span><sup></sup>
        </p>
        <p class="c3 c15"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0">This conjunction suggests you will be highly
                motivated to achieve your
                goals, particularly in careers that involve aesthetics,
                creativity, or
                design. Professions where creativity meets action will resonate
                with you
                due to this powerful Venus-Mars combination.</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0">The aspect of Jupiter on your 2nd house, which
                represents income and
                finances, further strengthens the likelihood of financial
                stability and
                success. With Mercury ruling your 2nd house, and Mercury itself
                placed
                in your 11th house of gains and desires alongside the Sun,
            </span><span class="c0 blur-content">this combination points to
                strong communication and interpersonal
                skills. You are likely to have a sharp intellect and may find
                success in
                careers requiring intellectual engagement. Mercury’s connection
                to
                business suggests that you could experience gains through
                entrepreneurial pursuits, particularly in industries related to
                fashion,
                design, or anything Venus represents. This overall placement
                hints that
                careers tied to Mars and Venus, as well as Mercury’s influence
                in your
                2nd house, </span><sup></sup><span class="c1 c0">will bring you
                financial success and professional fulfillment. Some
                recommended professions for you are:</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">Engineering, IT/Technical field,
                anywhere you can put your personal
                energy like cooking, baking, software development etc. Police,
                Military,
                Industries, surgeon, marble related, earth related, broker,
                property
                related, construction, profession requiring courage, energy, and
                action.</span>
        </p>
        <p class="c2"><span class="c1 c6"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content"> Intelligence, education field,
                writing, import-export, communication,
                journalism, public speaking, bureaucrats, commerce, finance, CA,
                bankers, economist, Counsellor, Sales, marketing, data analysis,
                editing, professions requiring intellect within
                versatility.<br /></span>
        </p>
        <p class="c3">
            <span class="c0 blur-content">Art, creativity, ads, huge mansions,
                fashion industry, luxury goods,
                five star hotels, fast food chains, beauty industry, Any big
                scale
                business like showrooms, showcases, etc. Flowers, perfumes,
                event
                management, entertainment industry, interior design, professions
                related
                to harmony, aesthetics, and pleasure.</span><sup></sup>
        </p>
        <p class="c3 c15"><span class="c1 c6"></span></p>
        <p class="c3">
            <span class="c1 c5">2. Coming to love life, I have been single for a
                while now. How will my
                married life be? Shall I even marry! What would be the right
                time for me
                to marry?</span>
        </p>
        <p class="c2"><span class="c1 c5"></span></p>
        <p class="c3">
            <span class="c5">Answer: </span><span class="c0">Your 5th house,
                which represents love and relationships, is ruled by
                Jupiter, the planet of wisdom, growth, and abundance. Jupiter’s
                influence over this house generally suggests a harmonious and
                fulfilling
                love life. However, with Saturn’s aspect on your 5th house and
                its
                debilitated position in your chart, </span><span
                class="c0 blur-content">you may face challenges and setbacks in
                relationships</span><sup></sup><span class="c0 blur-content">.
                Saturn represents hard work, responsibility, and discipline,
                which
                means you will need to put in extra effort to achieve the kind
                of
                relationship you desire. Saturn demands structure and
                consistency in the
                areas it influences, including your love life. If you adopt
                these
                qualities and continue making efforts, Saturn will eventually
                reward you
                with a stable and successful relationship.</span><sup></sup>
        </p>
        <p class="c2"><span class="c0 c1"></span></p>
        <p class="c3">
            <span class="c0">Saturn also rules your 7th house, which governs
                marriage and
                partnerships.</span><span class="c1 c0 blur-content"> Whenever
                Saturn forms a connection with the house of marriage, the
                marriage tends to get delayed and if you do before the time,
                then there
                can be problems and it might end up in divorce also.</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0 blur-content">The age group being </span><span
                class="c1 c5 blur-content">28-33.</span>
        </p>
        <p class="c2"><span class="c1 c5"></span></p>
        <p class="c3">
            <span class="c0 blur-content">Do not get into casuals as they are
                not for you. Aim for long term
                relationships.</span><sup></sup>
        </p>
        <p class="c2"><span class="c1 c6"></span></p>
        <p class="c3">
            <span class="c0">While Saturn’s influence may bring some challenges,
            </span><span class="c0 blur-content">it also encourages long-term
                stability once the right efforts are
                made</span><sup></sup><span class="c0">. </span><span
                class="c5">Ketu</span><span class="c0"> in your </span><span
                class="c5">7th house</span><span class="c0">, let's understand
                Ketu first.</span><span class="c1 c0 blur-content"> Ketu is
                someone who doesn't have a head. He cannot think mentally
                properly. Because he doesn't have a head, he is always confused.
                He
                makes you feel directionlessness, he makes you feel lost and he
                makes
                you feel like nothing is worth it. And when you get confused
                about a lot
                of things and you don't understand them, you eventually get
                detached
                from them. That is why people say that Ketu detaches itself.
            </span>
        </p>
        <h2 class="c3 c20" id="h.m5vxl1wzcr3o"><span class="c1 c0"></span></h2>
        <h2 class="c3" id="h.m5vxl1wzcr3o-1">
            <span class="c1 c0 blur-content">Because Ketu in the 7th house
                doesn't mean that you will be detached
                from your partner. It also means wherever Ketu is placed in your
                chart
                and in that area, you need to put in constant focus, active
                focus,
                constant efforts and attention to that particular area. So if
                Ketu is
                present in your 7th house, it means that you will have to put
                constant
                focus and efforts or attention in your married life to maintain
                it.
            </span>
        </h2>
        <h2 class="c3 c4" id="h.cik8xjbzrqru"><span class="c1 c0"></span></h2>
        <p class="c3 c15"><span class="c1 c13"></span></p>
        <h2 class="c3" id="h.m5vxl1wzcr3o-2">
            <span class="c1 c0 blur-content">Because you cannot put it on
                autopilot mode. Wherever Ketu is placed in
                your chart, you can't put that area on autopilot mode that
                requires your
                efforts constantly every day. That requires your attention in
                order to
                maintain it. Ketu in the 7th house also means that your spouse
                might be
                a little bit spiritual and you might get into spirituality after
                your
                marriage. Ketu in the 7th house also explains that you are very
                precise
                when it comes to choosing your spouse. You will filter out a lot
                of
                people and you will see people for who they are. You will give
                attention
                to the details and observe the person and then you will finalize
                and
                choose the person. This is not all negative. People make it
                negative
                because generally people cannot handle Ketu.</span>
        </h2>
        <h2 class="c3 c4" id="h.m5vxl1wzcr3o-3"><span class="c1 c0"></span></h2>
        <h2 class="c3" id="h.m5vxl1wzcr3o-4">
            <span class="c1 c0 blur-content">Ketu is the spiritual lord which
                requires spirituality. So wherever it
                is placed, that area of life you don't see practically or
                logically.
                Because Ketu doesn't understand practicality and logic. It is
                above
                that. It functions on a spiritual plane. So that area of life
                needs to
                have the spiritual touch.
            </span>
        </h2>
        <h2 class="c3 c4" id="h.ufe4fjajlkhb"><span class="c1 c0"></span></h2>
        <h2 class="c3 c10" id="h.5u4ouqsxlrhw">
            <span class="c0 blur-content">That area of life needs to be seen
                from a spiritual perspective in your
                life, this is what Ketu suggests. It is nothing bad. You just
                have to
                focus on it. Make sure your partner is spiritual in nature,
                otherwise it
                will give serious problems in your marriage
                life.<br /></span><sup></sup>
        </h2>
        <p class="c3">
            <span class="c0">Your chart holds strong potential for a fulfilling
                married life, but
                you will need to make efforts to maintain harmony and stability.
                Doing
                remedies for Saturn will help you overcome the challenges caused
                by its
                debilitation. As you are currently going through the
            </span><span class="c5">Rahu Mahadasha, </span><span class="c0">the
            </span><span class="c5">Antardasha of Saturn,</span><span
                class="c0"> which will begin in </span><span class="c5">July
                2026</span><span class="c0">, is expected to bring positive
                results in terms of your marriage.
            </span>
            <span class="c0 blur-content">With </span><span
                class="c5 blur-content">Rahu’s Mahadasha</span><span
                class="c0 blur-content">, he can fulfill all your desires and
                can make you rise up in a grand
                way, if he is controlled. But currently, he is out of control.
                Also,
                With this dasha set you are experiencing a lot of other things,
                too,
                like, Rahu makes you think a lot, It gives you overthinking. It
                gives
                you over analyzing of things and it is like the fluctuating
                current in
                your mind that never goes off, if you don't control it. It gives
                you
                next-level anxiety and panic attacks also It can give you
                insomnia, you
                might be facing problems sleeping. With </span><span
                class="c5 blur-content">Saturn's Antardasha</span><span
                class="c1 c0 blur-content"> being there, Saturn gives you
                self-doubt, insecurity and low
                self-esteem. So you might be feeling very low inside yourself,
                very
                helpless inside yourself.
            </span>
        </p>
        <p class="c3 c15 c25"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">Rahu basically gives uncertainty.
                Rahu also gives you instability. It
                can drive you crazy if it is not controlled. Rahu is like that
                mastermind who can think on an extraordinary level, he also
                gives a
                rebellious nature, you might get unethical thoughts or might get
                into
                any addiction, don't act on that.</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0 blur-content">Additionally, the transit of Jupiter
                in Leo during</span><span class="c5 blur-content"> October
                2026</span><span class="c0 blur-content"> will be highly
                beneficial, as Jupiter will then aspect your 7th house,
                opening up opportunities for marriage.</span><sup></sup>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c2"><span class="c1 c0"></span></p>
        <h2 class="c3 c10" id="h.41qvo5dm66x6">
            <span class="c1 c5">Remedies:</span>
        </h2>
        <ol class="c24 lst-kix_fnxtu971ku3a-0 start" start="1">
            <li class="c3 c11 li-bullet-0">
                <span class="c6 blur-content">Chant </span><span
                    class="c5 blur-content">“Hanuman Chalisa” </span><span
                    class="c1 c6 blur-content">everyday</span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="c1 c6 blur-content">Always respect your servants,
                    subordinates, people whosoever are
                    working for you or any service class people.
                </span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="c1 c6 blur-content">Donate to the labor
                    class.</span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="c6 blur-content">Go to Hanuman Temple every Tuesday
                    and offer </span><span
                    class="c6 blur-content">boondi</span><span
                    class="c6 blur-content">.</span><sup></sup>
            </li>
        </ol>
        <p class="c3 c15"><span class="c1 c6"></span></p>
        <p class="c3">
            <span class="c1 c5">3. I believe I am under confident. I picture
                myself being a powerful
                lady but I am afraid I did get a constant betrayal in terms of
                money and
                relationships. Over the past 2 years. Emotions took on me, my
                mental
                health and physical health too. Please guide me how I can work
                on
                it.</span>
        </p>
        <p class="c2"><span class="c1 c5"></span></p>
        <p class="c3">
            <span class="c5">Answer: </span><span class="c0">You are currently
                going through the </span><span class="c5">Mahadasha of
                Rahu,</span><span class="c0"> which can bring both opportunities
                and challenges. Let’s understand
                Rahu,</span><span class="c1 c0 blur-content"> Rahu is uncertain,
                sudden, illusionary, impulsive. But, he is also a
                mastermind. Rahu’s Mahadasha is here so it will bring a lot of
                uncertainty, suddenness. If you plan long term, it will not
                work. So,
                execute on short term things or ideas. Enter into the uncertain
                nature
                of Rahu, tap into the uncertainty. Rahu is unpredictable and
                because he
                is active in your life, you will have to become unpredictable,
                too.
            </span>
        </p>
        <p class="c3 c15 c27"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">If you won’t tap into uncertainty,
                Rahu will show you the illusions. It
                will give you what you want but then, suddenly will it take it
                away,
                too. Rahu gives overthinking, depressive tendencies, fear and
                low
                self-esteem. It gives you confidence but very unstable
                confidence. It
                is like a constant roller coaster ride. So it is on you if you
                want to
                enjoy it now or not. But, the moment you start enjoying it and
                take
                control over it, it will fulfill your desires. It can make your
                unrealistic desires come true, too if it is controlled and you
                know how
                to use it. Rahu’s nature is very instinctive, too. So, tap in
                your
                instincts and do the things accordingly.</span>
        </p>
        <p class="c3 c15"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c5 c14 blur-content">Maa Kali</span><span
                class="c0 blur-content"> is the goddess of Rahu, doing her
                mantra will help you control your
                Rahu.</span><sup></sup>
        </p>
        <p class="c3 c15"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0">Rahu is placed in the 1st house of your chart,
                which represents your
                self and personality.Whenever Rahu is in the first
                house</span><span class="c1 c0 blur-content">, it gives you a
                lot of thinking. It's like a fluctuating current in
                your head. It gives you a lot of instability, uncertainty. It
                can give
                you insomnia also and you might face problems sleeping. You
                might have a
                tendency to get addicted to things.</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">You may create false narratives
                about things and situations which will
                give you frustration. You will become impulsive.</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">But on the other hand, you will
                have a great imagination and thinking
                capacity about your goals. It can give you a lot of crazy,
                amazing ideas
                in order for you to manifest desires to physicality.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">It will even give you the ways of
                making things happen, how to reach
                your goals.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">Keep in mind this will only happen
                if he's calm and controlled. If he's
                not in control, he can make you go crazy because your mind will
                run into
                multiple directions at the same time and you won't understand
                what is
                happening to you. You will lose focus. You will become
                distracted. You
                will feel emotional and mental turmoil. It will give you some
                kind of
                obsession.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">Use this obsession for things that
                you want and that are right for you
                and your growth, not for something that will harm you in the
                long run.
                Just take Rahu into control and it will work in your
                favor.</span>
        </p>
        <sup></sup>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0">Your Moon, the planet associated with your mind and
                emotions, is placed
                in the 9th house along with Saturn. When the </span><span
                class="c5">Moon</span><span class="c0"> is in</span><span
                class="c5"> conjunction </span><span class="c0">with
            </span><span class="c5">Saturn</span><span class="c0">,</span><span
                class="c1 c0 blur-content"> it can bring emotional challenges.
                Whenever the Moon sits with Saturn
                it gives you a lot of depressing tendencies, low self-esteem,
                self-doubt, it gives you a lot of fears, insecurities and you
                don't have
                confidence in yourself that you can do anything. It gives you
                overthinking about stuff also and gives you a lot of emotional
                turmoil.
                Saturn and the moon have very opposite energies. Moon is
                constantly
                changing and fluctuating while Saturn is a very slow planet
                which likes
                stability. Saturn restricts the free motion Moon wants.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">The difference of perception of
                both planets where the Moon is a
                creative perception of any given situation with hope and
                achievements
                but Saturn on the other hand, makes you aware of the realistic
                facts
                about the situation.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0 blur-content">With </span><span
                class="c5 blur-content">Moon-Saturn</span><span
                class="c1 c0 blur-content"> conjunction, you may feel restricted
                in your expression of love,
                emotion and affection in any relationship with family/partner
                but will
                be very helpful in taking responsibility and care of your
                partner/family
                with complete dedication by thinking about the security
                concerns,
                materialistic comfort of the loved ones.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">This conjunction also makes you
                suppress your feelings. Hence, the
                internal conflict.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0 blur-content">Practices like meditation,
                mindfulness, and emotional awareness will
                help in managing these feelings. Remedies for the Moon will also
                yield
                positive results for your emotional well-being.</span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c0 blur-content">Mercury, the planet that represents
                your skin, is aspected by Saturn in
                your chart. Since Saturn is debilitated, this aspect could lead
                to
                issues related to the skin, such as dryness, rashes, or other
                skin
                conditions. The 6th house, which rules health, enemies, and
                debts, is
                also governed by Saturn. This makes it very important for you to
                adopt a
                disciplined and structured approach, particularly when it comes
                to your
                health. A regular routine and balanced lifestyle will be key to
                mitigating any negative effects Saturn may
                cause.</span><sup></sup>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <p class="c3">
            <span class="c1 c0">Doing remedies for both Rahu and Saturn will
                help ease the challenges
                posed by these planets. The Mahadasha of Rahu can bring
                hardships, but
                performing the necessary remedies can soften its intensity.
            </span>
        </p>
        <p class="c2"><span class="c1 c0"></span></p>
        <h2 class="c3 c10" id="h.uxos05lqky3x">
            <span class="c1 c5">Remedies:</span>
        </h2>
        <ol class="c24 lst-kix_1ovy383n3v89-0 start" start="1">
            <li class="c3 c11 li-bullet-0">
                <h2 id="h.yxdecxbcwmco" style="display: inline"
                    class="blur-content">
                    <span class="c6">Start chanting </span><span class="c5">“Om
                        Kreem Kalikaye Namah”</span><span class="c1 c6"> 108
                        times daily. </span>
                </h2>
            </li>
            <li class="c3 c11 li-bullet-0">
                <h2 id="h.yxdecxbcwmco-5" style="display: inline"
                    class="blur-content">
                    <span class="c6">Start listening to </span><span
                        class="c5">“Aigiri Nandini”</span><span class="c1 c6">
                        everyday. </span>
                </h2>
            </li>
            <li class="c3 c11 li-bullet-0">
                <h2 id="h.yxdecxbcwmco-6" style="display: inline"
                    class="blur-content">
                    <span class="c1 c6">Feed stray dogs.</span>
                </h2>
            </li>
            <li class="c3 c11 li-bullet-0">
                <h2 id="h.yxdecxbcwmco-7" style="display: inline"
                    class="blur-content">
                    <span class="c1 c6">Keep your home entrance clean.</span>
                </h2>
            </li>
            <li class="c3 c11 li-bullet-0">
                <h2 id="h.yxdecxbcwmco-8" style="display: inline"
                    class="blur-content">
                    <span class="c6">Take a salt water bath, everyday. 2 pinches
                        in a bucket full.</span><sup></sup>
                </h2>
            </li>
        </ol>
        <p class="c3 c15"><span class="c1 c0"></span></p>
        <h2 class="c3 c10" id="h.hrkk1cqbkz53">
            <span class="c1 c5">Remedies:</span>
        </h2>
        <ol class="c24 lst-kix_rfdhzkkds8w-0 start" start="1">
            <li class="c3 c11 li-bullet-0">
                <span class="blur-content">
                    <span class="c6">Start chanting n</span><span class="c5">Om
                        Namah Shivaya </span><span class="c1 c6">108 times every
                        day. It will help you a lot with your emotional
                        turmoil.
                    </span>
                </span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="c1 c6 blur-content">Drink a lot of water. </span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="c1 c6 blur-content">Respect your mother a lot.
                    Build a good relationship with her. No
                    matter what.</span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="c1 c6 blur-content">Touch your mother’s feet
                    everyday, if she doesn't live with you, talk
                    to her, everyday.</span>
            </li>
            <li class="c3 c11 li-bullet-0">
                <span class="blur-content">
                    <span class="c6">Meditate on</span><span class="c5"> Lord
                        Shiva</span><sup></sup>
                </span>
            </li>
        </ol>
    </body>
</html>
`;
