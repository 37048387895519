import { defineStyleConfig, extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./Button/theme";
import { selectTheme } from "./Select/theme";
import { textTheme } from "./Text/theme";
import { textAreaTheme } from "./TextArea/theme";
import { textBoxTheme } from "./TextBox/theme";

const formTextCss = {
  fontSize: "0.75rem",
  mt: "0px",
};

const extendedTheme = (type = "default") => {
  return extendTheme({
    styles: {
      global: {
        ":root": {
          // define your custom css variable starting with double hyphen (--).
          // '--primary-gradient': 'linear-gradient(121.49deg, rgba(28, 44, 68, 0.89) 29.39%, rgba(12, 148, 172, 0.95) 94.25%)', // gradient with rgba
          "--primary-gradient":
            "linear-gradient(121.49deg, #1c2c44 29.39%, #0c94ac 94.25%)", // gradient with hex code
          "--new-primary-gradient":
            "linear-gradient(150.07deg, #06A293 5.64%, #07CBB8 53.75%, #012925 98.95%)", // gradient with hex code,
          "--heading-font-color": "#1C2C44",

          "--white-color": "#ffffff",
          "--black-color": "#000000",

          "--border-color": "#DEE8F8",
          "--theme-color": "#E674CC",
          "--boxShadow-color": "#EEE0EB",

          // Black colors
          "--black-100": "#1C1C1C",

          // astrology colors
          "--astrology-nav": "#68030D",
          "--astrology-mob-nav-bg": "#FDE9CF",
          "--astrology-mobnav-txt": "#68030Dbf",

          // atlantic colors
          "--atlantic-50": "#F3F7FF",
          "--atlantic-100": "#DEE8F8",
          "--atlantic-200": "#B7C8E1",
          "--atlantic-300": "#7284A0",
          "--atlantic-400": "#556781",
          "--atlantic-500": "#1C2C44",
          "--atlantic-600": "#162336",
          "--atlantic-700": "#111A29",
          "--atlantic-800": "#0B121B",
          "--atlantic-900": "#06090E",

          // Theme
          primary: "#68030D",
        },
      },
    },
    components: {
      Button: buttonTheme,
      Input: textBoxTheme,
      Text: textTheme,
      Textarea: textAreaTheme,
      Select: selectTheme,
      Popover: defineStyleConfig({
        zIndex: 1700,
      }),
      Skeleton: {
        baseStyle: {
          "--skeleton-start-color": "#E9F0FC", // Starting color of the skeleton animation
          "--skeleton-end-color": "#D3DBEB", // Ending color of the skeleton animation
        },
      },
    },
  });
};

export default extendedTheme;
