// ProtectedRoute.js
import React from "react";
import { Outlet } from "react-router-dom";
import SidebarWithHeader from "../components/Sidebar";

const ProtectedRoute = (props) => {
  // all the routes rest are redirected from here
  return (
    <>
      <SidebarWithHeader routes={props.routes}>
        <Outlet />
      </SidebarWithHeader>
    </>
  );
};

export default ProtectedRoute;
