import { Text } from "@chakra-ui/react";
import React from "react";

const CopyRight = () => {
  return (
    <Text
      variant={{ base: "15px", md: "lgFont" }}
      color={"var(--astrology-nav)"}
      fontWeight={500}
      textTransform={"uppercase"}
      letterSpacing={{ base: "1px", md: "1.28px" }}
    >
      © 2024 A chosen son. All rights reserved
    </Text>
  );
};

export default CopyRight;
