import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import "./GemsImageSlider.css";

import Hessonite01 from "../images/gemstones/Hessonite01.webp";
import Hessonite1 from "../images/gemstones/Hessonite1.webp";
import Hessonite2 from "../images/gemstones/Hessonite2.webp";
import Hessonite3 from "../images/gemstones/Hessonite3.webp";
import Hessonite4 from "../images/gemstones/Hessonite4.webp";
import RedCoral05 from "../images/gemstones/RedCoral05.webp";
import Ruby05 from "../images/gemstones/Ruby05.webp";
import Ruby01 from "../images/gemstones/Ruby01.webp";
import Ruby02 from "../images/gemstones/Ruby02.webp";
import Ruby03 from "../images/gemstones/Ruby03.webp";
import Ruby04 from "../images/gemstones/Ruby04.webp";
import Emerald05 from "../images/gemstones/Emerald05.webp";
import Emerald01 from "../images/gemstones/Emerald01.webp";
import Emerald02 from "../images/gemstones/Emerald02.webp";
import Emerald03 from "../images/gemstones/Emerald03.webp";
import Emerald04 from "../images/gemstones/Emerald04.webp";
import Pearl01 from "../images/gemstones/Pearl01.webp";
import Pearl02 from "../images/gemstones/Pearl02.webp";
import Pearl03 from "../images/gemstones/Pearl03.webp";
import Pearl04 from "../images/gemstones/Pearl04.webp";
import Pearl05 from "../images/gemstones/Pearl05.webp";
import BlueSapphire01 from "../images/gemstones/BlueSapphire01.webp";
import BlueSapphire02 from "../images/gemstones/BlueSapphire02.webp";
import BlueSapphire03 from "../images/gemstones/BlueSapphire03.webp";
import BlueSapphire04 from "../images/gemstones/BlueSapphire04.webp";
import BlueSapphire05 from "../images/gemstones/BlueSapphire05.webp";
import CatEye01 from "../images/gemstones/CatEye01.webp";
import CatEye02 from "../images/gemstones/CatEye02.webp";
import CatEye03 from "../images/gemstones/CatEye03.webp";
import CatEye04 from "../images/gemstones/CatEye04.webp";
import CatEye05 from "../images/gemstones/CatEye05.webp";
import RedCoral01 from "../images/gemstones/RedCoral01.webp";
import RedCoral02 from "../images/gemstones/RedCoral02.webp";
import RedCoral03 from "../images/gemstones/RedCoral03.webp";
import RedCoral04 from "../images/gemstones/RedCoral04.webp";
import Opal01 from "../images/gemstones/Opal01.webp";
import Opal02 from "../images/gemstones/Opal02.webp";
import Opal03 from "../images/gemstones/Opal03.webp";
import Opal04 from "../images/gemstones/Opal04.webp";
import Opal05 from "../images/gemstones/Opal05.webp";
import YellowSapphire01 from "../images/gemstones/YellowSapphire01.webp";
import YellowSapphire03 from "../images/gemstones/YellowSapphire03.webp";
import YellowSapphire05 from "../images/gemstones/YellowSapphire05.webp";
import YellowSapphire1 from "../images/gemstones/YellowSapphire1.webp";
import YellowSapphire2 from "../images/gemstones/YellowSapphire2.webp";
import YellowSapphire3 from "../images/gemstones/YellowSapphire3.webp";

const images = {
  blueSapphire: [
    BlueSapphire01,
    BlueSapphire05,
    BlueSapphire02,
    BlueSapphire04,
    BlueSapphire03,
  ],
  yellowSapphire: [
    YellowSapphire2,
    YellowSapphire3,
    YellowSapphire1,
    YellowSapphire01,
    YellowSapphire03,
    YellowSapphire05,
  ],
  hessonite: [Hessonite01, Hessonite2, Hessonite4, Hessonite1, Hessonite3],
  pearl: [Pearl03, Pearl02, Pearl01, Pearl04, Pearl05],
  ruby: [Ruby01, Ruby02, Ruby05, Ruby03, Ruby04],
  opal: [Opal02, Opal03, Opal01, Opal04, Opal05],
  emerald: [Emerald02, Emerald01, Emerald03, Emerald04, Emerald05],
  catEye: [CatEye01, CatEye02, CatEye03, CatEye04, CatEye05],
  redCoral: [RedCoral02, RedCoral03, RedCoral01, RedCoral04, RedCoral05],
};

const GemsImageSlider = ({ type }) => {
  return (
    <Swiper
      navigation
      modules={[Pagination, Navigation]}
      slidesPerView={1}
      style={{
        width: "100%",
        height: "100%",
      }}
      className="mySwiper"
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className} custom-bullet"></span>`;
        },
      }}
      loop={true}
    >
      {images[type]?.map((image, index) => (
        <SwiperSlide key={index}>
          <Image
            src={image}
            alt={`Gemstone ${index + 1}`}
            objectFit="cover"
            width="100%"
            height="100%"
            aspectRatio={1 / 1}
            p={10}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default GemsImageSlider;
