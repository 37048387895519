import React from "react";
import {
  Image,
  Flex,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import HomeBackground from "../../image/HomeBg.webp";
import Section2Image from "../../image/Home-Section-2.compressed.svg";
import HomeSection3Bg from "../../image/Home-Section-3-Bg.compressed.svg";
import MobileBackground01 from "../../images/mobile/about-us.png";
import { Button } from "@astrology/shared-components";
import useNavigateWithSection from "../../services/navigateWithSection";
import AboutUs1 from "../../image/AboutUs-1.svg";
import AboutUs2 from "../../image/AboutUs-2.svg";
import AboutUs3 from "../../image/AboutUs-3.svg";
import AboutUs4 from "../../image/AboutUs-4.svg";
import CommonServices from "../CommonServices";
import Footer from "../Footer/Footer";
import ConsultNow from "./ConsultNow";
import Testimonials from "../Testimonials/Testimonials";
// Section-1 Text Size
const textSize = {
  base: "26px",
  sm: "26px",
  md: "30px",
  lg: "40px",
  xl: "50px",
  "2xl": "50px",
};

// Section-2 Text Size
const textSize2 = {
  base: "20px",
  sm: "20px",
  md: "16px",
  lg: "18px",
  xl: "24px",
  "2xl": "26px",
};
const btntextSize2 = {
  base: "16px",
  sm: "16px",
  md: "14px",
  lg: "16px",
  xl: "18px",
  "2xl": "20px",
};

// Section-3 Text Size
const headingtextSize3 = {
  base: "36px",
  sm: "36px",
  md: "30px",
  lg: "36px",
  xl: "40px",
  "2xl": "40px",
};
const textSize3 = {
  base: "20px",
  sm: "22px",
  md: "20px",
  lg: "24px",
  xl: "26px",
  "2xl": "26px",
};
const aboutSectiontextSize3 = {
  base: "18px",
  sm: "18px",
  md: "20px",
  lg: "24px",
  xl: "26px",
  "2xl": "26px",
};

const AboutSectionContent = [
  {
    image: AboutUs1,
    title: "50k+ Consultations",
  },
  {
    image: AboutUs2,
    title: "100k+ Personalized Written Notes delivered",
  },
  {
    image: AboutUs3,
    title: "150k+ Lives transformed",
  },
  {
    image: AboutUs4,
    title: "Clients from 20+ countries",
  },
];

// Section-4 Text Size
const headingtextSize4 = {
  base: "36px",
  sm: "36px",
  md: "30px",
  lg: "36px",
  xl: "40px",
  "2xl": "40px",
};
const aboutSectiontextSize4 = {
  base: "18px",
  sm: "18px",
  md: "18px",
  lg: "20px",
  xl: "20px",
  "2xl": "20px",
};

const Home = () => {
  const { navigateToSection } = useNavigateWithSection();

  const maskSection1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  const maskSection3 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      {/* --------------------- Section - 1 Starts --------------------- */}
      <VStack
        width={"full"}
        className={maskSection1}
        height={{
          base: "80vh",
          sm: "80vh",
          md: "40vh",
          lg: "calc(100vh - 90px)",
          xl: "calc(100vh - 90px)",
          "2xl": "calc(100vh - 90px)",
        }}
        background={`url(${HomeBackground})`}
        bgRepeat="no-repeat"
        backgroundPosition={{
          base: "right",
          sm: "right",
          md: "center",
          lg: "center",
          xl: "center",
          "2xl": "center",
        }}
        backgroundSize={"cover"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <VStack display={{ base: "none", md: "flex" }} gap={2}>
          <Text
            fontFamily={"Fondamento"}
            color={"#68030D"}
            stroke={"#68030D"}
            fontWeight={500}
            textTransform={"capitalize"}
            fontSize={textSize}
          >
            Transform your Life
          </Text>
          <Text
            fontFamily={"Fondamento"}
            color={"#68030D"}
            stroke={"#68030D"}
            fontWeight={500}
            textTransform={"capitalize"}
            fontSize={textSize}
          >
            Here & Now with
          </Text>
          <Text
            fontFamily={"Fondamento"}
            color={"#68030D"}
            stroke={"#68030D"}
            fontWeight={1000}
            textTransform={"capitalize"}
            fontSize={textSize}
          >
            A Chosen Son
          </Text>
        </VStack>
      </VStack>
      <VStack display={{ base: "flex", md: "none" }} p={"10"} bg={"#FDE9CF"}>
        <Text
          fontFamily={"Fondamento"}
          color={"#68030D"}
          stroke={"#68030D"}
          fontWeight={500}
          textTransform={"capitalize"}
          fontSize={textSize}
        >
          Transform your Life
        </Text>
        <Text
          fontFamily={"Fondamento"}
          color={"#68030D"}
          stroke={"#68030D"}
          fontWeight={500}
          textTransform={"capitalize"}
          fontSize={textSize}
        >
          Here & Now with
        </Text>
        <Text
          fontFamily={"Fondamento"}
          color={"#68030D"}
          stroke={"#68030D"}
          fontWeight={1000}
          textTransform={"capitalize"}
          fontSize={textSize}
        >
          A Chosen Son
        </Text>
      </VStack>
      {/* --------------------- Section - 1 Ends --------------------- */}
      {/* --------------------- Section - 2 Starts --------------------- */}
      <Flex
        p={{ base: "5", md: "10" }}
        px={{ base: "5", md: "20" }}
        flexDir={{ base: "column", md: "row" }}
        w={"full"}
        gap={{ base: "10", md: "20" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Image
          src={Section2Image}
          w={{
            base: "250px",
            sm: "250px",
            md: "250px",
            lg: "350px",
            xl: "auto",
          }}
        />
        <VStack
          alignItems={"center"}
          justifyContent={"center"}
          gap={{ base: "5", md: "10" }}
        >
          <Text
            color={"#68030D"}
            fontSize={textSize2}
            fontWeight={400}
            textAlign={{ base: "justify", md: "center" }}
          >
            Jay might not be the first astrologer you are consulting, but he is
            certainly the last one. His astrological insights and solutions are
            based on logic, magic and solutions which are easily doable in
            Kaliyuga. Be it career, love, or life, Jay helps you understand
            yourself through astrology like no other.
          </Text>
          <Button
            title={"Consult Now!"}
            boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            borderRadius={"8px"}
            padding={"13px 20px"}
            fontSize={btntextSize2}
            fontWeight={500}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
      </Flex>
      {/* --------------------- Section - 2 Ends --------------------- */}
      {/* --------------------- Section - 3 Starts --------------------- */}
      <VStack
        width={"full"}
        className={maskSection3}
        height={{
          base: "650px",
          sm: "650px",
          md: "650px",
          lg: "90vh",
          xl: "90vh",
          "2xl": "90vh",
        }}
        background={{
          base: `url(${MobileBackground01})`,
          md: `url(${HomeSection3Bg})`,
        }}
        aspectRatio={4 / 3}
        bgRepeat="no-repeat"
        backgroundPosition={{
          base: "center bottom",
          sm: "top right",
          md: "top",
          lg: "top",
          xl: "top",
          "2xl": "top",
        }}
        backgroundSize={{ base: "contain", md: "cover" }}
        justifyContent={"start"}
        alignItems={"start"}
        py={{ base: "5", sm: "5", md: "10", lg: "20", xl: "20", "2xl": "20" }}
        px={"20"}
        gap={20}
      >
        <VStack
          h={"full"}
          display={{ base: "none", md: "flex" }}
          justifyContent={"space-evenly"}
          alignItems={"start"}
        >
          <VStack gap={2} alignItems={"start"}>
            <Text
              fontFamily={"Fondamento"}
              color={"#68030D"}
              stroke={"#68030D"}
              fontWeight={1000}
              textTransform={"capitalize"}
              fontSize={headingtextSize3}
            >
              About Us
            </Text>
            <Text
              fontFamily={"Jost"}
              color={"#68030D"}
              stroke={"#68030D"}
              fontWeight={400}
              fontSize={textSize3}
              maxW={{
                base: "50%",
                sm: "50%",
                md: "50%",
                lg: "60%",
                xl: "70%",
                "2xl": "70%",
              }}
            >
              Jay and Team live and breathe Astrology. We are not here to scare
              you.
            </Text>
            <Text
              fontFamily={"Jost"}
              color={"#68030D"}
              stroke={"#68030D"}
              fontWeight={400}
              fontSize={textSize3}
              maxW={{
                base: "50%",
                sm: "50%",
                md: "50%",
                lg: "60%",
                xl: "70%",
                "2xl": "70%",
              }}
            >
              We are here to provide you clear and precise answers and solutions
              which can help you change your life.
            </Text>
          </VStack>
          <SimpleGrid
            alignSelf={"end"}
            columns={{ base: 2, sm: 4, md: 4, lg: 6 }}
            gap={{ base: "6", md: "15" }}
          >
            {AboutSectionContent?.map((ele) => (
              <VStack gap={1} justifyContent={"start"}>
                <Image src={ele.image} />
                <Text
                  color="#68030D"
                  textAlign="center"
                  fontFamily="Jost"
                  fontSize={aboutSectiontextSize3}
                  fontWeight={500}
                >
                  {ele?.title}
                </Text>
              </VStack>
            ))}
          </SimpleGrid>
        </VStack>
      </VStack>
      <VStack
        display={{ base: "flex", md: "none" }}
        gap={"10"}
        p={"10"}
        bg={"#FDE9CF"}
      >
        <VStack gap={2} alignItems={"center"} justifyContent={"center"}>
          <Text
            fontFamily={"Fondamento"}
            color={"#68030D"}
            stroke={"#68030D"}
            fontWeight={1000}
            textTransform={"capitalize"}
            fontSize={headingtextSize3}
          >
            About Us
          </Text>
          <Text
            fontFamily={"Jost"}
            color={"#68030D"}
            stroke={"#68030D"}
            fontWeight={500}
            fontSize={textSize3}
            textAlign={"justify"}
          >
            Jay and Team live and breathe Astrology. We are not here to scare
            you. We are here to provide you clear and precise answers and
            solutions which can help you change your life.
          </Text>
        </VStack>
        <SimpleGrid
          alignSelf={"end"}
          columns={{ base: 2, sm: 4, md: 4, lg: 6 }}
          gap={{ base: "6", md: "15" }}
        >
          {AboutSectionContent?.map((ele) => (
            <VStack gap={1} justifyContent={"start"}>
              <Image src={ele.image} />
              <Text
                color="#68030D"
                textAlign="center"
                fontFamily="Jost"
                fontSize={aboutSectiontextSize3}
                fontWeight={500}
              >
                {ele?.title}
              </Text>
            </VStack>
          ))}
        </SimpleGrid>
      </VStack>
      {/* --------------------- Section - 3 Ends --------------------- */}
      {/* --------------------- Section - 4 Starts --------------------- */}
      <VStack gap={1} paddingY={{ base: "4", md: "10" }}>
        <Text
          fontFamily={"Fondamento"}
          color={"#68030D"}
          stroke={"#68030D"}
          fontWeight={1000}
          textTransform={"capitalize"}
          fontSize={headingtextSize4}
        >
          Our Services
        </Text>
        <Text
          color={"#68030D"}
          stroke={"#68030D"}
          fontWeight={400}
          fontSize={aboutSectiontextSize4}
        >
          Unlock your true potential with us.
        </Text>
      </VStack>
      <CommonServices />
      {/* --------------------- Section - 4 Ends --------------------- */}
      <Box height="20vh" display={{ base: "flex", md: "none" }} />
      <ConsultNow />
      <Box height="20vh" display={{ base: "flex", md: "none" }} />
      <Testimonials screen={"home"} />
      <Footer viewRatings={true} />
    </>
  );
};

export default Home;
