import { Link, Text, Icon, HStack } from "@chakra-ui/react";
import { FaGlobe } from "react-icons/fa";  // Example icon

export const DeveloperInfo = () => {
    return (
      <HStack>
        <Icon
          as={FaGlobe}
          color={{ base: "var(--astrology-nav)", md: "var(--atlantic-900)" }}
        />
        <Text
          variant={{ base: "15px", md: "lgFont" }}
          color={"var(--astrology-nav)"}
          fontWeight={500}
          textTransform={"uppercase"}
          letterSpacing={{ base: "1px", md: "1.28px" }}
          display={{ base: "flex", md: "block", lg: "flex" }}
          alignItems="center"
        >
          Developed by:
          <Link
            href="https://www.codexcomms.com/"
            isExternal
            color="var(--astrology-nav)"
            fontWeight="500"
            _hover={{ textDecoration: "underline" }}
            textTransform={"none"}
            aria-label="Visit CodexComms website"
            display="flex"
            alignItems="center"
            paddingX={2}
          >
            CodeX Comms Systems
          </Link>
        </Text>
      </HStack>
    );
};
