import {
  Image,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Satisfied from "../../images/50k satisfied.PNG";
import Natural from "../../images/100 natural.PNG";
import Ethinical from "../../images/Ethically sourced.PNG";
// import FreeShipping from "../../images/Free shipping.PNG";
import HandPicked from "../../images/Hand picked.PNG";
import Background1 from "../../images/Service1Background.compressed.svg";

const Assurity = () => {
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });

  return (
    <SimpleGrid
      className={c2}
      bgColor={{ base: "#FDE9CF", md: "transparent" }}
      bgImage={{ base: "none", md: `url('${Background1}')` }}
      columns={{ base: 1, md: 2, lg: 4 }}
      bgRepeat="no-repeat"
      backgroundSize={"cover"}
      gap={{ base: "0px", md: "48px" }}
      alignItems="center"
      justifyContent="center"
      py={{ base: "10px", md: "50px", lg: "20px" }}
      px={{ base: "16px", md: "0" }}
      textAlign="center"
    >
      <VStack gap={0} justifyContent="start" alignItems="center">
        <Image src={Natural} />
        <Text
          color="var(--3, #68030D)"
          textAlign="center"
          fontFamily="Jost"
          fontSize={{ base: "16px", md: "20px" }}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="146.6%" /* 29.32px */
          marginTop="-30px"
        >
          Natural and Certified
        </Text>
      </VStack>
      <VStack gap={0} justifyContent="start" alignItems="center">
        <Image src={HandPicked} />
        <Text
          color="var(--3, #68030D)"
          textAlign="center"
          fontFamily="Jost"
          fontSize={{ base: "16px", md: "20px" }}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="146.6%" /* 29.32px */
          marginTop="-30px"
        >
          Hand Picked
        </Text>
      </VStack>
      <VStack gap={0} justifyContent="start" alignItems="center">
        <Image src={Satisfied} />
        <Text
          color="var(--3, #68030D)"
          textAlign="center"
          fontFamily="Jost"
          fontSize={{ base: "16px", md: "20px" }}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="146.6%" /* 29.32px */
          marginTop="-30px"
        >
          50K+ Satisfied
        </Text>
      </VStack>
      {/* <VStack gap={0} justifyContent="start" alignItems="center">
        <Image src={FreeShipping} />
        <Text
          color="var(--3, #68030D)"
          textAlign="center"
          fontFamily="Jost"
          fontSize={{ base: "16px", md: "20px" }}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="146.6%" 
          marginTop="-30px"
        >
          Free Shipping
        </Text>
      </VStack> */}
      <VStack gap={0} justifyContent="start" alignItems="center">
        <Image src={Ethinical} />
        <Text
          color="var(--3, #68030D)"
          textAlign="center"
          fontFamily="Jost"
          fontSize={{ base: "16px", md: "20px" }}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="146.6%" /* 29.32px */
          marginTop="-30px"
        >
          Ethically sourced
        </Text>
      </VStack>
    </SimpleGrid>
  );
};

export default Assurity;
