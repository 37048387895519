import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
const TextBox = ({
  validationErrors,
  innerref,
  hideLabel,
  widthFull,
  showPlaceHolder,
  leftAddOn,
  leftAddOnStyles,
  rightAddOn,
  rightAddOnStyles,
  fontSize = "14px",
  ...props
}) => {
  let keys = innerref && innerref.name && innerref.name.split(".");
  const validation =
    validationErrors &&
    (keys.length > 1
      ? validationErrors?.[keys[0]]?.[keys[1]]
      : validationErrors?.[keys[0]]);

  let textBoxId = props?.id
    ? props.id
    : props?.name && typeof props?.name === "string"
    ? props?.name
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()
        .replace(/[^a-zA-Z- ]/g, "")
    : props?.placeholder && typeof props?.placeholder === "string"
    ? props?.placeholder
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()
        .replace(/[^a-zA-Z- ]/g, "")
    : "";

  return (
    <React.Fragment>
      <FormControl
        name={props.name || props.placeholder}
        isInvalid={validation}
        isRequired={props.required}
        // mt={"20px !important"}
      >
        {!hideLabel ? (
          <HStack mb={"4px"}>
            <FormLabel
              fontSize={"18px"}
              color={"#030505"}
              fontWeight={400}
              w={"100%"}
              textTransform={"capitalize"}
              as="legend"
              mr="2px"
              mb={"6px"}
            >
              {props.name || props.placeholder}
            </FormLabel>
          </HStack>
        ) : null}
        <InputGroup borderRadius={"4px"}>
          {leftAddOn && (
            <InputLeftElement
              pointerEvents="none"
              rounded="4px"
              padding={"20px"}
              style={{ ...leftAddOnStyles }}
            >
              {leftAddOn}
            </InputLeftElement>
          )}
          <Input
            paddingLeft={
              props.leftAddPadding
                ? props.leftAddPadding
                : leftAddOnStyles
                ? "28px"
                : leftAddOn
                ? "45px"
                : ""
            }
            id={textBoxId}
            autoFocus={props.autoFocus}
            paddingRight={
              props.rightAddPadding
                ? props.rightAddPadding
                : rightAddOnStyles
                ? "28px"
                : rightAddOn
                ? "40px"
                : ""
            }
            borderColor={"var(--atlantic-100)"}
            {...props}
            {...innerref}
            errorBorderColor={"#F8B4B4"}
            boxShadow={"none !important"}
            fontSize={fontSize}
            width={widthFull ? widthFull : "full"}
            height={props.textBoxheight ? props.textBoxheight : "36px"}
            placeholder={showPlaceHolder ? props.placeholder : ""}
            _placeholder={{ color: "var(--atlantic-200)" }}
            _disabled={{
              bg: "var(--atlantic-50)",
              color: "var(--atlantic-300)",
            }}
          />
          {rightAddOn && (
            <InputRightElement
              cursor={"pointer"}
              mr={1}
              rounded="4px"
              padding={"20px"}
              style={{ ...rightAddOnStyles }}
            >
              {rightAddOn}
            </InputRightElement>
          )}

          {/* ==== removed warning icon throughout application ===  */}
          {/* {validation && (
            <InputRightElement>
              <Image src={warningIcon} />
            </InputRightElement>
          )} */}
        </InputGroup>
        {validation ? (
          <Errors errors={validation} />
        ) : props.hideHelperText ? (
          ""
        ) : (
          <FormHelperText>&nbsp;</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

const Errors = ({ errors }) => {
  if (errors.types && Object.entries(errors.types).length > 0) {
    return Object.entries(errors.types).map(([type, message]) => (
      <FormErrorMessage key={type}>{message}</FormErrorMessage>
    ));
  } else {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
};

export default TextBox;
