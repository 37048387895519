import React from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import Background1 from "../../images/Service1Background.compressed.svg";
import GemsImageSlider from "../../components/GemsImageSlider";

const Hessonite = () => {
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      {/* web view 2nd section */}
      <VStack
        w={"full"}
        h={{ base: "full" }}
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        display={{ base: "none", md: "flex" }}
        mb={"50px"}
      >
        <HStack>
          <Flex
            alignItems={"start"}
            justifyContent={"start"}
            gap={8}
            ml={"50px"}
            flexDir={"column"}
            maxW={"47%"}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              textTransform={"uppercase"}
              fontWeight={900}
              lineHeight={"40px"}
              whiteSpace={"nowrap"}
            >
              Hessonite ( गोमेद )
            </Text>
            <Flex gap={"5"} flexDir={"column"}>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                Who should wear Hessonite ?
              </Text>
              <OrderedList
                spacing={3}
                pl={4}
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color="var(--astrology-nav)"
              >
                <ListItem>
                  Helps you see through the illusions of Rahu. In Vedic
                  astrology, Hessonite is believed to be a powerful and
                  protective stone that can bring about feelings of clarity and
                  discernment.
                </ListItem>
                <ListItem>
                  It is also thought to be helpful for grounding and balancing
                  the energies of the body and mind.
                </ListItem>
                <ListItem>
                  It also helps with the sleep disorders and uncertainties of
                  Rahu.
                </ListItem>
                <ListItem>
                  It brings the calmness of meditation (Dhyana), the joy of
                  prosperity (Artha), knowledge of living (Dharma), the
                  pleasures of the body (Kama), and the salvation (Moksha).
                </ListItem>
                <ListItem>
                  To be worn in <Text display="inline"> Middle finger</Text>.
                </ListItem>
              </OrderedList>
            </Flex>

            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Buy Now!!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://wa.me/+916355230818", "_blank");
              }}
            >
              Buy Now!!
            </Button>
          </Flex>
          <Flex
            w={{ base: "full", md: "50vw", lg: "50vw" }}
            h={{ base: "300px", md: "500px", lg: "500px" }}
          >
            <GemsImageSlider type="hessonite" />
          </Flex>
        </HStack>
      </VStack>

      {/* mobile view 2nd section */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"100px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"400px"}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          aspectRatio={3 / 4}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <GemsImageSlider type="hessonite" />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            textTransform={"uppercase"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            Hessonite ( गोमेद )
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Who should wear Hessonite ?
          </Text>
          <OrderedList
            spacing={3}
            pl={4}
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            lineHeight={"30px"}
            color="var(--astrology-nav)"
          >
            <ListItem>
              Helps you see through the illusions of Rahu. In Vedic astrology,
              Hessonite is believed to be a powerful and protective stone that
              can bring about feelings of clarity and discernment.
            </ListItem>
            <ListItem>
              It is also thought to be helpful for grounding and balancing the
              energies of the body and mind.
            </ListItem>
            <ListItem>
              It also helps with the sleep disorders and uncertainties of Rahu.
            </ListItem>
            <ListItem>
              It brings the calmness of meditation (Dhyana), the joy of
              prosperity (Artha), knowledge of living (Dharma), the pleasures of
              the body (Kama), and the salvation (Moksha).
            </ListItem>
            <ListItem>
              To be worn in <Text display="inline"> Middle finger</Text>.
            </ListItem>
          </OrderedList>

          <Button
            variant={"trPrimary"}
            title={"Buy Now!!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/+916355230818", "_blank");
            }}
          >
            Buy Now!!
          </Button>
        </Flex>
      </VStack>
    </>
  );
};

export default Hessonite;
