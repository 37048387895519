import { Button } from "@astrology/shared-components";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import FooterBg from "../../images/footerBg.compressed.svg";
import CopyRight from "./CopyRight";
import { DeveloperInfo } from "./DeveloperInfo";
import { RiContractFill } from "react-icons/ri";
import TermsAndCondition from "./TermsAndCondition";

let routes = [
  {
    path: "/home",
    name: "Home",
  },
  {
    path: "/services/consultation",
    name: "Services",
  },
  // {
  //   path: "/faqs",
  //   name: "FAQS",
  // },
  {
    path: "/contact",
    name: "Contact Team",
  },
];

const activeStyle = {
  "&:hover": { textDecoration: "underline" },
};

const NavigationDisplay = ({ ele, mobileView }) => {
  return (
    <Box
      align={"center"}
      p={"8px"}
      role="group"
      cursor="pointer"
      width={"inherit"}
      color="var(--astrology-nav)"
      _hover={activeStyle}
    >
      <Link
        as={RouterLink}
        to={ele.path}
        style={{
          textDecoration: "none",
          fontSize: "16px",
          lineHeight: "28px",
          fontWeight: 500,
          textTransform: "uppercase",
          opacity: mobileView ? 0.6 : 1,
        }}
        _focus={{ boxShadow: "none" }}
        width={"100%"}
      >
        {ele.name}
      </Link>
    </Box>
  );
};

const Footer = ({ viewRatings }) => {
  let footerData = [
    "Genuine Reading",
    "Practical Solutions",
    "Privacy",
    "Satisfaction ",
  ];
  const maskClass = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "footer-mask", // For medium screens
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleCall = (number) => {
    window.location.href = `tel:${number}`;
  };

  return (
    <>
      {viewRatings && (
        <Box height={"auto"} marginTop={{ base: 6, md: 2, xl: 10 }}>
          <HStack
            height={"20vh"}
            width={"fit-content"}
            margin={"auto"}
            gap={12}
            display={{ base: "none", md: "flex" }}
          >
            {footerData?.map((ele, index) => {
              return (
                <VStack key={`${ele}-${index}`}>
                  <Text
                    variant={"xlgFont"}
                    fontSize={"40px"}
                    color="var(--astrology-nav)"
                    fontFamily={"Fondamento, sans-serif"}
                  >
                    100%
                  </Text>
                  <Text variant={"lgFont"} color="var(--astrology-nav)">
                    {ele}
                  </Text>
                </VStack>
              );
            })}
          </HStack>
          <SimpleGrid
            columns={2}
            spacing={8}
            mx={4}
            display={{ base: "grid", md: "none" }}
          >
            {footerData?.map((ele, index) => {
              return (
                <VStack
                  bg="#FDE9CF"
                  borderRadius={8}
                  p={8}
                  key={`${ele}-${index}`}
                >
                  <Text
                    variant={"xlgFont"}
                    fontSize={"30px"}
                    color="var(--astrology-nav)"
                    fontFamily={"Fondamento, sans-serif"}
                  >
                    100%
                  </Text>
                  <Text variant={"mdFont"} color="var(--astrology-nav)">
                    {ele}
                  </Text>
                </VStack>
              );
            })}
          </SimpleGrid>
        </Box>
      )}
      <Flex
        mt={5}
        background={{
          base: `url('${FooterBg}') lightgray -235.63px 0px / 104.81% 298.688% no-repeat`,
          md: `url('${FooterBg}') lightgray 50% / cover no-repeat`,
        }}
        pt={"60px"}
        className={maskClass}
        w={"full"}
        minH={"190px"}
        h={"max-content"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDir={"column"}
        gap={3}
      >
        {/* mobile view */}
        <Flex
          px={{ base: 4, md: 4 }}
          mt={4}
          w={"full"}
          flexDir={"column"}
          alignItems={"start"}
          justifyContent={{ base: "space-between", md: "space-between" }}
          display={{ base: "flex", md: "none" }}
        >
          <Text variant={"lgFont"} pl={2} color="var(--astrology-nav)">
            QUICK LINKS
          </Text>
          <VStack gap={1} alignItems={"start"}>
            {routes?.map((ele, index) => {
              return (
                <NavigationDisplay
                  ele={ele}
                  mobileView={true}
                  key={`${ele.name}-${index}`}
                />
              );
            })}
          </VStack>
          <VStack alignItems={"start"} justifyContent={"start"}>
            <Button
              leftIcon={<RiContractFill />}
              variant="trNoOutline"
              title={"View Terms & Conditions"}
              background={"transparent"}
              onClick={onOpen}
              textTransform={"auto"}
            />

            <Button
              leftIcon={<EmailIcon />}
              variant="trNoOutline"
              title={"Email: support@achosenson.com"}
              background={"transparent"}
              onClick={() => {
                window.location.href = "mailto:support@achosenson.com";
              }}
              textTransform={"auto"}
            />
            <HStack px={0} gap={0}>
              <Button
                leftIcon={<PhoneIcon />}
                variant="trNoOutline"
                title={"Call us:"}
                background="transparent"
                pointerEvents="none"
                _hover={{ background: "none" }}
                px={0}
              />
              <Button
                variant="trNoOutline"
                background="transparent"
                onClick={() => handleCall("+919601465169")}
                title={"+919601465169"}
                px={1}
              />
              <Text px={0}>,</Text>
              <Button
                variant="trNoOutline"
                background="transparent"
                onClick={() => handleCall("+916355230818")}
                title={"+916355230818"}
                px={1}
              />
            </HStack>

            {/* <Button
              leftIcon={<PhoneIcon />}
              variant="trNoOutline"
              title={"Call us: +919601465169"}
              background={"transparent"}
              onClick={() => handleCall("+919601465169")}
            />
            <Button
              leftIcon={<PhoneIcon />}
              variant="trNoOutline"
              title={"Call us: +916355230818"}
              background={"transparent"}
              onClick={() => handleCall("+916355230818")}
            /> */}
          </VStack>
        </Flex>
        {/* mobile view end */}

        {/* web view */}
        <Flex
          px={{ base: 4, md: 4 }}
          mt={2}
          w={"full"}
          alignItems={"center"}
          justifyContent={{ base: "end", md: "space-between" }}
          display={{ base: "none", md: "flex" }}
        >
          {/* <DummyLogoIcon w={"190px"} h={"60px"} /> */}
          <HStack>
            <Button
              leftIcon={<RiContractFill />}
              variant="trNoOutline"
              title={"View Terms & Conditions"}
              background={"transparent"}
              onClick={onOpen}
              textTransform={"auto"}
            />
            <Button
              leftIcon={<EmailIcon />}
              variant="trNoOutline"
              title={"Email: support@achosenson.com"}
              background={"transparent"}
              onClick={() => {
                window.location.href = "mailto:support@achosenson.com";
              }}
              textTransform={"auto"}
            />
            <HStack px={0} gap={0}>
              <Button
                leftIcon={<PhoneIcon />}
                variant="trNoOutline"
                title={"Call us:"}
                background="transparent"
                textTransform="auto"
                pointerEvents="none" // Disables hover and click actions
                _hover={{ background: "none" }} // Ensures no hover styles
                px={0}
              />
              <Button
                variant="trNoOutline"
                background="transparent"
                onClick={() => handleCall("+919601465169")}
                title={"+919601465169"}
                px={1}
              />
              <Text px={0}>,</Text>
              <Button
                variant="trNoOutline"
                background="transparent"
                onClick={() => handleCall("+916355230818")}
                title={"+916355230818"}
                px={1}
              />
            </HStack>
          </HStack>
          <HStack display={{ base: "none", md: "flex" }} gap={6} mr={2}>
            {routes?.map((ele, index) => {
              return (
                <NavigationDisplay ele={ele} key={`${ele.name}-${index}`} />
              );
            })}
          </HStack>
        </Flex>
        {/* web view end */}

        <Divider borderColor={"rgba(89, 54, 0, 0.15)"} />
        <Flex
          flexDir={{ base: "column", md: "row" }}
          width={"full"}
          textAlign={"center"}
          paddingX={{ base: 2, md: 8 }}
          justifyContent="space-evenly"
          alignItems="center"
          gap={2}
        >
          <CopyRight />
          <DeveloperInfo />
        </Flex>
      </Flex>
      <TermsAndCondition isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Footer;
