import { Button } from "@astrology/shared-components";
import { Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React from "react";
import C1 from "../../images/Contact1.png";
import C2 from "../../images/Contact2.png";
import C3 from "../../images/Contact3.png";
import C4 from "../../images/Contact4.png";
import useNavigateWithSection from "../../services/navigateWithSection";

const OurServices = ({ home }) => {
  const { navigateToSection } = useNavigateWithSection();
  return (
    <>
      <VStack gap={2} display={home ? {} : { base: "flex", md: "none" }}>
        <Text
          color={"#68030D"}
          textAlign={"center"}
          fontFamily={"Fondamento, sans-serif"}
          fontSize={"30px"}
          fontWeight={600}
          lineHeight={"30px"}
          letterSpacing={"1.5px"}
          textTransform={"capitalize"}
        >
          Our Services
        </Text>
        <Text
          color={"#68030D"}
          textAlign={"center"}
          fontSize={"15px"}
          fontWeight={400}
          lineHeight={"22px"}
          letterSpacing={"1.5px"}
        >
          Unlock your true potential with us.
        </Text>
      </VStack>
      <SimpleGrid
        columns={{ sm: 1, md: 4 }}
        gap={4}
        marginTop={"50px"}
        mx={{ base: "4", md: "20" }}
        spacing={"4"}
      >
        <VStack gap={2} justifyContent={"space-between"} height={"full"} mx={5}>
          <>
            <Image
              src={C1}
              width={{ base: "200px", md: "200px" }}
              height={{ base: "200px", md: "200px" }}
            />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={600}
              textTransform={"capitalize"}
              textAlign={{ base: "center" }}
            >
              Consultation with Jay
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={{ base: "justify", md: "center" }}
            >
              Talk to Jay and get answers to all your questions about your
              career, relationship, business and beyond.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consult Now!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                navigateToSection(
                  "/services/consultation",
                  "consultation-booking"
                );
              }}
            />
          </>
        </VStack>
        <VStack gap={2} justifyContent={"space-between"} height={"full"} mx={5}>
          <>
            <Image
              src={C2}
              width={{ base: "200px", md: "200px" }}
              height={{ base: "200px", md: "200px" }}
            />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={600}
              textTransform={"capitalize"}
            >
              Written Notes
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={{ base: "justify", md: "center" }}
            >
              Ask any life question and get in-depth, personalized solutions in
              an easy-to-understand PDF report by our team of experts in 72 hours.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Book Now!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                navigateToSection("/services/notes", "notes-booking");
              }}
            />
          </>
        </VStack>
        <VStack gap={2} justifyContent={"space-between"} height={"full"} mx={5}>
          <>
            <Image
              src={C3}
              width={{ base: "200px", md: "200px" }}
              height={{ base: "200px", md: "200px" }}
            />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={600}
              textTransform={"capitalize"}
            >
              Report
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={{ base: "justify", md: "center" }}
            >
              Get your detailed Kundli analysis including all the charts and
              predictions on all areas of your life in 48 hours.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Order Now!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                navigateToSection("/services/reports", "");
              }}
            />
          </>
        </VStack>
        <VStack gap={2} justifyContent={"space-between"} height={"full"} mx={5}>
          <>
            <Image
              src={C4}
              width={{ base: "200px", md: "200px" }}
              height={{ base: "200px", md: "200px" }}
            />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"24px"}
              fontWeight={600}
              textTransform={"capitalize"}
              textAlign={{ base: "center" }}
            >
              Numerology based solutions
            </Text>
          </>
          <>
            <Text
              color={"#68030D"}
              fontSize={"20px"}
              fontWeight={400}
              textAlign={{ base: "justify", md: "center" }}
            >
              Get Name and Business Name correction, House, mobile, and vehicle
              number correction in 48 hours, and change your fortune!
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Contact Now!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                navigateToSection("/services/numerology", "");
              }}
            />
          </>
        </VStack>
      </SimpleGrid>
    </>
  );
};

export default OurServices;
