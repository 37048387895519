import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const variants = defineStyle({
  outline: {
    border: "1px solid",
    borderColor: "brandGray.30",
    borderRadius: "4px",
    bg: "white",
  },
});

export const textAreaTheme = defineStyleConfig({
  variants: variants,
});
