import { AlertDialog, Button } from "@astrology/shared-components";
import {
  Box,
  Flex,
  HStack,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import border from "../../../images/border.png";
import C1 from "../../../images/urgent.png";
import C2 from "../../../images/premium.png";
import Career2 from "../../../images/Career-2.compressed.svg";
import Career3 from "../../../images/Career-3.compressed.svg";
import Career4 from "../../../images/Career-4.compressed.svg";
import Career5 from "../../../images/Career-5.compressed.svg";
import Career6 from "../../../images/Career-6.compressed.svg";
import Career from "../../../images/Career.compressed.svg";
import { ReactComponent as CheckIcon } from "../../../images/checkMark.svg";
import Background4 from "../../../images/fourthSection.compressed.svg";
import S1 from "../../../images/Service-1.compressed.svg";
import S2 from "../../../images/Service-2.compressed.svg";
import S3 from "../../../images/Service-3.compressed.svg";
import S4 from "../../../images/Service-4.compressed.svg";
import Background2 from "../../../images/Service-one-on-one-section-2.png";
import Background1 from "../../../images/Service1Background.compressed.svg";
import S5 from "../../../images/serviceSection.compressed.svg";
import Background3 from "../../../images/thirdSection.compressed.svg";
import C3 from "../../../images/videoCouncelling.compressed.svg";
import useNavigateWithSection from "../../../services/navigateWithSection";
import FAQS from "../../FAQS/FAQS";
import Footer from "../../Footer/Footer";
import Testimonials from "../../Testimonials/Testimonials";
import { sessionPoints } from "./sessionPoints";
import TermsAndConditions from "../../TermsConditions/Terms";
import MobileConsultation01 from "../../../images/mobile/consultation-01.png";
import MobileConsultation02 from "../../../images/mobile/consultation-02.png";

const OneToOneConsultant = () => {
  const termsDialogue = useDisclosure();
  const { navigateToSection } = useNavigateWithSection();
  const navigate = useNavigate();
  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  const c3 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      <Flex
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"full"}
        // h={{ base: "90vh", md: "90vh" }}
        h={"full"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        flexDir={"column"}
        backgroundPosition={"center"}
        pb={{ base: 5, md: "50px" }}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          gap={{ base: "20px", md: "40px" }}
          mt={"70px"}
        >
          <VStack w={"full"} gap={{ base: 5, md: "30px" }}>
            <Text
              fontSize={{ base: "22px", md: "45px" }}
              color={"#68030D"}
              stroke={"#68030D"}
              lineHeight={{ base: "20px", md: "50px" }}
              textAlign={"center"}
              fontFamily={"Fondamento, sans-serif"}
              fontWeight={500}
              textTransform={"capitalize"}
            >
              Get In Touch With
            </Text>
            <Text
              fontSize={{ base: "26px", md: "57px" }}
              color={"#68030D"}
              stroke={"#68030D"}
              lineHeight={{ base: "20px", md: "50px" }}
              textAlign={"center"}
              fontFamily={"Fondamento, sans-serif"}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              Jay Singh Zala
            </Text>
            <Text
              fontSize={{ base: "22px", md: "45px" }}
              color={"#68030D"}
              stroke={"#68030D"}
              lineHeight={{ base: "20px", md: "50px" }}
              textAlign={"center"}
              fontFamily={"Fondamento, sans-serif"}
              fontWeight={500}
              textTransform={"capitalize"}
            >
              For Your Consultation
            </Text>
          </VStack>
          <Text
            fontSize={{ base: "20px", md: "24px" }}
            fontWeight={400}
            color={"#68030D"}
            lineHeight={{ base: "21px", md: "37px" }}
            w={"full"}
            textAlign={"center"}
            mt={"12px"}
          >
            50k + people changed their lives
          </Text>
          <SimpleGrid
            columns={{ base: 2, md: 4 }}
            mx={"auto"}
            gap={{ base: "20px", md: "48px" }}
          >
            <VStack gap={0}>
              <Image
                src={S1}
                width={{ base: "100px", md: "auto" }}
                height={{ base: "100px", md: "auto" }}
              />
              <Text
                fontSize={{ base: "20px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Astrology
              </Text>
            </VStack>
            <VStack gap={0}>
              <Image
                src={S2}
                width={{ base: "100px", md: "auto" }}
                height={{ base: "100px", md: "auto" }}
              />
              <Text
                fontSize={{ base: "20px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Numerology
              </Text>
            </VStack>
            <VStack gap={0}>
              <Image
                src={S3}
                width={{ base: "100px", md: "auto" }}
                height={{ base: "100px", md: "auto" }}
              />
              <Text
                fontSize={{ base: "20px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Transformation
              </Text>
            </VStack>
            <VStack gap={0}>
              <Image
                src={S4}
                width={{ base: "100px", md: "auto" }}
                height={{ base: "100px", md: "auto" }}
              />
              <Text
                fontSize={{ base: "20px", md: "24px" }}
                fontWeight={900}
                color={"#68030D"}
                fontFamily={"Fondamento, sans-serif"}
                textTransform={"capitalize"}
              >
                Remedies
              </Text>
            </VStack>
          </SimpleGrid>
        </Flex>
        <VStack
          alignContent={"center"}
          justifyContent={"end"}
          mt={{ base: "25px", md: "45px" }}
        >
          <Button
            variant={"trPrimary"}
            title={"Consult Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
      </Flex>

      <VStack
        justifyContent={"space-evenly"}
        bgRepeat="no-repeat"
        backgroundSize={"contain"}
        w={"full"}
        mb={8}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
          marginTop={{ base: "40px", md: "100px" }}
        >
          <Text
            fontSize={{ base: "20px", md: "36px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={{ base: "42px", md: "59px" }}
            fontFamily={"Fondamento, sans-serif"}
            textAlign={"center"}
            fontWeight={900}
          >
            What all you will get in your session?
          </Text>
        </Flex>
        <HStack justifyContent={"space-around"} w={"90vw"}>
          <Flex
            flexDir={"column"}
            justifyContent={"space-between"}
            gap={{ base: "25px", md: "50px" }}
          >
            <UnorderedList>
              {sessionPoints?.map((ele) => {
                return (
                  <ListItem color={"#68030D"}>
                    <Text
                      fontSize={{ base: "20px", md: "24px" }}
                      fontWeight={400}
                      lineHeight={{ base: "normal", md: "38px" }}
                      color={"#68030D"}
                      stroke={"#68030D"}
                    >
                      {ele}
                    </Text>
                  </ListItem>
                );
              })}
            </UnorderedList>
            <Button
              variant={"trPrimary"}
              title={"Book Your Consultation Now!"}
              borderRadius={"10px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              height={"36px"}
              width={{ base: "full", md: "250px" }}
              onClick={() => {
                navigateToSection(
                  "/services/consultation",
                  "consultation-booking"
                );
              }}
            />
          </Flex>
          <Image src={Background2} display={{ base: "none", md: "inherit" }} />
        </HStack>
      </VStack>

      <VStack
        bgImage={`url('${Background3}')`}
        className={c2}
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"top"}
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          mr={"50px"}
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"70px"}
          >
            Change your life with a single call
          </Text>
          <Flex gap={"5"} flexDir={"column"}>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              maxW={"90%"}
            >
              Your planetary position has a mission to realign you with your
              life purpose.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              maxW={"75%"}
            >
              Jay being “A Chosen Son” is here to guide you through your
              transformational journey.
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"40px"}
              fontWeight={800}
              lineHeight={"30px"}
              maxW={"75%"}
              my={5}
            >
              सर्वे कर्मवशा वयम् |
            </Text>
            <Text
              fontFamily={"Jost"}
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              maxW={"75%"}
            >
              “Everything is subject to Karma”
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Consult Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </Flex>
      </VStack>

      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
      >
        <Flex
          bgImage={`url('${MobileConsultation01}')`}
          w={"full"}
          h={"500px"}
          backgroundPosition={"center bottom"}
          backgroundSize={"cover"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        ></Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            Change your life with a single call
          </Text>
          {/* <Flex gap={"5"} flexDir={"column"} w={"full"}> */}
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Your planetary position has a mission to realign you with your life
            purpose.
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Jay being “A Chosen Son” is here to guide you through your
            transformational journey.
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"30px"}
            fontWeight={800}
            lineHeight={"20px"}
            textAlign={"center"}
            // my={3}
          >
            सर्वे कर्मवशा वयम् |
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            lineHeight={"20px"}
            textAlign={"center"}
          >
            “Everything is subject to Karma”
          </Text>
          {/* </Flex> */}
          <Button
            variant={"trPrimary"}
            title={"Consult Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </Flex>
      </VStack>

      <VStack gap={"28px"} my={5}>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Career
            </Text>
            <Flex gap={4} flexDir={"column"}>
              <Text
                fontSize={"20px"}
                fontWeight={500}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Find which career is going to get you success.
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Feeling lost about what career to choose? Stuck in your career
                without any growth? Want to know which career will bring the
                most success to your life?
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Talk to Jay and get answers on the best career options according
                to your birth chart and planetary influences. Find a career with
                maximum growth and potential.
              </Text>
            </Flex>
          </Flex>
          <Image
            src={Career}
            width={{ base: "100px", md: "auto" }}
            height={{ base: "100px", md: "auto" }}
          />
        </HStack>
        <VStack gap={"10px"} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento"}
            fontSize={"24px"}
            fontWeight={1000}
            lineHeight={"36px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Career
          </Text>
          <Image src={Career} w={"200px"} height={"200px"} />
          <Flex flexDir={"column"} gap={3} mx={"5"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={700}
              // lineHeight={"22px"}
            >
              Find which career is going to get you success.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Feeling lost about what career to choose? Stuck in your career
              without any growth? Want to know which career will bring the most
              success to your life?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Talk to Jay and get answers on the best career options according
              to your birth chart and planetary influences. Find a career with
              maximum growth and potential.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Career Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Image src={Career2} />
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Love & Relationship
            </Text>
            <Flex gap={4} flexDir={"column"}>
              <Text
                fontSize={"20px"}
                fontWeight={500}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Love is simple, we complicate it. Find out how you can enhance
                your love life?
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Looking for a life partner? Or going through a breakup after a
                long relationship?
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Talk to Jay and get personalized insight into your romantic
                relationships. Find practical solutions to solve your
                relationship issues and foster healthier, and fulfilling
                connections.
              </Text>
            </Flex>
          </Flex>
        </HStack>
        <VStack gap={"23px"} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento"}
            fontSize={"24px"}
            fontWeight={1000}
            lineHeight={"36px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Love & Relationship
          </Text>
          <Image src={Career2} w={"200px"} height={"200px"} />
          <Flex flexDir={"column"} gap={3} mx={"5"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={700}
              // lineHeight={"22px"}
            >
              Love is simple, we complicate it. Find out how you can enhance
              your love life?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Looking for a life partner? Or going through a breakup after a
              long relationship?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Talk to Jay and get personalized insight into your romantic
              relationships. Find practical solutions to solve your relationship
              issues and foster healthier, and fulfilling connections.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Love Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
      </VStack>

      <VStack
        bgImage={`url('${Background1}')`}
        className={c2}
        w={"full"}
        h={"full"}
        // h={{ base: "120vh", md: "inherit" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        backgroundPosition={"top"}
        py={5}
        gap={"28px"}
      >
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Health
            </Text>
            <Flex gap={4} flexDir={"column"}>
              <Text
                fontSize={"20px"}
                fontWeight={500}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Save yourself from health issues before you know it.
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Suffering from health issues or want to prevent health issues in
                future?
              </Text>
              <Text
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color={"#68030D"}
              >
                Talk to Jay and identify potential areas of health concerns.
                Start taking care before you experience any symptoms and resolve
                your health issues by balancing the planetary influences.
              </Text>
            </Flex>
          </Flex>
          <Image src={Career3} />
        </HStack>
        <VStack gap={"10px"} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"24px"}
            fontWeight={1000}
            lineHeight={"36px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            health
          </Text>
          <Image src={Career3} w={"200px"} height={"200px"} />
          <Flex flexDir={"column"} gap={3} mx={"5"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={700}
              // lineHeight={"22px"}
            >
              Save yourself from health issues before you know it.
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Suffering from health issues or want to prevent health issues in
              future?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Talk to Jay and identify potential areas of health concerns. Start
              taking care before you experience any symptoms and resolve your
              health issues by balancing the planetary influences.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Health Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Image src={Career4} />
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Business
            </Text>

            <Text
              fontSize={"20px"}
              fontWeight={500}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Feeling stuck in your business? Want to know if your new business
              will succeed?
            </Text>
            <Text
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Get personalized advice based on your birth chart. Discover your
              strengths, weaknesses, and the best path forward to achieve your
              goals. Align your hard work in the right direction and watch
              yourself grow in business.
            </Text>
          </Flex>
        </HStack>
        <VStack gap={"10px"} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento"}
            fontSize={"24px"}
            fontWeight={1000}
            lineHeight={"36px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Business
          </Text>
          <Image src={Career4} w={"200px"} height={"200px"} />
          <Flex flexDir={"column"} gap={3} mx={"5"}>
            <Text
              color={"#68030D"}
              textAlign={"center"}
              fontSize={"20px"}
              fontWeight={700}
              // lineHeight={"22px"}
            >
              Feeling stuck in your business? Want to know if your new business
              will succeed?
            </Text>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Get personalized advice based on your birth chart. Discover your
              strengths, weaknesses, and the best path forward to achieve your
              goals. Align your hard work in the right direction and watch
              yourself grow in business.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Business Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
      </VStack>

      <VStack gap={"28px"} my={5}>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Marriage
            </Text>

            <Text
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Marriage is a union of two people who have the highest karmic
              debts with each other. It is important to ensure both partners
              have compatibility and they don’t face any issues based on each
              other's charts. This process of kundli matching, analysis of
              marriage problems helps in recognizing your partner and solve
              issues in current marriages.
            </Text>
          </Flex>
          <Image src={Career5} />
        </HStack>
        <VStack gap={"10px"} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento"}
            fontSize={"24px"}
            fontWeight={1000}
            lineHeight={"36px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Marriage
          </Text>
          <Image src={Career5} w={"200px"} height={"200px"} />
          <Flex flexDir={"column"} gap={3} mx={"5"}>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Marriage is a union of two people who have the highest karmic
              debts with each other. It is important to ensure both partners
              have compatibility and they don’t face any issues based on each
              other's charts. This process of kundli matching, analysis of
              marriage problems helps in recognizing your partner and solve
              issues in current marriages.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Marraige Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
        <HStack
          justifyContent={"space-around"}
          mx={"auto"}
          my={8}
          display={{ base: "none", md: "flex" }}
        >
          <Image src={Career6} />
          <Flex gap={4} flexDir={"column"} w={"40%"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              fontWeight={900}
              lineHeight={"70px"}
              textTransform={"capitalize"}
              color={"#68030D"}
            >
              Family consultation
            </Text>

            <Text
              fontSize={"20px"}
              fontWeight={400}
              lineHeight={"30px"}
              color={"#68030D"}
            >
              Talk to Jay and gain insights into your family dynamics through
              astrology. Understand the karmic connections between family
              members and find practical solutions to resolve conflicts. Jay’s
              guidance can help restore balance, foster love, and nurture
              stronger bonds within your family. Discover the areas that need
              attention and unlock the potential for peace and happiness in your
              household.
            </Text>
          </Flex>
        </HStack>
        <VStack gap={"10px"} display={{ base: "flex", md: "none" }}>
          <Text
            fontFamily={"Fondamento"}
            fontSize={"24px"}
            fontWeight={1000}
            lineHeight={"36px"}
            textTransform={"capitalize"}
            color={"#68030D"}
          >
            Family consultation
          </Text>
          <Image src={Career6} w={"200px"} height={"200px"} />
          <Flex flexDir={"column"} gap={3} mx={"5"}>
            <Text
              color={"#68030D"}
              textAlign={"justify"}
              fontSize={"20px"}
              fontWeight={500}
              // lineHeight={"22px"}
            >
              Talk to Jay and gain insights into your family dynamics through
              astrology. Understand the karmic connections between family
              members and find practical solutions to resolve conflicts. Jay’s
              guidance can help restore balance, foster love, and nurture
              stronger bonds within your family. Discover the areas that need
              attention and unlock the potential for peace and happiness in your
              household.
            </Text>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Get Family Guidance"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </VStack>
      </VStack>

      <VStack
        bgImage={`url('${Background4}')`}
        className={c2}
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"top"}
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          ml={"80px"}
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"70px"}
          >
            Benefits of a session with Jay
          </Text>
          <Flex gap={"3"} flexDir={"column"}>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Engage directly with Jay for personalized guidance.
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Receive a comprehensive astrological analysis for your concerns
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Discover practical and tailored solutions to overcome life’s
                challenges
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"40px"}
                maxW={"90%"}
              >
                Harness the power of planetary influences to unlock your full
                potential
              </Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"25px"}
                maxW={"80%"}
              >
                Gain insight into past challenges and discover actionable steps
                for an improved future
              </Text>
            </HStack>
          </Flex>
          <Button
            variant={"trPrimary"}
            title={"Book Your Consultation Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </Flex>
      </VStack>

      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        alignItems={"start"}
        justifyContent={"start"}
        display={{ base: "flex", md: "none" }}
        gap={3}
        paddingBottom={"5"}
      >
        <Flex
          bgImage={`url('${MobileConsultation02}')`}
          w={"full"}
          h={"540px"}
          backgroundPosition={"center bottom"}
          backgroundSize={"contain"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={{ base: "flex", md: "none" }}
          mb={3}
        ></Flex>
        <Text
          w={"full"}
          fontFamily={"Fondamento"}
          fontSize={"22px"}
          textTransform={"capitalize"}
          fontWeight={900}
          lineHeight={"30px"}
          // mx={"20"}
          textAlign={"center"}
        >
          Benefits of a session with Jay
        </Text>
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={5}
          mx={"5"}
          flexDir={"column"}
          p={2}
        >
          <HStack gap={2} h={"full"}>
            <CheckIcon />
            <Text
              fontFamily={"Jost"}
              fontSize={"18px"}
              fontWeight={400}
              // lineHeight={"20px"}
              textAlign={"justify"}
            >
              Engage directly with Jay for personalized guidance.
            </Text>
          </HStack>
          <HStack gap={2} h={"full"}>
            <CheckIcon />
            <Text
              fontFamily={"Jost"}
              fontSize={"18px"}
              fontWeight={400}
              // lineHeight={"20px"}
              textAlign={"justify"}
            >
              Receive a comprehensive astrological analysis for your concerns
            </Text>
          </HStack>
          <HStack gap={2} h={"full"}>
            <CheckIcon />
            <Text
              fontFamily={"Jost"}
              fontSize={"18px"}
              fontWeight={400}
              // lineHeight={"20px"}
              textAlign={"justify"}
            >
              Discover practical and tailored solutions to overcome life’s
              challenges
            </Text>
          </HStack>
          <HStack gap={2} h={"full"}>
            <CheckIcon />
            <Text
              fontFamily={"Jost"}
              fontSize={"18px"}
              fontWeight={400}
              // lineHeight={"20px"}
              textAlign={"justify"}
            >
              Harness the power of planetary influences to unlock your full
              potential
            </Text>
          </HStack>
          <HStack gap={2} h={"full"}>
            <CheckIcon />
            <Text
              fontFamily={"Jost"}
              fontSize={"18px"}
              fontWeight={400}
              // lineHeight={"20px"}
              textAlign={"justify"}
            >
              Gain insight into past challenges and discover actionable steps
              for an improved future
            </Text>
          </HStack>
          <Button
            variant={"trPrimary"}
            title={"Book Your Consultation Now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </Flex>
      </VStack>

      <div id="consultation-booking">
        <VStack my={"10"} gap={"23px"}>
          <Text
            mx={"auto"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "24px", md: "36px" }}
            fontWeight={900}
            lineHeight={{ base: "normal", md: "70px" }}
            textTransform={"capitalize"}
            textAlign={"center"}
          >
            One call can change everything—if it’s with the right person
          </Text>
          <Text
            mx={{ base: "5", md: "auto" }}
            fontSize={{ base: "14px", md: "20px" }}
            fontWeight={400}
            lineHeight={{ base: "20px", md: "28px" }}
            maxW={{ base: "full", md: "75%" }}
            textAlign={{ base: "justify", md: "center" }}
          >
            With Jay, that call unlocks the solutions to your toughest life
            challenges. He listens, reveals the real reasons behind your
            struggles, and provides practical solutions tailored just for you.
            Make the call today and embrace the change and the transformations
            filled with success and harmony. See here{" "}
            <span
              style={{
                cursor: "pointer",
                fontWeight: "600",
                textDecoration: "underline",
              }}
              onClick={() => {
                termsDialogue.onOpen();
              }}
            >
              Terms & Conditions
            </span>
          </Text>
          <Text
            mx={{ base: "5", md: "auto" }}
            fontSize={{ base: "14px", md: "20px" }}
            fontWeight={600}
            lineHeight={{ base: "20px", md: "28px" }}
            maxW={{ base: "full", md: "75%" }}
            textAlign={{ base: "justify", md: "center" }}
          >
            All the regular slots are booked till April and we are not taking
            regular bookings right now.
          </Text>
        </VStack>

        <SimpleGrid
          columns={{ sm: 1, md: 3 }}
          gap={8}
          marginY={"50px"}
          mx={{ base: "auto", md: "150px" }}
          spacing={"4"}
        >
          <VStack gap={2} h={"full"} justifyContent={"space-between"}>
            <>
              <Image src={S5} />
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={{ base: "22px", md: "24px" }}
                fontWeight={900}
                textTransform={"capitalize"}
              >
                10 minutes Audio Regular
              </Text>
            </>
            <VStack gap={0}>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "none", md: "flex" }}
              >
                Ask questions to Jay and get brief and effective solution.
              </Text>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "flex", md: "none" }}
              >
                Ask questions to Jay and
              </Text>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "flex", md: "none" }}
              >
                get brief and effective solution.
              </Text>
              {/* <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
              >
                (₹6,000)
              </Text> */}
              <Button
                isDisabled
                variant={"trPrimary"}
                borderRadius={"8px"}
                padding={"13px 20px"}
                fontSize={"16px"}
                fontWeight={500}
                lineHeight={"22px"}
                title={"Sold out!"}
                boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
                onClick={() => {
                  window.open("https://rzp.io/rzp/unvDNS1", "_blank");
                }}
                mt={3}
              />
            </VStack>
          </VStack>
          <VStack gap={2} h={"full"} justifyContent={"space-between"}>
            <>
              <Image src={S5} />
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={{ base: "22px", md: "24px" }}
                fontWeight={900}
                textTransform={"capitalize"}
              >
                20 minutes Audio Regular
              </Text>
            </>
            <VStack gap={0}>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "none", md: "flex" }}
              >
                Explore 3-4 areas of your life with more detail and guidance.
              </Text>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "flex", md: "none" }}
              >
                Explore 3-4 areas of your life
              </Text>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "flex", md: "none" }}
              >
                with more detail and guidance.
              </Text>
              {/* <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
              >
                (₹10,000)
              </Text> */}
              <Button
                isDisabled
                variant={"trPrimary"}
                borderRadius={"8px"}
                padding={"13px 20px"}
                fontSize={"16px"}
                fontWeight={500}
                lineHeight={"22px"}
                title={"Sold out!"}
                boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
                onClick={() => {
                  window.open("https://rzp.io/rzp/MHwq8Pq", "_blank");
                }}
                mt={3}
              />
            </VStack>
          </VStack>
          <VStack gap={2} h={"full"} justifyContent={"space-between"}>
            <>
              <Image src={S5} />
              <Text
                fontFamily={"Fondamento, sans-serif"}
                fontSize={{ base: "22px", md: "24px" }}
                fontWeight={900}
                textTransform={"capitalize"}
              >
                30 minutes Audio Regular
              </Text>
            </>
            <VStack gap={0}>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "none", md: "flex" }}
              >
                Dive deep into all aspects of your life for thorough insights.
              </Text>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "flex", md: "none" }}
              >
                Dive deep into all aspects of your life
              </Text>
              <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
                display={{ base: "flex", md: "none" }}
              >
                for thorough insights.
              </Text>
              {/* <Text
                color={"#68030D"}
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight={400}
                textAlign={"center"}
              >
                (₹14,000)
              </Text> */}
              <Button
                isDisabled
                variant={"trPrimary"}
                borderRadius={"8px"}
                padding={"13px 20px"}
                fontSize={"16px"}
                fontWeight={500}
                lineHeight={"22px"}
                title={"Sold out!"}
                boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
                onClick={() => {
                  window.open("https://rzp.io/rzp/yEEFd0bi", "_blank");
                }}
                mt={3}
              />
            </VStack>
          </VStack>
        </SimpleGrid>
      </div>

      <Image
        src={border}
        width={"100vw"}
        display={{ base: "none", md: "flex" }}
      />

      <SimpleGrid
        columns={{ sm: 1, md: 3 }}
        gap={8}
        py={{ base: "5", md: "30px" }}
        px={{ base: "auto", md: "150px" }}
        spacing={"4"}
        bgImage={{ base: `url(${Background1})`, md: "" }}
        className={c2}
        bgPosition={"center"}
        pb={8}
      >
        <VStack gap={2} h={"full"} justifyContent={"space-between"}>
          <>
            <Image src={C3} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              10 minutes video Regular
            </Text>
          </>
          <VStack gap={0}>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              Ask questions and see Jay as he provides personalized guidance.
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              Ask questions and see Jay
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              as he provides personalized guidance.
            </Text>
            {/* <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
            >
              (₹8,000)
            </Text> */}
            <Button
              isDisabled
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Sold out!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                window.open("https://rzp.io/rzp/760tkN07", "_blank");
              }}
              mt={3}
            />
          </VStack>
        </VStack>
        <VStack gap={2} h={"full"} justifyContent={"space-between"}>
          <>
            <Image src={C3} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              20 minutes video Regular
            </Text>
          </>
          <VStack gap={0}>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              Discuss 3-4 key areas of your life face-to-face with Jay for
              deeper clarity.
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              Discuss 3-4 key areas of your life face-to-face
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              with Jay for deeper clarity.
            </Text>
            {/* <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
            >
              (₹12,000)
            </Text> */}
            <Button
              isDisabled
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Sold out!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                window.open("https://rzp.io/rzp/gUjq9vs", "_blank");
              }}
              mt={3}
            />
          </VStack>
        </VStack>
        <VStack gap={2} h={"full"} justifyContent={"space-between"}>
          <>
            <Image src={C3} />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              30 minutes video Regular
            </Text>
          </>
          <VStack gap={0}>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              Go in-depth on all aspects of your life face-to-face with Jay.
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              Go in-depth on all aspects of your life
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              face-to-face with Jay.
            </Text>
            {/* <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
            >
              (₹16,000)
            </Text> */}
            <Button
              isDisabled
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Sold out!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={() => {
                window.open("https://rzp.io/rzp/DaJgzy5J", "_blank");
              }}
              mt={3}
            />
          </VStack>
        </VStack>
      </SimpleGrid>

      <Image
        src={border}
        width={"100vw"}
        display={{ base: "none", md: "flex" }}
      />

      <Flex
        alignSelf={"center"}
        w={"full"}
        mx={"auto"}
        justifyContent={"space-evenly"}
        my={"30px"}
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: 5, md: 0 }}
      >
        <VStack gap={2} h={"full"} w={{ base: "full", md: "30%" }}>
          <>
            <Image
              src={C1}
              width={{ base: "200px", md: "150px" }}
              height={{ base: "200px", md: "150px" }}
            />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
            >
              Urgent Slot in Next 3 Months
            </Text>
          </>
          <VStack gap={0}>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              Book a priority slot within 3 months and get answers to all your
              pressing questions.
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              Book a priority slot within 3 months and
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              get answers to all your pressing questions.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consult Now!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://wa.me/+919601465169?text=Urgent",
                  "_blank"
                );
              }}
              mt={3}
            />
          </VStack>
        </VStack>
        <VStack gap={2} h={"full"} w={{ base: "full", md: "30%" }}>
          <>
            <Image
              src={C2}
              width={{ base: "200px", md: "150px" }}
              height={{ base: "200px", md: "150px" }}
            />
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              Premium Urgent Slot in Current Month
            </Text>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              Premium Urgent Slot
            </Text>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "22px", md: "24px" }}
              fontWeight={900}
              textTransform={"capitalize"}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              in Current Month
            </Text>
          </>
          <VStack gap={0}>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "none", md: "flex" }}
            >
              Skip the wait and get immediate guidance when you need it most.
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              Skip the wait and get immediate guidance
            </Text>
            <Text
              color={"#68030D"}
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight={400}
              textAlign={"center"}
              display={{ base: "flex", md: "none" }}
            >
              when you need it most.
            </Text>
            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Consult Now!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://wa.me/+919601465169?text=Premium%20Urgent",
                  "_blank"
                );
              }}
              mt={3}
            />
          </VStack>
        </VStack>
      </Flex>

      <FAQS headText={"FAQS"} type="consultant" />
      <Box height="10vh" display={{ base: "flex", md: "none" }} />
      <Testimonials screen={"consultant"} />
      <Footer viewRatings={true} />

      <TermsAndConditions {...termsDialogue} />
    </>
  );
};

export default OneToOneConsultant;
