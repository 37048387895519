import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
// import { inputAnatomy } from "@chakra-ui/anatomy";
// import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

// const { definePartsStyle, defineMultiStyleConfig } =
//   createMultiStyleConfigHelpers(inputAnatomy.keys);

// const pill = definePartsStyle({
//   field: {
//     border: "1px solid",
//     borderColor: "#C4CCD8",
//     borderRadius: "4px",
//     height: "40px",
//   },
// });

// export const textBoxTheme = defineMultiStyleConfig({
//   variants: { pill },
// });

const variants = defineStyle({
  outline: {
    field: {
      border: "1px solid",
      borderColor: "brandGray.30",
      borderRadius: "4px",
      height: "40px",
      bg: "white",
    },
  },
});

export const textBoxTheme = defineStyleConfig({
  variants: variants,
});
