import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import TestimonialCard from "../features/Testimonials/TestimonialCard";
import { testimonial } from "../features/Testimonials/testimonial";
import "./ResponsiveSlider.css";
import { useMediaQuery } from "@chakra-ui/react";

export const ResponsiveSlider = ({ screen, mob }) => {
  function useResponsiveHeight() {
    const [
      isExtraSmall, // Phones
      isSmall, // Small Phones
      isMedium, // Large Phones/Small Tablets
      isLarge, // Tablets (Portrait)
      isExtraLarge, // Tablets (Landscape)
      isSmallLaptop, // Small Laptops
      isMediumLaptop, // Medium Laptops / Small Desktops
      isLargeDesktop, // Large Desktops
      isExtraLargeDesktop, // Extra Large Desktops (4K Monitors)
    ] = useMediaQuery([
      "(max-width: 321px)", // Extra Small Devices (Phones)
      "(min-width: 321px) and (max-width: 481px)", // Small Devices (Small Phones)
      "(min-width: 481px) and (max-width: 601px)", // Medium Devices (Large Phones / Small Tablets)
      "(min-width: 601px) and (max-width: 769px)", // Large Devices (Tablets in Portrait)
      "(min-width: 769px) and (max-width: 1025px)", // Extra Large Devices (Tablets in Landscape)
      "(min-width: 1025px) and (max-width: 1281px)", // Small Laptops
      "(min-width: 1281px) and (max-width: 1441px)", // Medium Laptops / Small Desktops
      "(min-width: 1441px) and (max-width: 1921px)", // Large Desktops
      "(min-width: 1921px)", // Extra Large Desktops (4K Monitors)
    ]);

    // Initialize a variable for height
    let height;

    // Set the height based on the screen size
    if (isExtraSmall) {
      //card width change
      height = "590px"; // Example height for extra small devices (Phones)
    } else if (isSmall) {
      height = "570px"; // Example height for small phones
    } else if (isMedium) {
      height = "560px"; // Example height for large phones/small tablets
    } else if (isLarge) {
      // change card number to 2
      height = "560px"; // Example height for tablets in portrait
    } else if (isExtraLarge) {
      height = "500px"; // Example height for tablets in landscape
    } else if (isSmallLaptop) {
      //modify card width
      height = "500px"; // Example height for small laptops
    } else if (isMediumLaptop) {
      height = "470px"; // Example height for medium laptops/small desktops
    } else if (isLargeDesktop) {
      height = "470px"; // Example height for large desktops
    } else if (isExtraLargeDesktop) {
      height = "470px"; // Example height for extra large desktops (4K monitors)
    }

    // Return the calculated height
    return height;
  }

  const height = useResponsiveHeight();

  return (
    <Swiper
      style={{ width: "100%", height: "100%" }}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className} custom-bullet"></span>`;
        },
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 10, // Extra Small Devices (Phones)
        },
        360: {
          slidesPerView: 1,
          spaceBetween: 12, // Additional breakpoint for slightly larger phones
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 15, // Small Devices (Small Phones)
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 20, // Medium Devices (Large Phones / Small Tablets)
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10, // Large Devices (Tablets in Portrait)
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 10, // Extra Large Devices (Tablets in Landscape)
        },
        1280: {
          slidesPerView: 2,
          spaceBetween: 20, // Small Laptops
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 10, // Medium Laptops / Small Desktops
        },
        1600: {
          slidesPerView: 3,
          spaceBetween: 15, // Large Desktops
        },
        1920: {
          slidesPerView: 4,
          spaceBetween: 20, // Extra Large Desktops (4K Monitors)
        },
      }}
      modules={[Pagination, Autoplay]}
      className="mySwiper"
    >
      {testimonial[screen]?.map((ele, index) => (
        <SwiperSlide
          key={index}
          style={{
            height: height,
          }}
        >
          <TestimonialCard text={ele?.text} mob={mob} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
