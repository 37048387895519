export const notesFAQ = {
  consultant: [
    {
      ans: `Yes, you can ask multiple questions during your session. However, we recommend preparing a list of your most important questions in advance to make the most out of your consultation time.`,
      ques: `Can I ask multiple questions during the consultation?`,
    },
    {
      ans: `In case of rescheduling and cancellation, please contact us as soon as possible. We require a minimum of 48 hours' notice to avoid a cancellation fee. Rescheduling will be subject to slot availability. If the available slots don't suit you, you can opt to purchase another service, but refunds are not available.`,
      ques: `Can I reschedule or cancel my appointment?`,
    },
    {
      ans: `Consultations are conducted via Google Meet. Upon booking, you'll receive detailed instructions and the meeting link via email.`,
      ques: `How will the consultation be conducted?`,
    },
    {
      ans: `If you miss your scheduled consultation, you will not be able to reschedule or claim a refund. Jay will wait for 5 minutes for you to join. We expect you to respect our time and be punctual.`,
      ques: `What if I miss my scheduled consultation?`,
    },
    {
      ans: `During the consultation, Jay will offer personalized astrological insights for your specific questions and concerns. You can expect a detailed, thoughtful response and remedial measures based on your unique situation.`,
      ques: `What can I expect from the consultation?`,
    },
    {
      ans: `You can record the session from your end. However, we do not provide recordings.`,
      ques: `Can I get a recording of my consultation?`,
    },
    {
      ans: `Yes, you can request an analysis of your partner's or parents' Kundli as part of the consultation.`,
      ques: `Can I ask about my partner’s or parents' Kundli during the consultation?`,
    },
    {
      ans: `We value your feedback! Please feel free to share your experience via email or through our feedback form to help us continue improving our services.`,
      ques: `How can I provide feedback about my consultation?`,
    },
  ],
  notes: [
    {
      ans: `You will receive your personalized written notes in PDF format directly on your WhatsApp number or Email for easy access.`,
      ques: `How do we receive the written notes?`,
    },
    {
      ans: `Yes, these notes are uniquely tailored based on the specific birth details you provide and the questions you ask.`,
      ques: `Are these notes personalized?`,
    },
    {
      ans: `Once your payment is confirmed, you will receive a  Form link to submit all the necessary details and questions for your personalized notes.`,
      ques: `How do I submit my questions?`,
    },
    {
      ans: `Your personalized written note will be delivered directly to your WhatsApp number for instant access.`,
      ques: `Where will I receive my personalized written note?`,
    },
    {
      ans: `No, these written notes are made only for you to help you solve your problem based on your specific questions.`,
      ques: `Is it a software generated generalized Kundli analysis?`,
    },
    {
      ans: `In the form that you will fill up there will be a section for additional information, please enter their details there.`,
      ques: `Where do I enter my partner’s / parents' Kundli details?`,
    },
    {
      ans: `No, the Kundli details are only required to answer your specific questions which involve the other person.`,
      ques: `If I enter 2 Kundli details, do I get 2 readings?`,
    },
    {
      ans: `These readings are done by Jay and his team of astrologers to give you the most accurate answers and solutions to your questions and problems.`,
      ques: `Are these readings done by Jay?`,
    },
    {
      ans: `No. Your questions will be answered in detail with proper explanation.`,
      ques: `Will these be one liner answers?`,
    },
    {
      ans: `Yes, we will give you doable, very practical solutions and easy to follow remedies to solve your problems.`,
      ques: `Will these include remedies?`,
    },
  ],
  gemstones: [
    {
      ques: "Why should I wear a gemstone?",
      ans: "Gemstones are believed to carry energies that can align with and enhance specific aspects of your life, such as health, relationships, career, or personal growth. Wearing the right gemstone can help balance planetary influences and bring positivity and clarity.",
    },
    {
      ques: "How do I know which gemstone is right for me?",
      ans: "The ideal gemstone for you is determined based on your birth chart, numerology, and personal goals. You can opt for our Gemstone Recommendation Report, The Chosen stOne, for a detailed analysis and personalized advice.",
    },
    {
      ques: "Are the gemstones authentic and certified?",
      ans: "Yes, all gemstones we provide are genuine, certified, and ethically sourced. We ensure that each gemstone meets high-quality standards.",
    },
    {
      ques: "Do you offer both precious and semi-precious gemstones?",
      ans: "Yes, we provide a wide range of precious and semi-precious gemstones, including crystals.",
    },
    {
      ques: "Can I buy a gemstone without opting for a report?",
      ans: "Absolutely. If you already know the gemstone you need, you can connect with our team directly to purchase it.",
    },
    {
      ques: "How should I care for my gemstone?",
      ans: "Our team provides detailed guidance on how to wear, cleanse, and maintain your gemstone to preserve its energy and effectiveness.",
    },
    {
      ques: "Do you offer delivery services?",
      ans: "Yes, we deliver gemstones to your doorstep with free and secure shipping.",
    },
    {
      ques: "How do I connect with your team?",
      ans: "You can reach our team at +91 6355230818. We’ll be delighted to assist you with selecting or purchasing your gemstone.",
    },
    {
      ques: "What stone should I wear for a happy life? Which stone can get me a lot of money?",
      ans: "Stone for money and happiness is different for everyone. It is never advisable to wear a gemstone on your own. The best gemstone may vary from person to person. This can, only, be certainly said by our team of expert astrologers in The Chosen stOne Report.",
    },
  ],
};
