import React from "react";
import S1 from "../image/Service-1.png";
import S2 from "../image/Service-2.png";
import S3 from "../image/Service-3.png";
import S4 from "../image/Service-4.png";
import S5 from "../image/Service-5.png";
import { Image, Text, VStack, Wrap, WrapItem, Box } from "@chakra-ui/react";
import { Button } from "@astrology/shared-components";
import useNavigateWithSection from "../services/navigateWithSection";

const textSize = {
  base: "18px",
  sm: "18px",
  md: "20px",
  lg: "24px",
  xl: "26px",
  "2xl": "26px",
};

const btnTextSize = {
  base: "16px",
  sm: "16px",
  md: "14px",
  lg: "16px",
  xl: "18px",
  "2xl": "20px",
};

const ServiceContentArray = [
  {
    image: S1,
    title: "Consultation with Jay",
    content:
      "Talk to Jay and get answers to all your questions about your career, relationship, business and beyond.",
    btnText: "Consult Now!",
    btnFunction: ["/services/consultation", "consultation-booking"],
  },
  {
    image: S2,
    title: "Written Notes",
    content:
      "Ask any life question and get in-depth, personalized solutions in an easy-to-understand PDF report by our team of experts in 72 hours.",
    btnText: "Book Now!",
    btnFunction: ["/services/notes", "notes-booking"],
  },
  {
    image: S3,
    title: "Report",
    content:
      "Get your detailed Kundli analysis including all the charts and predictions on all areas of your life in 48 hours",
    btnText: "Order Now!",
    btnFunction: ["/services/reports", ""],
  },
  {
    image: S4,
    title: "Numerology based solutions",
    content:
      "Get Name and Business Name correction, House, mobile, and vehicle number correction in 48 hours, and change your fortune!",
    btnText: "Contact Now!",
    btnFunction: ["/services/numerology", ""],
  },
  {
    image: S5,
    title: "Get The Chosen stOne Report",
    content:
      "A detailed analysis designed to recommend the most suitable gemstone for your energy and life. We also provide hand-picked, natural, and certified gemstones, ensuring quality and alignment with your goals.",
    btnText: "Get The Chosen stOne Now!!",
    btnFunction: ["/products/gemstones", ""],
  },
];

const CommonServices = () => {
  const { navigateToSection } = useNavigateWithSection();

  return (
    <Wrap
      spacing="6"
      justify="center"
      paddingX={{ base: "4", md: "8" }}
      gap={8}
      align="center"
    >
      {ServiceContentArray?.map((ele, index) => (
        <WrapItem
          key={index}
          flexBasis={{
            base: "100%",
            md: index <= 3 ? "48%" : "100%",
            xl: index < 3 ? "30%" : "45%",
          }}
          justifyContent="center"
        >
          <Box
            width="100%"
            maxWidth={
              ele.title === "Get The Chosen stOne Report" ? "650px" : "450px"
            }
            minWidth="250px"
            height="auto"
            maxHeight="600px"
            minHeight="350px"
          >
            <VStack gap={2}>
              <Image src={ele.image} height="200px" width="200px" />
              <Text
                fontFamily="Fondamento"
                color="#68030D"
                textAlign="center"
                fontSize={textSize}
                fontWeight={800}
                textTransform="capitalize"
              >
                {ele?.title}
              </Text>
              <Text
                color="#68030D"
                fontSize="20px"
                fontWeight={400}
                textAlign={{ base: "justify", md: "center" }}
              >
                {ele?.content}
              </Text>
              <Button
                variant="trPrimary"
                borderRadius="8px"
                padding="13px 20px"
                fontSize={btnTextSize}
                fontWeight={500}
                title={ele?.btnText}
                boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
                onClick={() => {
                  navigateToSection(...ele?.btnFunction);
                }}
              />
            </VStack>
          </Box>
        </WrapItem>
      ))}
    </Wrap>
  );
};
export default CommonServices;
