import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Heading,
  List,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { Button } from "@astrology/shared-components";

const TermsAndCondition = ({ onClose, isOpen }) => {
  const textProps = {
    fontFamily: "Jost",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
  };

  const headerProps = {
    as: "h3",
    size: "md",
    mb: 2,
    color: "var(--astrology-nav)",
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      isCentered
      trapFocus={false}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={900}
            lineHeight={"40px"}
            whiteSpace={"nowrap"}
          >
            Terms & Conditions
          </Text>
        </ModalHeader>
        <ModalBody>
          <Box mx="auto" textAlign={{ base: "left", md: "center" }}>
            <List spacing={6}>
              <ListItem>
                <Heading {...headerProps}>1. Product Authenticity</Heading>
                <Text {...textProps}>
                  We guarantee the authenticity of all gems sold. Slight
                  variations in color, size, or texture may occur due to their
                  natural origin.
                </Text>
              </ListItem>
              <ListItem>
                <Heading {...headerProps}>2. No Refund Policy</Heading>
                <Text {...textProps}>
                  All sales are final and non-refundable. Please review all
                  product details before purchasing.
                </Text>
              </ListItem>
              <ListItem>
                <Heading {...headerProps}>3. Exchanges</Heading>
                <Text {...textProps}>
                  Exchanges are allowed only for damaged or defective items
                  reported immediately after the delivery. The product must be
                  unused. Required proof should be given.
                </Text>
              </ListItem>
              <ListItem>
                <Heading {...headerProps}>4. Shipping</Heading>
                <Text {...textProps}>
                  We ensure secure packaging and reliable shipping. We are not
                  liable for delays caused by customs or unforeseen
                  circumstances.
                </Text>
              </ListItem>
              <ListItem>
                <Heading {...headerProps}>5. Liability Disclaimer</Heading>
                <Text {...textProps}>
                  We are not responsible for dissatisfaction based on personal
                  preferences or misuse of the product.
                </Text>
              </ListItem>
              <ListItem>
                <Heading {...headerProps}>6. Dispute Resolution</Heading>
                <Text {...textProps}>
                  Any disputes will be resolved under the laws of India. Contact
                  us first for any concerns.
                </Text>
              </ListItem>
              <ListItem>
                <Heading {...headerProps}>7. Contact Us</Heading>
                <Text {...textProps}>
                  For support, mail us at{" "}
                  <Link href="mailto:support@achosenson.com">
                    support@achosenson.com
                  </Link>{" "}
                  or message us on{" "}
                  <Link href="tel:+9601465169">9601465169</Link>.
                </Text>
              </ListItem>
            </List>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            variant={"trPrimary"}
            borderRadius={"8px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            title={"Close"}
            boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
            onClick={onClose}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsAndCondition;
