import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Background1 from "../../images/Service1Background.compressed.svg";
import GaneshaTree from "../../images/ganeshatreeillustration.compressed.svg";
import Ganpati from "../../images/ganpati.compressed.svg";
import Lakshmidevi from "../../images/lakshmiDevi.compressed.svg";
import MobileNumerology from "../../images/mobile-numerology.compressed.svg";

import { Button } from "@astrology/shared-components";
import Elephant from "../../images/ElephantImage.compressed.svg";
import Footer from "../Footer/Footer";
import Testimonials from "../Testimonials/Testimonials";

const Numerology = () => {
  const class1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  const class2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      <HStack
        bgImage={`url(${Background1})`}
        className={class1}
        bgSize="cover"
        bgPosition="center"
        alignItems={"center"}
        height={"fit-content"}
        p={4}
        paddingLeft={{ md: 16 }}
        py={{ base: "70px", md: "4" }}
        // justifyContent={"space-around"}
      >
        <VStack gap={3}>
          <Text
            color={"#68030D"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "24px", md: "48px" }}
            fontWeight={1000}
            textTransform={"capitalize"}
            textAlign={{ base: "center", md: "inherit" }}
          >
            Transform Your Life with the Power of Numbers
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "26px" }}
            fontWeight={400}
            textAlign={{ base: "center", md: "inherit" }}
          >
            Unlock your true potential through numerology. Get precise,
            personalized insights delivered straight to your inbox in a detailed
            <span style={{ fontWeight: 600 }}> PDF</span>—no consultations
            needed. Receive your accurate numerology solutions within{" "}
            <span style={{ fontWeight: 600 }}>48 hours</span>.
          </Text>
        </VStack>
        <Image
          src={GaneshaTree}
          display={{ base: "none", md: "inherit" }}
          width="50%"
        />
      </HStack>

      <HStack justifyContent={"center"} my={{ base: "6", md: "8" }}>
        <Text
          textAlign={"center"}
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "30px", md: "50px" }}
          fontWeight={1000}
          textTransform={"capitalize"}
        >
          Name Correction
        </Text>
      </HStack>

      <Flex
        p={{ base: "5", md: "36px" }}
        pt={{ base: "0", md: "36px" }}
        alignItems={"center"}
        flexDir={{ base: "column-reverse", md: "row" }}
        mx={{ base: "auto", md: "50px" }}
        justifyContent={"space-around"}
        w={"full"}
        gap={"25px"}
      >
        <VStack
          gap={"20px"}
          alignItems={"start"}
          maxW={{ base: "full", md: "50%" }}
        >
          <Text
            w={"full"}
            color={"#68030D"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "24px", md: "36px" }}
            fontWeight={1000}
            textTransform={"capitalize"}
            textAlign={{ base: "center", md: "inherit" }}
          >
            Why Name Correction?
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            In your date of birth, every aspect is fixed except for the spelling
            of your name. If your name number is not aligned with your
            Personality and Destiny numbers, you will constantly miss out on
            achieving the desired results.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Name change or name correction to an auspicious vibration leads to
            positive changes in life.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            This won't just impact your personality and behavior but will also
            help you gain the desired success rate in life.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            The results of a name change can be felt in six months. Using the
            ancient Vedic method, you can witness these changes in three months.
          </Text>
          <Flex justifyContent={{ base: "center", md: "center" }} w={"full"}>
            <Button
              variant={"trPrimary"}
              title={
                <VStack gap={1} my={2}>
                  <Text color={"inherit"} fontWeight={"400"}>
                    Change Your Name Now!
                  </Text>
                  <Text color={"inherit"} fontWeight={"400"}>
                    (₹11,000 Only)
                  </Text>
                </VStack>
              }
              borderRadius={"10px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"22px"}
              height={"60px"}
              onClick={() => {
                window.open("https://rzp.io/rzp/qZY5iAh", "_self");
              }}
            />
          </Flex>
        </VStack>
        <Image
          src={Ganpati}
          width={"auto"}
          height={{ base: "200px", md: "auto" }}
        />
      </Flex>

      <Flex
        bgImage={`url(${Background1})`}
        className={class2}
        bgSize="cover"
        bgPosition="center"
        p={{ base: "5", md: "36px" }}
        alignItems={"center"}
        flexDir={{ base: "column", md: "column" }}
        // mx={{ base: "auto", md: "auto" }}
        justifyContent={"center"}
        w={"full"}
        gap={"25px"}
        h={{ base: "full", md: "full" }}
      >
        <Text
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "18px", md: "24px" }}
          fontWeight={1000}
          lineHeight={"34px"}
          textAlign={{ base: "center", md: "inherit" }}
          textTransform={"capitalize"}
          // maxW={"80%"}
        >
          What Do We Provide?
        </Text>

        <HStack
          mx={{ base: "auto", md: "350px" }}
          justifyContent={"space-between"}
          gap={5}
        >
          <Flex gap={2} alignItems={"center"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "26px", md: "48px" }}
              fontWeight={900}
              lineHeight={{ base: "48px", md: "96px" }}
              letterSpacing={"2.4px"}
              textTransform={"capitalize"}
              // textAlign={"start"}
            >
              1
            </Text>
            <Text
              fontFamily={"Jost, sans-serif"}
              fontSize={{ base: "18px", md: "24px" }}
              fontWeight={400}
              lineHeight={{ base: "normal", md: "33px" }}
              // maxW={"70%"}
              textAlign={"justify"}
            >
              We will help you find your correct name number.
            </Text>
          </Flex>
          <Flex gap={2} alignItems={"center"}>
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={{ base: "26px", md: "48px" }}
              fontWeight={900}
              lineHeight={{ base: "48px", md: "96px" }}
              letterSpacing={"2.4px"}
              textTransform={"capitalize"}
              // textAlign={"start"}
            >
              2
            </Text>
            <Text
              fontFamily={"Jost, sans-serif"}
              fontSize={{ base: "18px", md: "24px" }}
              fontWeight={400}
              lineHeight={{ base: "normal", md: "33px" }}
              // maxW={"70%"}
              textAlign={"justify"}
            >
              Iron out the differences and implement the correct name.
            </Text>
          </Flex>
        </HStack>
        <Flex
          mx={{ base: "auto", md: "300px" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            lineHeight={{ base: "normal", md: "34px" }}
            textAlign={{ base: "justify", md: "inherit" }}
            // maxW={"80%"}
          >
            We understand your name is your identity that you have been carrying
            since birth. We assist with the right approach according to your
            needs, offering numerous name options based on your life path
            number.
          </Text>
        </Flex>
        <Button
          variant={"trPrimary"}
          title={
            <VStack gap={1} my={2}>
              <Text color={"inherit"} fontWeight={"400"}>
                Change Your Name Now!
              </Text>
              <Text color={"inherit"} fontWeight={"400"}>
                (₹11,000 Only)
              </Text>
            </VStack>
          }
          borderRadius={"10px"}
          padding={"13px 20px"}
          fontSize={"16px"}
          fontWeight={400}
          lineHeight={"22px"}
          height={"60px"}
          onClick={() => {
            window.open("https://rzp.io/rzp/qZY5iAh", "_self");
          }}
        />
      </Flex>

      <Flex
        p={{ base: "5", md: "36px" }}
        alignItems={"center"}
        flexDir={{ base: "column", md: "row" }}
        mx={{ base: "auto", md: "50px" }}
        justifyContent={"space-around"}
        w={"full"}
        gap={"25px"}
      >
        <Image
          src={Lakshmidevi}
          width={"auto"}
          height={{ base: "200px", md: "auto" }}
        />
        <VStack
          gap={"20px"}
          alignItems={"start"}
          maxW={{ base: "full", md: "50%" }}
        >
          <Text
            w={"full"}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "30px", md: "50px" }}
            fontWeight={1000}
            textTransform={"capitalize"}
          >
            Business Name Correction
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            The name of an institute is just as important as that of an
            individual. Your business name number doesn't have to be aligned
            with your personal number.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            However, it is of utmost importance that your business name number
            is aligned with the energy of the domain you are working in.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            From Mukesh Ambani to Elon Musk, everyone has been very particular
            about this aspect, and the success of their businesses speaks for
            itself.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            So why should you miss out, especially after knowing this?
          </Text>
          <Flex justifyContent={{ base: "center", md: "center" }} w={"full"}>
            <Button
              variant={"trPrimary"}
              title={
                <VStack gap={1} my={2}>
                  <Text color={"inherit"} fontWeight={"400"}>
                    Change your Business name now!
                  </Text>
                  <Text color={"inherit"} fontWeight={"400"}>
                    (₹11,000 Only)
                  </Text>
                </VStack>
              }
              borderRadius={"10px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"22px"}
              height={"60px"}
              onClick={() => {
                window.open("https://rzp.io/rzp/8wrliOnr", "_self");
              }}
            />
          </Flex>
        </VStack>
      </Flex>

      <Flex
        bgImage={`url(${Background1})`}
        className={class2}
        bgSize="cover"
        bgPosition="center"
        p={{ base: "5", md: "36px" }}
        pb={{ base: "5", md: "50px" }}
        alignItems={"center"}
        flexDir={{ base: "column", md: "row" }}
        mx={{ base: "auto", md: "auto" }}
        justifyContent={"space-around"}
        w={"full"}
        gap={"25px"}
        h={{ base: "full", md: "full" }}
      >
        <Image
          src={MobileNumerology}
          width={"auto"}
          height={{ base: "200px", md: "auto" }}
        />
        <VStack
          gap={"20px"}
          alignItems={"start"}
          maxW={{ base: "full", md: "50%" }}
        >
          <Text
            w={"full"}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "30px", md: "50px" }}
            fontWeight={1000}
            textTransform={"capitalize"}
          >
            Mobile Numerology
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Is your business stagnant at a certain point?
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Are you unable to close deals at the last minute?
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Can you sense a pattern of how things go south in a particular
            manner?
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Lately, have you started realizing that sustaining the order book
            has been an issue for you?
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            If any of these things worry you, then you should get your mobile
            number checked. Mobile Numerology is for entrepreneurs and also for
            people who are not winning in love despite giving their all.
          </Text>
          <Flex justifyContent={{ base: "center", md: "center" }} w={"full"}>
            <Button
              variant={"trPrimary"}
              title={
                <VStack gap={1} my={2}>
                  <Text color={"inherit"} fontWeight={"400"}>
                    Change your Mobile Number Now!
                  </Text>
                  <Text color={"inherit"} fontWeight={"400"}>
                    (₹5,000 Only)
                  </Text>
                </VStack>
              }
              borderRadius={"10px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"22px"}
              height={"60px"}
              onClick={() => {
                window.open("https://rzp.io/rzp/8DVrohcr", "_self");
              }}
            />
          </Flex>
        </VStack>
      </Flex>

      <Flex
        p={{ base: "5", md: "36px" }}
        alignItems={"center"}
        flexDir={{ base: "column-reverse", md: "row" }}
        mx={{ base: "auto", md: "50px" }}
        justifyContent={"space-around"}
        w={"full"}
        gap={"25px"}
      >
        <VStack
          gap={"20px"}
          alignItems={"start"}
          maxW={{ base: "full", md: "50%" }}
        >
          <Text
            w={"full"}
            textAlign={"center"}
            fontFamily={"Fondamento, sans-serif"}
            fontSize={{ base: "30px", md: "50px" }}
            fontWeight={1000}
            textTransform={"capitalize"}
          >
            Choosing the right vehicle number
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            A well-chosen vehicle number can enhance the overall driving
            experience, making every journey more enjoyable and aligned with
            personal goals and aspirations.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Moreover, it will attract safety, success, and positive experiences
            on the road.
          </Text>
          <Text
            color={"#68030D"}
            fontFamily={"Jost, sans-serif"}
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight={400}
            textAlign={{ base: "justify", md: "inherit" }}
          >
            Discover the perfect vehicle number today with us and drive with
            confidence into a prosperous future!
          </Text>
          <Flex justifyContent={{ base: "center", md: "center" }} w={"full"}>
            <Button
              variant={"trPrimary"}
              title={
                <VStack gap={1} my={2}>
                  <Text color={"inherit"} fontWeight={"400"}>
                    Change Your Vehicle Number Now!
                  </Text>
                  <Text color={"inherit"} fontWeight={"400"}>
                    (₹8,000 Only)
                  </Text>
                </VStack>
              }
              borderRadius={"10px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              height={"60px"}
              onClick={() => {
                window.open("https://rzp.io/rzp/DfemZHu", "_self");
              }}
            />
          </Flex>
        </VStack>
        <Image
          src={Elephant}
          width={"auto"}
          height={{ base: "200px", md: "auto" }}
        />
      </Flex>

      {/* <Box height="10vh" display={{ base: "flex", md: "none" }} /> */}
      <Testimonials screen={"numerology"} />
      <Footer viewRatings={true} />
    </>
  );
};

export default Numerology;
