export const testimonial = {
  home: [
    {
      text: `I am thoroughly impressed with the detailed and insightful guidance I received. Every question I asked was answered with such clarity, depth, and practical advice. The astrologer not only provided predictions but also offered actionable remedies for each concern.
      Overall, this consultation has brought clarity and direction to both my personal and professional life. The remedies suggested were simple yet profound.`,
    },
    {
      text: `Hi Jay, first of all thank you so much for the amazing session. I really like your approach towards astrology and how simple you make it for us so that anyone can understand it. You gave me a new approach to work towards my career. Will definitely start doing the remedies from tomorrow. Jay, you have a great positive energy which i think anyone can feel, it really felt like talking to some old friend or a brother who is in a different part of the country. More power to you will
      definitely speak to you again.
      Thank you so much.`,
    },
    {
      text: `Dear Jay Sir,
I wanted to express my gratitude for the insightful report you prepared by studying my birth chart. The way you explained the key planetary positions and their influence on my career has given me much-needed clarity. After going through your analysis, I feel a renewed sense of confidence and direction in my professional path. Your guidance has truly made a difference, and I am grateful for your support.
Thank you once again for your help and expertise. 🙏🏻❤️`,
    },
    {
      text: `Hey team Jay,Thank You so much for such a prompt response..the reading is too accurate and very much in detail..I hope and wish to connect with you someday. Your guidance is going to help me alot Thank You once again You're doing such a noble work of guiding people and helping them out in their tough times..may God always bless you with abundance.`,
    },
    {
      text: `Thank you, Jay. You made my charts easy to understand and showed me that true change lies in me, not just remedies. Astrology is about discipline and self-awareness. I highly recommend Jay for his ability to put you at ease and answer all my questions. Wishing you the best—you’re sure to change how people view astrologers.`,
    },
    {
      text: `This was my first astrology session by choice, and I’m not a firm believer, but I felt an eerie calm talking to you. You backed every point with logic and made sure I followed along. Though it was a lot to digest as a beginner, you broke it down into bite-sized nuggets. Your calm, patient aura stood out, which is crucial in your profession.`,
    },
  ],

  notes: [
    {
      text: `I read my report and the detail that you provided is so damn good.....one of the best things is that you didn't fear monger a person like other astro related people do..... i started following you because you yourself told in your videos that everything is fixable in this universe....the clarity that you gave me because of that i know on what things i have to work on more .......I really wish i could book a live session with you soon or maybe meet you sometime later in life......huge respect brother.👌👌`,
    },
    {
      text: `Thank you so much Sir 🙏🏻. I read it three times and it filled me with confidence. Now I'll work hard to resolve my problems and follow the remedies you have suggested to me. No one has ever told me why I go though these situations when I was trying to find answers. Thank you so much Jay Sir.   I'll  do these remedies and share with you about my progress. I'll meet you someday somewhere. God bless you 🙏🏻🙏🏻🙏🏻🙏🏻`,
    },
    {
      text: `Thank you so much, Jay Sir, for explaining the report in such a clear and relatable way.I will begin implementing the suggested remedies right away and will update you on any changes over the next couple of months. I am confident that these steps will bring about positive results.Once I secure a job, I look forward to connecting with you in person. I will definitely need your guidance to excel both in my career and personal life.`,
    },
    {
      text: `Also you predicted my possible career options and based on what you told me I am working in Supply chain (Shipping and Logistics) and beauty industry. So this prediction was right. This also assures me that I am in a right career🥹`,
    },
    {
      text: `Thank You jay. you have very correctly pointed out the emotional turmoil I go through, and now I get the reason for them.also the remedies you have mentioned are simple and easy to follow. I would like to do a video call session with you in the near future for further insights . But overall I have gained a lot of clarity about why things have been happening in my life acertain way .Thank you for your guidance and always keep helping people like us to find our true paths in life.`,
    },
    {
      text: `Thank you Jay and team for the accurate report...All the details are to the point and remedies are simple...I will start practicing the remedies soon in my day to day life... Hoping to meet you in person one day and have one to one conversation ✨`,
    },
    {
      text: `Hello sir, just read the report twice and wanna say thanks for the precise answers I was seeking for and providing me with some remedies along. Also, I appreciate you for explaining the meaning of the retrograde planets in my chart and what karmas I have to perform in this life. This is 👏 I will surely do the work that has to be done. Thank you for your sheer guidance & god bless!`,
    },
    {
      text: `Hi Jay, I can't thank you enough for the insightful report! It's truly a life guide that has given me clarity on what I need to do moving forward. I plan to save this report and revisit it whenever self-doubt creeps in. I'm committed to implementing the remedies you suggested and will definitely share feedback on my progress. Your guidance has been invaluable, and I'll be sure to reach out whenever I need advice. Thank you again for your support`,
    },
    {
      text: `Hey Jay, so it took me some time to get back on the readings you've delivered. I went through the answers multiple times & all I've gotta say is that you're more of a therapist than an astrologer !! Your  practical approach to life  & fixing one's personality to overcome the obstacles has given me confidence to lead my path hereon. I've already begun doing the suggested remedies & I'm sure I will get back with positive results very soon. Immense gratitude for your guidance and support. Thank you!!`,
    },
    {
      text: `Thanks a lot sir! The way everything has been explained in the pdf and all of your videos have been an eye opener for me. Been reading the pdf again and again and through a series of events my perspective towards life has entirely changed just at the right time in the right way. I'm really grateful for the moment when I came across you and will stay connected with your insightful wisdom. I also wish to have a session with you in person in the near future. Until then I will work towards being the best version of myself. Thanks a lot for the effort put in by you and your team.`,
    },
  ],

  consultant: [
    {
      text: `Hello sir, It was great speaking with you today. I was going through a difficult phase, I struggled to find peace and couldn't confide in anyone. However, Jay sir, you astoundingly understood my situation and offered both insight and natural solutions. It was an exceptional experience, and conversing with you was equally delightful sir. Easy to speak with and very down to earth. Thank you so much. Also Good work sir, I hope many others, like me, can also find solutions through your guidance.`,
    },
    {
      text: `Hi Jay, first of all thank you so much for the amazing session. I really like your approach towards astrology and how simple you make it for us so that anyone can understand it. You gave me a new approach to work towards my career. Will definitely start doing the remedies from tomorrow.`,
    },
    {
      text: `Jay you have a great positive energy which I think anyone can feel, it really felt like talking to some old friend or a brother who is in a different part of the country. More power to you will definitely speak to you again. Thank you so much.`,
    },
    {
      text: `Hello! Had an amazing time in the session. There were moments like when you said about emotional eating and I was shit yes I do that a lot! And how can someone possibly no about it, but you did. You gave me a new perspective on things...and you've challenged me that I'll put on weight and I challenge you to it will be stable, because as you said I'm gonna work hard...be it working hard on my body, or in business both. For sure. Thanks so much. Also, your aura is amazing. I could sense that.`,
    },
    {
      text: `Hi Jay ! It was lovely talking to you. The session was insightful and you have a great knowledge of astrology. Your manner of explaining things and simplifying them is great. I'm glad I spoke to you. All the best and thank you for your advice.`,
    },
    {
      text: `Thank you so much Jay for such an amazing reading! I could relate with every reading you did, especially the Mars one because I know I feel bored when I get lazy so I never sit idle I will definitely take your advice under consideration and let you know once I buy my first property After you told me about the devotional side, I feel I should invest my time more towards it too.`,
    },
    {
      text: `Thank you for the session Jay Brother, you've added more clarity in my mind. I now know what areas of my life need work. Where do I have to put more effort and where should I not. I promise you to achieve it all. I will make you and the person in the mirror feel proud. Once again Thank you so much man!!`,
    },
    {
      text: `Hello jay, It was a great experience talking with you today,really amazed how much you can tell about me just by looking at my kundli, I am feeling relaxed now and will follow all the advice you gave today step by step, i must say this session seemed like someone showing a path for a great life based on my qualities and drawbacks, will surely let you know about my experiences in future. Thank you very much for the session today!`,
    },
    {
      text: `Thank you! This was really helpful and the details shared were on point and easy to understand.
    I really liked your approach and hope more astrologers use such approaches rather than just embedding fear in people's minds without any proper explanations.
    Keep growing!`,
    },
    {
      text: `I would like to thank you for giving wonderful insight about my personality and clearing all my doubts of what I am today and which direction I must go in future. You have a very clear approach and shared simple yet practical remedies. I loved your energy today and you are really confident and have a deep knowledge of your work. Thanks for guiding me, you are truly God sent.`,
    },
    {
      text: `OMG! What an experience!!
    I am so obliged to talk to you and hear such accurate predictions in one session. Apologies for writing to you little late due to busy schedule but yes looking forward to hear from you in near future
    Last but not least, Thank you so much for providing guidance in an accurate direction. I am grateful for life and blessings from God
    Best regards,`,
    },
    {
      text: `Also would like to mention that it took me sometime to like in general absorb everything we discussed over the session. A lot of the observations you had regarding me were on point. When you asked me if I have had issues with my dad I was very taken aback because not only is it true but it was also a very painful set of emotions for me to navigate. All in all a great session and I got a lot of insight into why I am the way I am.`,
    },
    {
      text: `Jay is an incredibly insightful astrologer. His guidance has been valuable to me, providing clarity and direction in my life. Speaking with you was truly delightful.
    Your knowledge of astrology is impressive, and conversing with you felt like seeking advice from a trusted friend. Thank you for your exceptional service. I look forward to meeting you again, Jay Thank you.`,
    },
    {
      text: `Hi @achosenson, I wanted to take a moment to express my gratitude and share my experience with our recent discussion. Your insights and guidance were truly enlightening and helped me and I left with a place feeling grateful. Thank you so much
    Your approach was professional, and spot on! I felt seen, heard & I highly recommend your services to anyone seeking astrological guidance.
    `,
    },
  ],

  numerology: [
    {
      text: `Hello,
Just wrapped up my session with Jay sir, what an exceptional guy. Very happy and satisfied with all the things he told me. I feel more confident about my goals after talking with him, explaining each and everything with crisp detail which helped me get more clarity. Keep doing the good work, and I'll see you soon again. Thank you.`,
    },
    {
      text: `Wow I didn't expect the answers to my questions will be in depth and well explained. Thank you so much. It helped me realize the things I was doing wrong in my life. The ☑ remedies are doable & the answers 9 are very well explained according to the planets in my chart. Thank you Jay, your way of explaining things is unique.`,
    },
    {
      text: `Thank you so much to Jay and team, my heartfelt thanks for the written notes you've provided. They were incredibly helpful and offered great clarity on the subject. I truly appreciate the time and efforts that have been put into preparing them, especially the key details.`,
    },
    {
      text: `Thank you so much Jay
You filled me up with confidence today. I'm going to follow what you told me.
Talking to you was a big relief. I'll see you so0n with my partner's chart next time. Take care of your health and energy as you deal with so many different people everyday. You doing a big Job guiding people`,
    },
    {
      text: `Hi Jay sir, thank you so much for your assistance. Your straightforward advice was incredibly helpful, and I appreciate that you didn't push any products or solutions.
Even while being sick, you took the time to review the report and handle everything calmly and respectfully. I look forward to seeking your advice again in the future and will definitely recommend you to others. Hope you get well soon! :)`,
    },
    {
      text: `Thank you so much. The analysis has really helped me with my major queries. The reasons mentioned in the analysis are quite authentic and the solutions provided to them are quite relevant. Hope things you have mentioned get true and will do the remedies advised by you. Thank you again for taking the time out and doing the chart analysis and helping me with your knowledge.`,
    },
  ],
};
