import React from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import Background1 from "../../images/Service1Background.compressed.svg";
import GemsImageSlider from "../../components/GemsImageSlider";

const Opal = () => {
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });

  return (
    <>
      {/* web view */}

      <VStack
        className={c2}
        bgImage={`url('${Background1}')`}
        w={"full"}
        h={{ base: "auto" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"top"}
        paddingY="50px"
        display={{ base: "none", md: "flex" }}
        mb={"50px"}
      >
        <HStack>
          <Flex
            w={{ base: "full", md: "50vw", lg: "50vw" }}
            h={{ base: "300px", md: "500px", lg: "500px" }}
          >
            <GemsImageSlider type="opal" />
          </Flex>
          <Flex
            alignItems={"start"}
            justifyContent={"start"}
            gap={8}
            mr={"30px"}
            flexDir={"column"}
            maxW={"50%"}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              textTransform={"capitalize"}
              fontWeight={900}
              lineHeight={"40px"}
            >
              OPAL ( ओपल )
            </Text>
            <Flex gap={"5"} flexDir={"column"}>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                Who should wear Opal stone ?
              </Text>
              <OrderedList
                spacing={3}
                pl={4}
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color="var(--astrology-nav)"
              >
                <ListItem>
                  Enhances intuitive power and psychic abilities.
                </ListItem>
                <ListItem>
                  Wearing opals can help balance emotions, reduce stress, and
                  promote a calm and centered state of mind. Creativity and
                  Inspiration: Opals are thought to stimulate creativity and
                  originality, making it ideal for artists and writers.
                </ListItem>
                <ListItem>
                  Worn in the <Text display="inline">Index Finger</Text>.
                </ListItem>
                <ListItem>
                  It can help improve mutual understanding between a couple and
                  increase love, Offers radiating confidence and Offers creative
                  guidance.
                </ListItem>
              </OrderedList>
            </Flex>

            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Buy Now!!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://wa.me/+916355230818", "_blank");
              }}
            >
              Buy Now!!
            </Button>
          </Flex>
        </HStack>
      </VStack>

      {/* mobile view  */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"150px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"400px"}
          backgroundPosition={"center bottom"}
          backgroundSize={"cover"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <GemsImageSlider type="opal" />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            OPAL ( ओपल )
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Who should wear Opal stone ?
          </Text>
          <OrderedList
            spacing={3}
            pl={4}
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            lineHeight={"30px"}
            color="var(--astrology-nav)"
          >
            <ListItem>Enhances intuitive power and psychic abilities.</ListItem>
            <ListItem>
              Wearing opals can help balance emotions, reduce stress, and
              promote a calm and centered state of mind. Creativity and
              Inspiration: Opals are thought to stimulate creativity and
              originality, making it ideal for artists and writers.
            </ListItem>
            <ListItem>
              Worn in the <Text display="inline">Index Finger</Text>.
            </ListItem>
            <ListItem>
              It can help improve mutual understanding between a couple and
              increase love, Offers radiating confidence and Offers creative
              guidance.
            </ListItem>
          </OrderedList>

          <Button
            variant={"trPrimary"}
            title={"Buy Now!!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/+916355230818", "_blank");
            }}
          >
            Buy Now!!
          </Button>
        </Flex>
      </VStack>
    </>
  );
};

export default Opal;
