import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import TestimonialBg from "../../images/TestimonialBg.compressed.svg";
import TestimonialMobBg from "../../images/TestimonialMobBg.compressed.svg";

const TestimonialCard = ({ text, mob }) => {
  const isSmallestScreenSize = useMediaQuery("(max-width: 480px");

  const useWidth = () => {
    const isSmallMobile = useMediaQuery("(max-width: 375px)");
    const isMobile = useMediaQuery("(min-width: 375px) and (max-width: 480px)");
    const isGeneralMobile = useMediaQuery(
      "(min-width: 480px) and (max-width: 480px)"
    );

    if (isSmallMobile) {
      return "smallMobile";
    } else if (isMobile) {
      return "mobile";
    } else if (isGeneralMobile) {
      return "generalMobile";
    } else {
      return "other";
    }
  };

  const commonStyles = {
    background: `url('${mob ? TestimonialMobBg : TestimonialBg}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "10px",
    bgRepeat: "no-repeat",
    mx: "auto",
    p: { base: "0px" },
    h: {
      base: isSmallestScreenSize ? "500px" : "550px",
      md: "430px",
      xl: "400",
    },
  };

  //  //mobile view
  //         <Box
  //           {...commonStyles}
  //           w={{
  //             base: isSmallestScreenSize ? "280px" : "350px",
  //             md: "260px",
  //             lg: "350px",
  //             xl: "400px",
  //           }}
  //         ></Box>

  return (
    <>
      {mob ? (
        <Box
          background={`url('${TestimonialMobBg}')`}
          borderRadius="10px" // rounded corners
          bgRepeat="no-repeat"
          p={4}
          // p="6" // padding
          // boxShadow="lg" // shadow for depth
          // left={{ base: "5px", md: "0px" }}
          maxW={{ base: "280px", md: "350px" }} // maximum width
          mx="auto" // center horizontally
          // mt="8" // margin at the top
          // pos="relative"
          h={{ base: "390px", md: "58vh" }}
          overflow={"hidden"}
        >
          {/* <Stack zIndex="5" position="relative" ml={-5}> */}
          <Text
            fontSize={{ sm: "1rem", md: "1rem", lg: "1rem" }}
            fontWeight={500}
            // m={4}

            h={"full"}
            overflow={"hidden"}
            color={"var(--3, #68030D)"}
            fontFamily={"Jost"}
            // maxW={{ base: "290px", md: "inherit" }}
          >
            {text}
          </Text>
        </Box>
      ) : (
        //desktop view
        <Box {...commonStyles} w={{ base: "90%", md: "310px", lg: "360px" }}>
          <Text
            fontSize={{ sm: "1rem", md: "1rem", lg: "1.1rem" }}
            fontWeight={500}
            m={4}
            pt={6}
            color={"var(--3, #68030D)"}
            fontFamily={"Jost"}
          >
            {text}
          </Text>
        </Box>
      )}
    </>
  );
};

export default TestimonialCard;
