import React from "react";
import {
  Button,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import Background1 from "../../images/Service1Background.compressed.svg";
import GemsImageSlider from "../../components/GemsImageSlider";

const Sapphire = () => {
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      {/* web view 2nd section */}
      <VStack
        w={"full"}
        h={{ base: "full" }}
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        display={{ base: "none", md: "flex" }}
        mb={"50px"}
      >
        <HStack justifyContent={"center"}>
          <Flex
            alignItems={"start"}
            justifyContent={"start"}
            gap={8}
            ml={"50px"}
            flexDir={"column"}
            maxW={"47%"}
          >
            <Text
              fontFamily={"Fondamento, sans-serif"}
              fontSize={"36px"}
              textTransform={"capitalize"}
              fontWeight={900}
              lineHeight={"40px"}
              whiteSpace={"nowrap"}
            >
              BLUE SAPPHIRE ( नीलम )
            </Text>
            <Flex gap={"5"} flexDir={"column"}>
              <Text
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
              >
                Who should wear blue sapphire stone ?
              </Text>
              <OrderedList
                spacing={3}
                pl={4}
                fontFamily={"Jost"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"30px"}
                color="var(--astrology-nav)"
              >
                <ListItem>
                  Ruled by Saturn , the planet of Justice. Lord Shani is
                  associated with qualities such as discipline, perseverance,
                  responsibility, and longevity.Hence, it is also known as
                  Shani-Priya.
                </ListItem>
                <ListItem>
                  Taurus and Libra Ascendant people are best suited because
                  Shani Dev is their Yogkaraka.
                </ListItem>
                <ListItem>Is worn on Saturday.</ListItem>
                <ListItem>
                  Is worn in the <Text display="inline">Middle Finger</Text>.
                </ListItem>
                <ListItem>
                  It regulates the functioning of the Third Eye, Crown, and
                  Throat Chakras mainly.
                </ListItem>
                <ListItem>
                  The Neelam stone boosts our financial growth, power of
                  intuition, and mental clarity for thoughts.
                </ListItem>
                <ListItem>
                  This dynamic stone mainly controls the air element out of the
                  5 major elements in astrology.
                </ListItem>
              </OrderedList>
            </Flex>

            <Button
              variant={"trPrimary"}
              borderRadius={"8px"}
              padding={"13px 20px"}
              fontSize={"16px"}
              fontWeight={500}
              lineHeight={"22px"}
              title={"Buy Now!!"}
              boxShadow={"0px 1px 2px 0px rgba(15, 23, 42, 0.06)"}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://wa.me/+916355230818", "_blank");
              }}
            >
              Buy Now!!
            </Button>
          </Flex>
          <Flex
            w={{ base: "full", md: "45vw", lg: "50vw" }}
            h={{ base: "300px", md: "500px", lg: "500px" }}
          >
            <GemsImageSlider type="blueSapphire" />
          </Flex>
        </HStack>
      </VStack>

      {/* mobile view 2nd section */}
      <VStack
        w={"full"}
        h={{ base: "full", md: "90vh" }}
        flexDir={"column"}
        className={c2}
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5"}
        mb={"100px"}
      >
        <Flex
          bgImage={`url('${Background1}')`}
          w={"full"}
          h={"400px"}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          aspectRatio={3 / 4}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c2}
        >
          <GemsImageSlider type="blueSapphire" />
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          mx={"auto"}
          flexDir={"column"}
          p={2}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"20px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"35px"}
            letterSpacing={"0.5px"}
          >
            BLUE SAPPHIRE ( नीलम )
          </Text>
          <Text
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            textAlign={"center"}
          >
            Who should wear blue sapphire stone ?
          </Text>
          <OrderedList
            spacing={3}
            pl={4}
            fontFamily={"Jost"}
            fontSize={"18px"}
            fontWeight={400}
            lineHeight={"30px"}
            color="var(--astrology-nav)"
          >
            <ListItem>
              Ruled by Saturn , the planet of Justice. Lord Shani is associated
              with qualities such as discipline, perseverance, responsibility,
              and longevity.Hence, it is also known as Shani-Priya.
            </ListItem>
            <ListItem>
              Taurus and Libra Ascendant people are best suited because Shani
              Dev is their Yogkaraka.
            </ListItem>
            <ListItem>Is worn on Saturday.</ListItem>
            <ListItem>
              Is worn in the <Text display="inline">Middle Finger</Text>.
            </ListItem>
            <ListItem>
              It regulates the functioning of the Third Eye, Crown, and Throat
              Chakras mainly.
            </ListItem>
            <ListItem>
              The Neelam stone boosts our financial growth, power of intuition,
              and mental clarity for thoughts.
            </ListItem>
            <ListItem>
              This dynamic stone mainly controls the air element out of the 5
              major elements in astrology.
            </ListItem>
          </OrderedList>

          <Button
            variant={"trPrimary"}
            title={"Buy Now!!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/+916355230818", "_blank");
            }}
          >
            Buy Now!!
          </Button>
        </Flex>
      </VStack>
    </>
  );
};

export default Sapphire;
