import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Background1 from "../../images/Service1Background.compressed.svg";

const Commingsoon = () => {
  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-4", // For medium screens
  });
  return (
    <>
      <Flex
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"100vw"}
        h={"calc(100vh - 64px)"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text
          fontFamily={"Fondamento, sans-serif"}
          fontSize={{ base: "22px", md: "50px" }}
          fontWeight={"900"}
          textAlign={"center"}
          textTransform={"capitalize"}
        >
          Coming Soon
        </Text>
      </Flex>
    </>
  );
};

export default Commingsoon;
