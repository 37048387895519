import { Button } from "@astrology/shared-components";
import {
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import Background3 from "../../images/Home-Bg3.compressed.svg";
import MobileBackground02 from "../../images/mobile/home-page-02.png";
import useNavigateWithSection from "../../services/navigateWithSection";

const Points = () => {
  return (
    <UnorderedList>
      <ListItem>
        <Text
          fontFamily={"Jost"}
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          //   maxW={"90%"}
        >
          He spent his entire life for these learnings and the answers you are
          looking for (he is born to do this)
        </Text>
      </ListItem>
      <ListItem>
        <Text
          fontFamily={"Jost"}
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          //   maxW={"90%"}
        >
          Authentic and easy to understand astrological readings
        </Text>
      </ListItem>
      <ListItem>
        <Text
          fontFamily={"Jost"}
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          //   maxW={"90%"}
        >
          In depth astrological analysis of your life concerns using Charts,
          Nakshatras, Transits
        </Text>
      </ListItem>
      <ListItem>
        <Text
          fontFamily={"Jost"}
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          //   maxW={"90%"}
        >
          Accurate predictions of your past, present and future{" "}
        </Text>
      </ListItem>
      <ListItem>
        <Text
          fontFamily={"Jost"}
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          //   maxW={"90%"}
        >
          Powerful, effective and practical remedies for your life problems
        </Text>
      </ListItem>

      <ListItem>
        <Text
          fontFamily={"Jost"}
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          //   maxW={"90%"}
        >
          Your life patterns that you need to break and change to achieve the
          things you want
        </Text>
      </ListItem>
    </UnorderedList>
  );
};

const ConsultNow = () => {
  const c3 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "footer-mask", // For medium screens
  });

  const { navigateToSection } = useNavigateWithSection();

  return (
    <>
      <VStack
        bgImage={`url('${Background3}')`}
        w={"full"}
        h={"90vh"}
        className={c3}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"flex-end"}
        justifyContent={"center"}
        flexDir={"column"}
        backgroundPosition={"top"}
        display={{ base: "none", md: "flex" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          gap={8}
          maxW={"45%"}
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"36px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            lineHeight={"70px"}
          >
            Why Consult with Jay?
          </Text>
          <Points />
          <Button
            variant={"trPrimary"}
            title={"Book your consultation now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </Flex>
      </VStack>

      <VStack
        w={"full"}
        pb={5}
        h={"full"}
        flexDir={"column"}
        bgColor={"#FDE9CF"}
        display={{ base: "flex", md: "none" }}
      >
        <Flex
          bgImage={`url('${MobileBackground02}')`}
          w={"full"}
          h={"480px"}
          backgroundPosition={"center bottom"}
          backgroundSize={"cover"}
          aspectRatio={4 / 3}
          bgRepeat="no-repeat"
          display={"flex"}
          className={c3}
        ></Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={8}
          maxW={"100%"}
          mx={4}
          flexDir={"column"}
        >
          <Text
            fontFamily={"Fondamento, sans-serif"}
            fontSize={"30px"}
            textTransform={"capitalize"}
            fontWeight={1000}
            textAlign={"center"}
            lineHeight={"40px"}
            letterSpacing={"1.5px"}
          >
            Consult Now
          </Text>
          <Points />
          <Button
            variant={"trPrimary"}
            title={"Book your consultation now!"}
            borderRadius={"10px"}
            padding={"13px 20px"}
            fontSize={"15px"}
            fontWeight={500}
            lineHeight={"22px"}
            height={{ base: "36px", md: "5vh" }}
            mx={"auto"}
            onClick={() => {
              navigateToSection(
                "/services/consultation",
                "consultation-booking"
              );
            }}
          />
        </Flex>
      </VStack>
    </>
  );
};

export default ConsultNow;
