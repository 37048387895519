import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const variants = defineStyle({
  xsFont: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "14px",
    color: "var(--atlantic-300)",
  },
  smFont: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "var(--atlantic-300)",
  },
  mdFont: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "var(--atlantic-700)",
  },
  lgFont: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "var(--atlantic-700)",
  },
  xlgFont: {
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "30px",
    color: "var(--atlantic-700)",
  },
  tanStackHeader: {
    fontSize: "12px",
    color: "var(--atlantic-400)",
    lineHeight: "18px",
    fontWeight: 600,
  },
  smWidget: {
    fontSize: "12px",
    color: "var(--atlantic-300)",
    fontWeight: 400,
    lineHeight: "18px",
  },
  smBoldWidget: {
    fontSize: "12px",
    color: "var(--atlantic-700)",
    fontWeight: 500,
    lineHeight: "18px",
  },
  lgWidget: {
    fontSize: "16px",
    color: "var(--atlantic-700)",
    fontWeight: 600,
    lineHeight: "24px",
  },
  tanStackBodySmall: {
    fontSize: "14px",
    color: "var(--atlantic-700)",
    lineHeight: "20px",
    fontWeight: 400,
  },
  tanStackBodySmallBold: {
    fontSize: "14px",
    color: "var(--atlantic-700)",
    lineHeight: "20px",
    fontWeight: 500,
  },
  tanStackBodyClickableCell: {
    fontSize: "14px",
    color: "inherit !important",
    lineHeight: "20px",
    fontWeight: 500,
    _groupHover: { textDecoration: "underline" },
  },
  tanStackBodyMedium: {
    fontSize: "14px",
    color: "var(--atlantic-700)",
    lineHeight: "20px",
    fontWeight: 500,
  },
});

export const textTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    color:"#68030D",
    fontFamily:"Jost" // <-- border radius is same for all variants and sizes
  },
  variants: variants,
});
