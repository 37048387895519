import { Button } from "@astrology/shared-components";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import bgNav from "../images/bgNav.compressed.svg";
import { ReactComponent as DummyLogoIcon } from "../images/dummyLogo.svg";
import Advertise from "../features/Advertise";
import { ReactComponent as VectorIcon } from "../images/vector.svg";

const activeStyle = {
  "&:hover": { textDecoration: "underline" },
};

export default function SidebarWithHeader({ routes, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("white", "gray.900")}>
      <Navigation routes={routes} />
      <Box transition="0.5s ease">{children}</Box>
    </Box>
  );
}

const NavigationDisplay = ({ ele }) => {
  let location = useLocation();

  return (
    <Link
      as={RouterLink}
      to={ele.path}
      style={
        location.pathname.indexOf(ele?.path) !== -1
          ? {
              textDecoration: "underline",
              fontSize: "16px",
              lineHeight: "22px",
              fontWeight: 500,
              textTransform: "uppercase",
            }
          : {
              textDecoration: "none",
              fontSize: "16px",
              lineHeight: "22px",
              fontWeight: 500,
              textTransform: "uppercase",
            }
      }
      _focus={{ boxShadow: "none" }}
      align={"center"}
      p={"8px"}
      role="group"
      cursor="pointer"
      width={"inherit"}
      color="var(--astrology-nav)"
      _hover={activeStyle}
    >
      <Box>{ele.name}</Box>
    </Link>
  );
};

const MobileNavigationDisplay = ({
  ele,
  textDisplay,
  align = "center",
  color,
  linkColor,
  onClose,
}) => {
  let location = useLocation();

  return (
    <Box
      align={align ? align : "center"}
      p={"8px"}
      role="group"
      cursor="pointer"
      width={"inherit"}
      color={color ? color : "var(--astrology-nav)"}
    >
      {textDisplay ? (
        <Text
          style={{
            textDecoration: "none",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 500,
            textTransform: "uppercase",
            color: color ? color : "inherit",
          }}
        >
          {ele.name}
        </Text>
      ) : (
        <Link
          as={RouterLink}
          to={ele.path}
          style={
            location.pathname.indexOf(ele?.path) !== -1
              ? {
                  textDecoration: "underline",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }
              : {
                  textDecoration: "none",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: linkColor ? linkColor : "var(--astrology-mobnav-txt)",
                }
          }
          _focus={{ boxShadow: "none" }}
          width={"100%"}
          onClick={() => {
            onClose();
          }}
        >
          {ele.name}
        </Link>
      )}
    </Box>
  );
};
const Navigation = ({ routes }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        px={{ base: 4, md: 4 }}
        py={{ base: "3", md: "1" }}
        // position={"fixed"}
        // top={6}
        zIndex={"1080"}
        w={"full"}
        alignItems={"center"}
        justifyContent={{ base: "end", md: "end" }}
      >
        {/* <DummyLogoIcon w={"190px"} h={"60px"} /> */}
        {/* web view */}
        <HStack display={{ base: "none", md: "flex" }} gap={6} mr={2} my={1}>
          {routes?.map((ele) => {
            return ele?.menuItem ? (
              ele?.subComponent ? (
                <Menu offset={[4, -6]}>
                  <MenuButton>
                    <NavigationDisplay ele={ele} />
                  </MenuButton>
                  <Portal>
                    <MenuList
                      zIndex={"popover"}
                      bgImage={bgNav}
                      bgPosition={"center"}
                      bgRepeat={"no-repeat"}
                      bgSize={"cover"}
                    >
                      {ele?.subComponent?.map((e) => {
                        return (
                          <MenuItem background={"transparent"} p={0}>
                            <NavigationDisplay ele={e} />{" "}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Portal>
                </Menu>
              ) : (
                <NavigationDisplay ele={ele} />
              )
            ) : (
              <></>
            );
          })}
          <Button
            variant="trNoOutline"
            fontSize="16px"
            lineHeight="28px"
            fontWeight={500}
            title="Book a call"
            border={"1px solid"}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/+919601465169", "_blank");
            }}
          />
        </HStack>
        {/* web view end */}

        {/* mobile view start */}
        <IconButton
          transition="all 0.3s"
          _focus={{ boxShadow: "none" }}
          display={{ base: "flex", md: "none" }}
          variant="outline"
          border="1px solid #68030D"
          borderRadius={"10px"}
          aria-label="open menu"
          icon={<VectorIcon />}
          onClick={onOpen}
        />

        <Drawer placement={"top"} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent bgColor={"var(--astrology-mob-nav-bg)"}>
            <DrawerHeader padding={4} display="flex" justifyContent={"end"}>
              <Button
                variant="trPrimary"
                name={<CloseIcon boxSize={3} onClick={onClose} />}
              />
            </DrawerHeader>
            <DrawerBody>
              {routes?.map((ele) => {
                return ele?.menuItem ? (
                  ele?.subComponent ? (
                    <Accordion allowToggle>
                      <AccordionItem border="none">
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton p={0} borderRadius={8}>
                              {isExpanded ? (
                                <Flex
                                  bg={"var(--astrology-nav)"}
                                  width={"full"}
                                  alignItems={"center"}
                                  borderRadius={8}
                                  justifyContent={"space-between"}
                                >
                                  <MobileNavigationDisplay
                                    ele={ele}
                                    textDisplay={true}
                                    align="start"
                                    color={"var(--white-color)"}
                                  />
                                  <AccordionIcon
                                    color={"var(--white-color)"}
                                    mr={2}
                                  />
                                </Flex>
                              ) : (
                                <MobileNavigationDisplay
                                  ele={ele}
                                  textDisplay={true}
                                  color={"var(--astrology-mobnav-txt)"}
                                />
                              )}
                            </AccordionButton>
                            <AccordionPanel pb={4} bg={"#FEF0DD"}>
                              {ele?.subComponent?.map((e) => {
                                return (
                                  <MobileNavigationDisplay
                                    ele={e}
                                    color={"var(--astrology-nav)"}
                                    linkColor={"var(--astrology-nav)"}
                                    onClose={onClose}
                                  />
                                );
                              })}
                            </AccordionPanel>{" "}
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <MobileNavigationDisplay ele={ele} onClose={onClose} />
                  )
                ) : (
                  <></>
                );
              })}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        {/* mobile view end */}
      </Flex>
    </>
  );
};
