import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  Box,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import Background1 from "../../images/Service1Background.compressed.svg";

const TermsAndConditions = ({ isOpen, onClose }) => {
  const [isSmallerThan768] = useMediaQuery("(max-width: 768px)");

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent maxW={{ base: "90vw", md: "max-content" }}>
          <VStack
            bgImage={`url('${Background1}')`}
            w={"full"}
            h={"full"}
            bgRepeat="no-repeat"
            backgroundSize={"cover"}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundPosition={"center"}
          >
            <Box p={2} boxShadow="md" mx="auto" my={5}>
              <AlertDialogCloseButton />
              <Text
                fontSize={{ base: "22px", md: "30px" }}
                fontFamily={"Fondamento, sans-serif"}
                fontWeight="900"
                textAlign="center"
              >
                Terms & Conditions
              </Text>

              <UnorderedList spacing={2} color="gray.700" fontSize="md">
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Slot Selection: Choose your slot, based on available dates.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    One time Consultation: This is a one-time consultation call
                    only.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Rescheduling: You can reschedule up to 3 times. Rescheduling
                    isn't allowed within 48 hours of the call.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    No-Show Policy: No refunds or rescheduling if you miss your
                    call.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Extended Call Charges: Extra charges apply for extended
                    calls.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Refund Policy: No refunds after payment, unless it’s
                    canceled from our side.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Flexibility: Achosenson reserves the right to reschedule in
                    case of emergencies.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Recording: Clients may record the call at their discretion.
                  </Text>
                </ListItem>
                <ListItem color={"#68030D"}>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    // lineHeight={"44px"}
                    fontWeight={400}
                  >
                    Time Zone: The calendar operates on Indian Standard Time
                    (IST) . Please adjust for your time zone accordingly.
                    Clients will be responsible for any time zone errors.
                  </Text>
                </ListItem>
              </UnorderedList>
            </Box>
          </VStack>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TermsAndConditions;
