import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { extendedTheme } from "@astrology/shared-components";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const { ToastContainer } = createStandaloneToast();

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-4Z0W6PQ4Z6");
root.render(
  <React.StrictMode>
      <ChakraProvider theme={extendedTheme("sp")}>
          <App />
          <ToastContainer />
      </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
