import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import Background1 from "../../images/Service1Background.compressed.svg";
import { notesFAQ } from "./notesFAQ";

const DisplayFAQItem = ({ ele }) => {
  return (
    <AccordionItem borderColor={"#D2B589"}>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Text
                  color="var(--3, #68030D)"
                  fontFamily="Fondamento"
                  fontSize={{ base: "16px", md: "29.896px" }}
                  fontStyle="normal"
                  fontWeight={{ base: "900", md: "600" }}
                  lineHeight="38.2px " /* 127.778% */
                >
                  {ele.ques}
                </Text>
              </Box>
              {isExpanded ? (
                <Box
                  bgColor={"#9C541C"}
                  px={5}
                  py={3}
                  border={"1px solid #9C541C"}
                  borderRadius={"50%"}
                >
                  <CloseIcon fontSize="12px" color={"#FFF"} />
                </Box>
              ) : (
                <Box
                  px={5}
                  py={3}
                  border={"1px solid #9C541C"}
                  borderRadius={"50%"}
                >
                  <AddIcon fontSize="12px" color={"#68030D"} />
                </Box>
              )}
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} width={"80%"}>
            <Text
              color="var(--3, #68030D)"
              fontFamily="Jost"
              fontSize={{ base: "15px", md: "20px" }}
              fontStyle="normal"
              fontWeight="400"
              lineHeight="28px"
            >
              {" "}
              {ele.ans}
            </Text>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
const FAQS = ({ headText, type, hasBackground = true }) => {
  const c2 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-2", // For medium screens
  });
  return (
    <>
      <Flex
        bgImage={hasBackground ? `url('${Background1}')` : "transparent"}
        className={c2}
        w={"full"}
        minH={{ base: "auto", md: "90vh" }}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        flexDir={"column"}
        backgroundPosition={"top"}
        pb={{ base: "40px", md: "80px" }}
      >
        <Flex
          alignItems={"start"}
          justifyContent={"start"}
          flexDir={"column"}
          gap={{ base: "20px", md: "50px" }}
          mt={{ base: "30px", md: "70px" }}
        >
          <Text
            fontSize={{ base: "26px", md: "36px" }}
            color={"#68030D"}
            stroke={"#68030D"}
            lineHeight={"70px"}
            // maxW={{ base: "80%", md: "100%" }}
            textAlign={"center"}
            fontFamily={"Fondamento"}
            fontWeight={900}
            textTransform={"capitalize"}
          >
            {headText ?? ""}
          </Text>
        </Flex>
        <Accordion width={"100%"} px={{ base: 2, md: 12 }} allowToggle>
          {notesFAQ?.[type]?.map((ele, index) => {
            return <DisplayFAQItem ele={ele} key={`${type}-${index}`} />;
          })}
        </Accordion>
      </Flex>
    </>
  );
};

export default FAQS;
